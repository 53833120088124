var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "Mostrar mais",
  "client.header.login.button": "Acessar",
  "client.header.logout.button": "Sair",
  "client.user-id-modal.input-placeholder": "ID do usuário",
  "client.user-id-modal.input-incorrect-id": "ID de usuário incorreto.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "Tente copiar e colar seu ID do jogo.",
  "client.user-id-modal.continue-button": "Continuar",
  "client.view-nickname-modal.show-more.button": "Mostrar mais",
  "client.privacy-settings.open-button": "Configurações de privacidade",
  "client.usa-privacy-settings.open-button": "Não venda meus dados",
  /* default-data page texts */
  "document.pages.auth.title": "Insira seu ID",
  "document.pages.auth.placeholder": "Exemplo: 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "Não consegue encontrar seu ID de usuário?",
  "document.pages.auth.instruction.steps.description": "Descreva como o usuário pode encontrar seu ID pessoal. Você pode adicionar várias etapas e imagens.",
  "document.pages.seo.title": "O novo jogo acaba de ser lançado!",
  "document.pages.seo.description": "Seu jogo se passa em uma aventura grandiosa repleta de obstáculos, inimigos, caçadores de recompensas e muito perigo.",
  "document.pages.seo.ogTitle": "O novo jogo acaba de ser lançado!",
  "document.pages.seo.ogDescription": "Seu jogo se passa em uma aventura grandiosa repleta de obstáculos, inimigos, caçadores de recompensas e muito perigo.",
  "default-data.templates.google-play.contact-us": "Entre em contato: {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "RECARREGUE A CARTEIRA DO JOGO",
  "blocks.topup.values.description": "Insira seu ID de usuário",
  "blocks.topup.values.userIdPlaceholder": "Exemplo: 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "NÃO CONSEGUE ENCONTRAR SEU ID DE USUÁRIO?",
  "blocks.topup.values.userInstruction.stepTitleTag": "Nome da etapa",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "Descreva como o usuário pode encontrar seu ID pessoal. Você pode adicionar várias etapas e imagens.",
  "default-data.templates.topup.app-support": "Suporte do aplicativo",
  "default-data.templates.topup.age-raiting": "Classificação etária",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>TÍTULO DO SEU JOGO</h1>",
  "blocks.retailers.values.title": "<h2>Escolha sua edição</h2>",
  "blocks.hero.values.description": "Seu jogo se passa em uma aventura grandiosa repleta de obstáculos, inimigos, caçadores de recompensas e muito perigo.",
  "blocks.hero.values.affiliate": "A receita desta compra será compartilhada com seu afiliado",
  "blocks.hero.components.platforms.label": "Disponível para",
  "blocks.hero.components.subscribe.label": "Insira seu e-mail",
  "blocks.hero.components.subscribe.values.text" : "Inscreva-se no nosso boletim informativo para obter as últimas notícias e atualizações",
  "blocks.hero.components.scroll.label": "Role",
  "blocks.footer.values.name": "Insira seu texto",
  "blocks.footer.values.affiliate": "Participe do programa de afiliados",
  "blocks.hero.components.buy.label": "Compre agora",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "Indisponível",
  "blocks.hero.components.customButton.link.label": "Botão de configuração",
  "blocks.header.components.customButton.link.label": "Botão de configuração",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "Indisponível",
  "blocks.packs.components.pack.customButton.link.label": "Botão de configuração",
  "blocks.packs.components.pack.standart.components.buy.label": "Compre agora",
  "blocks.packs.components.pack.premium.components.buy.label": "Compre agora",
  "blocks.packs.components.pack.ultimate.components.buy.label": "Compre agora",
  "blocks.packs.components.pack.platinum.components.buy.label": "Compre agora",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "Indisponível",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "Indisponível",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "Indisponível",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "Indisponível",
  "blocks.packs.components.pack.standart.value.ribbon": "Mais popular",
  "blocks.packs.components.pack.premium.value.ribbon": "Mais popular",
  "blocks.packs.components.pack.ultimate.value.ribbon": "Mais popular",
  "blocks.packs.components.pack.platinum.value.ribbon": "Mais popular",
  "blocks.description.values.title": "<h2>DESCRIÇÃO DO JOGO</h2>",
  "blocks.description.components.text.label": "Seu jogo se passa em uma aventura grandiosa repleta de obstáculos, inimigos, caçadores de recompensas e muito perigo. Eventos dramáticos transformaram sua vida para sempre e agora você deve lutar pela sua sobrevivência em um novo mundo hostil. Descubra, explore, e conquiste conforme você enfrenta os desafios de diversas dimensões da realidade!",
  "blocks.faq.values.title": "<h2>Perguntas frequentes</h2>",
  "blocks.faq.components.question.question": "Como posso jogar Your Game?",
  "blocks.faq.components.question.answer": "Para jogar Your Game, você precisa comprá-lo e informar o seu e-mail. Você receberá a chave de ativação. A partir disso, é só enviá-la e aproveitar o jogo.",
  "blocks.news.values.title": "<h2>Novidades</h2>",
  "blocks.news.values.description": "Confira nossos artigos mais recentes para ver as novidades.",
  "blocks.store.values.title": "<h2>Loja</h2>",
  "blocks.store.buy_button.text": "COMPRE AGORA POR {amount}",
  "blocks.embed.values.title": "<h2>Widgets de mídias sociais</h2>",
  "blocks.embed.components.twitter.title": "<h3>FEED DO TWITTER</h3>",
  "blocks.embed.components.facebook.title": "<h3>FEED DO FACEBOOK</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "Selecione sua edição",
  "blocks.packs.values.description": "Cada edição inclui vantagens exclusivas e benefícios que você receberá após a compra.",
  "blocks.packs.components.pack.standart.value.subtext": "Já disponível",
  "blocks.packs.components.pack.premium.value.subtext": "Já disponível",
  "blocks.packs.components.pack.ultimate.value.subtext": "Já disponível",
  "blocks.packs.components.pack.platinum.value.subtext": "Já disponível",
  "blocks.packs.components.pack.standart.value.title": "<h3>Edição Padrão</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>Edição Premium</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>Edição Platina</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>Edição Suprema</h3>",
  "blocks.packs.components.pack.standart.value.description": "Chave do jogo, insígnia exclusiva, trilha sonora, mapa e pacote de armadura adicional",
  "blocks.packs.components.pack.premium.value.description": "Chave do jogo, insígnia exclusiva, trilha sonora, mapa e pacotes de armadura e arma adicionais",
  "blocks.packs.components.pack.platinum.value.description": "Chave do jogo, insígnia exclusiva, trilha sonora, mapa, livro de arte digital, pacote de armadura adicional, pacote de arma adicional, e um pacote de aparência aleatória",
  "blocks.packs.components.pack.ultimate.value.description": "Chave do jogo, insígnia exclusiva, trilha sonora, mapa, livro de arte digital, pacote de armadura adicional, dois pacotes de armas adicionais, e três pacotes de aparências aleatórias",
  "blocks.subscriptions.values.card.title": "Seja premium e ganhe benefícios",
  "blocks.subscriptions.values.title": "Ativar assinatura",
  "blocks.subscriptions.values.heading": "Assinaturas",
  "blocks.subscriptions.values.bullets.one.title": "Conteúdo digital",
  "blocks.subscriptions.values.bullets.one.description": "Imagens, papel de parede, conteúdo por trás dos bastidores, trilhas sonoras do jogo",
  "blocks.subscriptions.values.bullets.two.title": "Vantagens no jogo",
  "blocks.subscriptions.values.bullets.two.description": "Visuais de personagens, efeitos visuais, cartas de história",
  "blocks.subscriptions.values.bullets.three.title": "3 caixas de saque no jogo",
  "blocks.subscriptions.values.bullets.three.description": "5 passes extra ao Salão do Caos, 10 renascimentos de personagem",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "Próxima renovação",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "Renovar agora",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "Gerenciar plano",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "Selecionar seu plano:",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "Configurar assinatura",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial} dia(s) de graça",
  "editor.subscriptions-packs.button.then.price.per.day": "E depois {amount} por dia",
  "editor.subscriptions-packs.button.then.price.per.week": "E depois {amount} por semana",
  "editor.subscriptions-packs.button.then.price.per.month": "E depois {amount} por mês",
  "editor.subscriptions-packs.button.then.price.per.year": "E depois {amount} por ano",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "E então {amount} a cada {periodValue} dias",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "E então {amount} a cada {periodValue} semanas",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "E então {amount} a cada {periodValue} meses",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "E então {amount} a cada {periodValue} anos",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "E depois {amount} vitalícios",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "como primeiro pagamento, depois {amount} por dia",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "como primeiro pagamento, depois {amount} por semana",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "como primeiro pagamento, depois {amount} por mês",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "como primeiro pagamento, depois {amount} por ano",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "como primeiro pagamento, depois {amount} vitalícios",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "como primeiro pagamento, e então {amount} a cada {periodValue} dias",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "como primeiro pagamento, e então {amount} a cada {periodValue} semanas",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "como primeiro pagamento, e então {amount} a cada {periodValue} meses",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "como primeiro pagamento, e então {amount} a cada {periodValue} anos",
  "editor.subscriptions-packs.button.price.per.day": "{amount} por dia",
  "editor.subscriptions-packs.button.price.per.week": "{amount} por semana",
  "editor.subscriptions-packs.button.price.per.month": "{amount} por mês",
  "editor.subscriptions-packs.button.price.per.year": "{amount} por ano",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{amount} a cada {periodValue} dias",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{amount} a cada {periodValue} semanas",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{amount} a cada {periodValue} meses",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{amount} a cada {periodValue} anos",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount} vitalícios",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por dia durante {periodsCounts} período(s), e então {amount} por dia",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por semana durante {periodsCounts} período(s), e então {amount} por semana",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por mês durante {periodsCounts} período(s), e então {amount} por mês",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por ano durante {periodsCounts} período(s), e então {amount} por ano",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "E então <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} dias durante {periodsCounts} período(s), e então {amount} a cada {periodValue} dias",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "E então <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} semanas durante {periodsCounts} período(s), e então {amount} a cada {periodValue} semanas",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "E então <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} meses durante {periodsCounts} período(s), e então {amount} a cada {periodValue} meses",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "E então <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} anos durante {periodsCounts} período(s), e então {amount} a cada {periodValue} anos",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "Depois {amount} como o primeiro pagamento, e então {amountPromo} por dia",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "Depois {amount} como o primeiro pagamento, e então {amountPromo} por semana",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "Depois {amount} como o primeiro pagamento, e então {amountPromo} por mês",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "Depois {amount} como o primeiro pagamento, e então {amountPromo} por ano",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "E então {amount} como primeiro pagamento, e então {amountPromo} a cada {periodValue} dias",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "E então {amount} como primeiro pagamento, e então {amountPromo} a cada {periodValue} semanas",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "E então {amount} como primeiro pagamento, e então {amountPromo} a cada {periodValue} meses",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "E então {amount} como primeiro pagamento, e então {amountPromo} a cada {periodValue} anos",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "Depois {amount} como o primeiro pagamento, e então {amountPromo} vitalícios",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "durante {periodsCounts} período(s), depois {amount} por dia",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "durante {periodsCounts} período(s), depois {amount} por semana",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "durante {periodsCounts} período(s), depois {amount} por mês",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "durante {periodsCounts} período(s), depois {amount} por ano",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "durante {periodsCounts} período(s), e então {amount} a cada {periodValue} dias",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "durante {periodsCounts} período(s), e então {amount} a cada {periodValue} semanas",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "durante {periodsCounts} período(s), e então {amount} a cada {periodValue} meses",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "durante {periodsCounts} período(s), e então {amount} a cada {periodValue} anos",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "durante {periodsCounts} período(s), depois {amount} vitalícios",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, a temporada termina em {periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, a temporada começa em {periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "Depois {amount}, a temporada termina em {periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "Depois {amount}, a temporada começa em {periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por dia",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por semana",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por mês",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por ano",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "E então <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} dias",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "E então <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} semanas",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "E então <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} meses",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "E então <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} anos",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} vitalícios",
  "editor.subscriptions-packs.button.then.price.promo.price": "Depois <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por dia",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por semana",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por mês",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} por ano",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} dias",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} semanas",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} meses",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} a cada {periodValue} anos",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} vitalícios",
  "editor.subscriptions-packs.button.then.price": "Depois {amount}",
  "editor.subscriptions-packs.button.season.ends": "A temporada termina em {periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "A temporada começa em {periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount} / dia",
  "editor.subscriptions-packs.button.price.period.week": "{amount} / semana",
  "editor.subscriptions-packs.button.price.period.month": "{amount} / mês",
  "editor.subscriptions-packs.button.price.period.year": "{amount} / ano",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount} / {periodValue} dias",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount} / {periodValue} semanas",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount} / {periodValue} meses",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount} / {periodValue} anos",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount} / vitalícios",
  "blocks.requirements.values.title": "<h2>Requisitos de sistema</h2>",
  "blocks.requirements.components.platform_req.value.title": "Plataforma",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "Gráficos",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "Sistema operacional",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "Armazenamento",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "Processador",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "Placa de som",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "Memória",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "Mínimo",
  "default-data.values.requirements.title.recommended": "Recomendado",
  "default-data.values.requirements.item.directx.minimum": "Versão 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2.5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "Compatível com DirectX9",
  "default-data.values.requirements.item.sound_card.recommended": "Compatível com DirectX9",
  "default-data.values.requirements.item.directx.recommended": "Versão 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "Olá, colega!",
  "default-data.customization.receipt.description": "Obrigado pela sua compra! Nós apreciamos sua vontade de jogar nosso jogo.",
  "default-data.customization.receipt.footer": "© Todos os direitos reservados.",
  "blocks.store.components.storeSection.title": "Itens",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "Você se inscreveu com sucesso!",
  "notification.hero.components.subscribe.subscription.success": "Acabamos de enviar um e-mail de confirmação a você. Siga o link no e-mail para ativar sua inscrição.",
  "notification.hero.components.subscribe.error": "Ocorreu um erro. Tente novamente mais tarde ou entre em contato com o nosso time de integração em<a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "notification.hero.components.subscribe.email.taken.error": "Você já se inscreveu.",
  /* notifications values END */
  "period.years_short": "anos",
  "period.months_short": "meses",
  "period.weeks_short": "semanas",
  "period.days_short": "dias",
  "subscription.button.text": "Assine por </br>{fullCurrency} por {periodCount}&thinsp;{periodName}",
  "client.subscription.button.current.text": "Sua assinatura",
  "client.subscription.button.configuration": "Gerenciar assinatura",
  "client.subscription.button.lifetime_text": "Assinatura vitalícia</br> por {fullCurrency}",
  "client.thank_you_modal.title": "Obrigado pela assinatura!",
  "client.thank_you_modal.description": "Sua assinatura é renovada automaticamente. O pagamento será cobrado regularmente de acordo com os termos da sua assinatura. Você pode cancelá-la a qualquer momento.",
  "client.thank_you_modal.input.description": "Caso você queira ter seu nome exibido na lista de assinantes, insira seu nome abaixo.",
  "client.thank_you_modal.button.default.text": "Não desejo inserir meu nome",
  "client.thank_you_modal.input.placeholder": "Seu nome",
  "client.thank_you_modal.button.show_list": "Exibir todos assinantes",
  "client.subscribers_list_modal.title": "Lista completa de assinantes",
  "client.subscribers_list_modal.description": "Muito obrigado a todos usuários que fizeram uma assinatura no nosso jogo!",
  "client.subscribers_list_modal.show_more_button": "Mostrar mais",
  "client.subscribers_list_modal.anonymous": "e usuários anônimos",
  "client.footer.button.list_of_subscribers": "Lista de assinantes",
  "client.footer.xsollalogo.powered": "Fornecido por", // копирайт в футере
  "client.footer.xsollalogo.sb": "Construtor de Sites Xsolla",
  "client.empty_subscribers_modal.title": "Os assinantes são listados aqui",
  "client.empty_subscribers_modal.description": "Seja o primeiro a fazer uma assinatura no nosso jogo.",
  "client.empty_subscribers_modal.button": "Selecione a assinatura agora",
  "client.news.blurred.description": "Assine {subscription_name} para desbloquear esse conteúdo.",
  "client.news.blurred.button.join": "Participar agora",
  "client.cart_modal.title": "Carrinho",
  "client.cart_modal.item.pre-order": "(pré-ordem)",
  "client.cart_modal.clear_all": "limpar tudo",
  "client.cart_modal.item_purchased_description": "Você já comprou esse item",
  "client.cart_modal.subtotal_title": "Total",
  "client.cart_modal.subtotal_subtitle": "Taxas adicionais poderão ser aplicadas",
  "client.cart_modal.continue_button": "continuar comprando",
  "client.cart_modal.checkout_button": "finalizar compra",
  "client.cart_modal.footer-auth": "<button class=\'xl_auth\' data-xl-route=\'login\'>Acesse sua conta</button> ou <button class=\'xl_auth\' data-xl-route=\'signup\'>cadastre-se</button> para salvar os itens no carrinho, ou para ver itens salvos anteriormente",
  "client.cart_modal.empty_cart_description": "Seu carrinho está vazio. Vá para a loja e encontre produtos incríveis para comprar.",
  "client.cart_modal.empty_cart_open_store_button": "abrir loja",
  "client.cart_modal.email_label": "Enviar chave de ativação do jogo para:",
  "client.cart_modal.email_description": "Você receberá uma chave que você precisará ativar para começar a jogar.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "Extras",
  "client.cart_modal.promo_codes.apply": "Aplicar",
  "client.cart_modal.promo_codes.remove": "Remover",
  "client.cart_modal.promo_codes.placeholder": "Código promocional",
  "client.cart_modal.promo_codes.error_incorrect": "Código promocional incorreto.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "Limite de resgate de códigos atingido.",
  "client.cart_modal.promo_codes.error_expired": "Esse código promocional expirou.",
  "client.cart_modal.promo_codes.error_could_not_apply": "Não foi possível aplicar o código promocional. Tente novamente mais tarde.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "O código promocional não é válido para os itens em seu carrinho.",
  "client.cart_modal.promo_codes.error_select_game_platform": "Selecione uma plataforma de jogos para continuar.",
  "client.cart_modal.promo_codes.select_drm": "Selecione a plataforma",
  "client.cart_modal.promo_codes.tooltip_drm": "Para alterar a plataforma, remova o código promocional e aplique-o novamente.",
  "client.bundle_modal.group_items": "Itens no pacote",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>Bronze</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>Conteúdos digitais</h3><p>✓ Arte</p><p>✓ Papéis de parede</p><p><span style=\'color:rgb(127, 96, 0);\'>× Trilha sonora do jogo </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Conteúdos por trás das cenas</span></p><p><br></p><h3>3 Caixas de recompensa</h3><h3>no jogo</h3><p>✓ Visuais de personagens</p><p><span style=\'color:rgb(127, 96, 0);\'>× Efeitos visuais </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Cartas de história</span></p><p><br></p><h3>Vantagens nos jogos</h3><p>✓ x1 Passe extra ao Hall do Caos</p><p>✓ x1 renascimento de personagem</p><p><br></p><h3>Discord</h3><p>✓ Acesso ao canal de jogadores do jogo</p><p><span style=\'color:rgb(127, 96, 0);\'>× Acesso ao canal dos desenvolvedores</span></p><p><br></p><h3>Fórum</h3><p>✓ Cor de apelido exclusiva</p><p><span style=\'color:rgb(127, 96, 0);\'>× Emoji exclusivo(s)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Imagem de perfil animada </span></p><p>✓ x2 pontos extras para classificar a postagem (diariamente)</p><p><br></p><h3>Merchandising</h3><p>✓ Broches &amp; adesivos (a cada 6 meses)</p><p>✓ Pôster de alta qualidade (a cada 6 meses)</p><p><span style=\'color:rgb(127, 96, 0);\'>× Brinquedos de pelúcia de marca (a cada ano)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Estatuetas de alta qualidade (a cada ano)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>Eventos off-line</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× Acesso gratuito às nossas convenções de jogos</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Visite o estúdio da empresa (a cada ano)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ concede acesso ao Discord</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>Prata</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>Conteúdos digitais</h3><p>✓ Arte</p><p>✓ Papéis de parede</p><p><span style=\"color:rgb(127, 96, 0);\">× Trilha sonora do jogo</span></p><p>✓ Conteúdos por trás das cenas</p><p><br></p><h3>6 Caixas de recompensa</h3><h3>no jogo</h3><p>✓ Visuais de personagens</p><p>✓ Efeitos visuais</p><p><span style=\"color:rgb(127, 96, 0);\">× Cartas de história</span></p><p><br></p><h3>Vantagens nos jogos</h3><p>✓ x2 Passe extra ao Hall do Caos</p><p>✓ x2 renascimento de personagem</p><h3><br></h3><h3>Discord</h3><p>✓ Acesso ao canal de jogadores do jogo</p><p><span style=\"color:rgb(127, 96, 0);\">× Acesso ao canal dos desenvolvedores</span></p><p><br></p><h3>Fórum</h3><p>✓ Cor de apelido exclusiva</p><p><span style=\"color:rgb(127, 96, 0);\">× Emoji exclusivo(s)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Imagem de perfil animada</span></p><p>✓ 5x pontos extras para classificar a postagem (diariamente)</p><p><br></p><h3>Merchandising</h3><p>✓ Broches &amp; adesivos (a cada 6 meses)</p><p>✓ Pôster de alta qualidade (a cada 6 meses)</p><p><span style=\"color:rgb(127, 96, 0);\">× Brinquedos de pelúcia de marca (a cada ano)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Estatuetas de alta qualidade (a cada ano)</span></p><p><br></p><h3>Eventos off-line</h3><p>✓ Acesso gratuito às nossas convenções de jogos</p><p><span style=\"color:rgb(127, 96, 0);\">× Visite o estúdio da empresa (a cada ano)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ concede acesso ao Discord</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>Ouro</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>Conteúdos digitais</h3><p>✓ Arte</p><p>✓ Papéis de parede</p><p>✓ Trilha sonora do jogo</p><p>✓ Conteúdos por trás das cenas</p><p><br></p><h3>9 Caixas de recompensa</h3><h3>no jogo</h3><p>✓ Visuais de personagens</p><p>✓ Efeitos visuais</p><p>✓ Cartas de história</p><p><br></p><h3>Vantagens nos jogos</h3><p>✓ x5 Passe extra ao Hall do Caos</p><p>✓ x10 renascimento de personagem</p><p><br></p><h3>Discord</h3><p>✓ Acesso ao canal de jogadores do jogo</p><p>✓ Acesso ao canal dos desenvolvedores</p><p><br></p><h3>Fórum</h3><p>✓ Cor de apelido exclusiva</p><p>✓ Emoji exclusivo(s)</p><p>✓ Imagem de perfil animada</p><p>✓ x12 pontos extras para classificar a postagem (diariamente)</p><p><br></p><h3>Merchandising</h3><p>✓ Broches &amp; adesivos (a cada 6 meses)</p><p>✓ Pôster de alta qualidade (a cada 6 meses)</p><p>✓ Brinquedos de pelúcia de marca (a cada ano)</p><p>✓ Estatuetas de alta qualidade (a cada ano)</p><p><br></p><h3>Eventos off-line</h3><p>✓ Acesso gratuito às nossas convenções de jogos</p><p>✓ Visite o estúdio da empresa (a cada ano)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ concede acesso ao Discord</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>Conteúdos digitais</h3><p>Arte</p><p>Papéis de parede</p><p><span>Trilha sonora do jogo </span></p><p><span>Conteúdos por trás das cenas</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>Caixas de recompensa</h3><h3>no jogo</h3><p>Visuais de personagens</p><p><span>Efeitos visuais </span></p><p><span>Cartas de história</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>Vantagens nos jogos</h3><p>x1 Passe extra ao Hall do Caos</p><p>x1 renascimento de personagem</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>Acesso ao canal de jogadores do jogo</p><p><span>× Acesso ao canal dos desenvolvedores</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>Fórum</h3><p>Cor de apelido exclusiva</p><p><span>× Emoji exclusivo(s)</span></p><p><span>× Imagem de perfil animada</span></p><p>x2 pontos extras para classificar a postagem (diariamente)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>Merchandising</h3><p>Broches &amp; adesivos (a cada 6 meses)</p><p>Pôster de alta qualidade (a cada 6 meses)</p><p><span>Brinquedos de pelúcia de marca (a cada ano)</span></p><p><span>Estatuetas de alta qualidade (a cada ano)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>Eventos off-line</h3><p>✓ Acesso gratuito às nossas convenções de jogos</p><p>✓ Visite o estúdio da empresa (a cada ano)</p>",
  "client.buy_button.text": "Compre agora",
  "client.buy_button.unavailable": "Indisponível",
  "client.buy_button.pre-order": "Pré-ordem",
  "client.buy_button.checkout": "Finalizar compra",
  "client.store.cart.add_button": "Adicionar ao carrinho",
  "client.store.cart.header_button": "Carrinho",
  "client.store.no_items": "A loja está vazia no momento. Volte mais tarde!",
  "client.store.error.gt10_gk": "Para lhe protegermos de fraudes, você só pode comprar 10 chaves por vez.",
  "client.store.error.no_keys": "O jogo está temporariamente esgotado e será reabastecido em breve. Sinta-se à vontade para nos contatar e solicitar informações.",
  "client.store.item.purchased": "Comprado",
  "client.store.item.price_from": "de {price}",
  "client.store.item.show_more": "Exibir detalhes",
  "client.store.successful_purchase": "Sucesso. Obrigado pela sua compra!",
  "client.store.not_enough_vc": "Moedas virtuais insuficientes.</br>Faça uma recarga para concluir a compra.",
  "client.store.non_consumable_item": "Você já comprou esse item antes e ele não está mais disponível.",
  "client.store.user_id_input_before_submit_hint": "Os items serão creditados a esta conta.",
  "client.store.user_id_input_after_submit_hint": "Você está comprando para:",
  "client.common_error": "Ocorreu um erro. Tente novamente mais tarde ou entre em contato com o nosso time de integração em<a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "client.user_account.balance_title": "Seu saldo",
  "client.header.logout": "Sair",
  "client.retailers.edition.title": "Selecione sua cópia",
  "client.retailers.country.title": "Selecione seu país",
  "client.retailers.platform.title": "Selecione a plataforma",
  "client.retailers.retailer.title": "Selecione o vendedor",
  "client.retailers.edition.digital": "Digital",
  "client.retailers.edition.physical": "Física",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "Comprar do desenvolvedor",
  "client.retailers.expand_button.collapse": "Recolher",
  "client.retailers.buy_button.buy": "Compre agora",
  "blocks.retailers.components.retailers.standart.value.ribbon": "Mais popular",
  "blocks.retailers.components.retailers.standart.value.subtext": "Já disponível",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>Edição Padrão</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "Chave do jogo, insígnia exclusiva, trilha sonora, mapa e pacote de armadura adicional",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>TÍTULO DO SEU JOGO</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "Seu jogo se passa em uma aventura grandiosa repleta de obstáculos, inimigos, caçadores de recompensas e muito perigo.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "Disponível para",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "Insira seu e-mail",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "Inscreva-se no nosso boletim informativo para obter as últimas notícias e atualizações",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "Botão de configuração",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "Compre agora",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "Botão de configuração",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "Botão de configuração",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "Botão de configuração",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "Indisponível",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "Botão de configuração",
  "client.topup.input.errorMessage": "Ocorreu um erro. Certifique-se de que seu ID de usuário está correto ou tente novamente mais tarde.",
  "client.topup.input.successMessage": "Você acessou com o ID {userID}. Agora você pode realizar compras no site.",
  "client.topup.input.username": "Nome de usuário: {name}",
  "client.topup.input.id": "ID: {id}",
  "client.topup.input.level": "Nível: {level}",
  "client.topup.input.user_not_found": "Não conseguimos encontrar um usuário com o ID especificado.<br/>Tente novamente.",
  "client.topup.input.serverError": "Ocorreu um erro.<br>Se você encontrou essa mensagem mais de uma vez, {contact_us}.",
  "client.topup.input.serverError.contact_us": "entre em contato conosco",
  "client.topup.authorized_title": "Você está comprando para:",
  "blocks.store.values.custom_title": "<h2>Loja {gameTitle}</h2>"
};

module.exports = localizations;
