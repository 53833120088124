// @flow

import type { Landing } from '#common/types/model/landing';
import type {
  Launcher, NewsArticles, NewsArticleWithPlanId, NewsCategories, Categories
} from '../../../utils/types';
import type { Action, Dispatch, GetState } from '../../types';
import type { RootState } from './index';
import type { $AxiosXHR } from 'axios';

import * as api from '../../../utils/api';

export type State = {|
  launcherList: Launcher[],
  newsArticles: NewsArticles,
  loadingLauncherList: boolean,
  newsCategories: NewsCategories,
  loadingNewsArticles: boolean
|};
const initialState: State = {
  launcherList: [],
  newsArticles: {},
  newsCategories: {},
  loadingLauncherList: true,
  loadingNewsArticles: false
};

export const INIT_LAUNCHER_DATA = 'INIT_LAUNCHER_DATA';
export const INIT_NEWS_ARTICLES = 'INIT_NEWS_ARTICLES';
export const START_LOADING_NEWS_ARTICLES = 'START_LOADING_NEWS_ARTICLES';
export const END_LOADING_NEWS_ARTICLES = 'END_LOADING_NEWS_ARTICLES';
export const UPDATE_NEWS_ARTICLES = 'UPDATE_NEWS_ARTICLES';
export const CREATE_NEWS_ARTICLE = 'CREATE_NEWS_ARTICLE';
export const GET_NEWS_ARTICLE = 'GET_NEWS_ARTICLE';
export const UPDATE_NEWS_ARTICLE = 'UPDATE_NEWS_ARTICLE';
export const DELETE_NEWS_ARTICLE = 'DELETE_NEWS_ARTICLE';
export const INIT_NEWS_CATEGORIES = 'INIT_NEWS_CATEGORIES';
export const UPDATE_NEWS_CATEGORIES = 'UPDATE_NEWS_CATEGORIES';

export const startLoadingNewsArticles: Action = {
  type: START_LOADING_NEWS_ARTICLES
};

export const endLoadingNewsArticles: Action = {
  type: END_LOADING_NEWS_ARTICLES
};

export const selectDefaultCategory = (launcherState: State, launcherId: string): Categories | '' => launcherState.newsCategories[launcherId].find(elem => elem.default) || '';

export const initLauncherData = (landing: Landing) => async (dispatch: Dispatch): Promise<void> => {
  const { merchantId, projectId, _id } = landing;
  const response: $AxiosXHR<Launcher[]> = await api.getLauncherList({
    merchantId,
    projectId,
    landingId: _id
  });
  if (response.status !== 200) {
    return;
  }

  dispatch({
    type: INIT_LAUNCHER_DATA,
    payload: { launcherList: response.data || [] }
  });
};

export const getNewsCategories = (launcherIds: string[]) => async (dispatch: Dispatch): Promise<void> => {
  const newsResponse: $AxiosXHR<{
    categories: Categories[]
  }>[] = await Promise.all(
    launcherIds.map(async id => api.getNewsCategories({ launcherId: id }))
  );
  const newsCategories = newsResponse.reduce((accumulator, response, index) => {
    if (response.status !== 200 || !response.data) {
      return { ...accumulator };
    }
    return {
      ...accumulator,
      [launcherIds[index]]: response.data.categories
    };
  }, {});

  dispatch({
    type: INIT_NEWS_CATEGORIES,
    payload: newsCategories
  });
};

export const initNewsArticles = (landing: Landing) => (dispatch: Dispatch) => {
  const downloadNewsForAllLauncher = async (launcherIds: string[]): Promise<void> => {
    const uniqLauncherIds = launcherIds.filter((id, index) => launcherIds.indexOf(id) === index);
    const newsResponse: $AxiosXHR<{news: NewsArticleWithPlanId[], count: number }>[] = await Promise.all(
      uniqLauncherIds.map(async id => api.getNews({ launcherId: id }))
    );
    const newsArticles = newsResponse.reduce((accumulator, response, index) => {
      if (response.status !== 200 || !response.data) {
        return { ...accumulator };
      }
      return {
        ...accumulator,
        [uniqLauncherIds[index]]: { news: response.data.news, count: response.data.count }
      };
    }, {});

    dispatch({
      type: UPDATE_NEWS_ARTICLES,
      payload: newsArticles
    });
  };

  if (!landing.pages[0]) {
    return;
  }
  const { blocks } = landing.pages[0];
  const newsBlocks = blocks.filter(({ module }) => module === 'news');
  const launcherComponents = newsBlocks.map(({ components }) => components.find(({ type }) => type === 'launcher_select'));
  const launcherIds = launcherComponents.reduce((accumulator, component) => {
    if (component && component.enable && component.value) {
      accumulator.push(component.value);
    }
    return accumulator;
  }, []);
  const newsArticles = launcherIds.reduce((accumulator, id) => ({ ...accumulator, [id]: { news: [], count: 0 } }), {});

  
  dispatch({
    type: INIT_NEWS_ARTICLES,
    payload: newsArticles
  });
  downloadNewsForAllLauncher(launcherIds);
  dispatch(getNewsCategories(launcherIds));
};

export const changeLauncher = (newLauncherId: string) => async (dispatch: Dispatch, getState: GetState<RootState>) => {
  const { launcherData: { newsArticles, newsCategories } } = getState();
  if (newsArticles[newLauncherId]) {
    return;
  }

  const [
    responseNews,
    responseCategories
  ] = await Promise.all([
    api.getNews({ launcherId: newLauncherId }),
    api.getNewsCategories({ launcherId: newLauncherId })
  ]);

  if (responseNews.status === 200 && responseNews.data) {
    const newsArticlesForNewLauncher = {
      [newLauncherId]: {
        news: responseNews.data.news,
        count: responseNews.data.count
      }
    };
    dispatch({
      type: UPDATE_NEWS_ARTICLES,
      payload: { ...newsArticles, ...newsArticlesForNewLauncher }
    });
  }

  if (responseCategories.status !== 200 || !responseCategories.data.categories) {
    return;
  }
  const newsCategoriesForNewLauncher = {
    [newLauncherId]: responseCategories.data.categories
  };
  dispatch({
    type: UPDATE_NEWS_CATEGORIES,
    payload: { ...newsCategories, ...newsCategoriesForNewLauncher }
  });
};


export const createNews = (createdNews: NewsArticleWithPlanId, launcherId: string) => async (dispatch: Dispatch, getState: GetState<RootState>): Promise<void> => {
  const { launcherData: { newsArticles }, landing: { landing: { merchantId, _id: landingId } } } = getState();
  const { news = [], count } = newsArticles[launcherId] || {};

  const response: $AxiosXHR<NewsArticleWithPlanId> = await api.createNews({
    launcherId, merchantId, landingId, data: createdNews
  });
  if (response.status !== 200 || !response.data) {
    return;
  }

  newsArticles[launcherId] = {
    news: [response.data, ...news].sort(newsItem => (newsItem.newsData.pin_to_top_order ? -1 : 1)),
    count: count + 1
  };
  dispatch({
    type: CREATE_NEWS_ARTICLE,
    payload: newsArticles
  });
};

export const getNews = (params: Object) => async (dispatch: Dispatch, getState: GetState<RootState>): Promise<void> => {
  const { launcherData: { newsArticles } } = getState();
  const { news = [], count } = newsArticles[params.launcherId] || {};

  params.offset = params.offset || news.length;
  if (newsArticles.loadingNewsArticles || params.offset === count) {
    return;
  }

  dispatch(startLoadingNewsArticles);
  const response: $AxiosXHR<{
    news: NewsArticleWithPlanId[],
    count: number
  }> = await api.getNews(params);
  if (response.status !== 200 || !response.data || !response.data.news) {
    return;
  }

  const withoutDuplicate = nextNewsItem => !news.find(newsItem => newsItem.newsData.id === nextNewsItem.newsData.id);
  const nextNews = response.data.news.filter(withoutDuplicate);
  newsArticles[params.launcherId] = {
    news: [...news, ...nextNews],
    count: response.data.count
  };

  dispatch({
    type: GET_NEWS_ARTICLE,
    payload: newsArticles
  });
  dispatch(endLoadingNewsArticles);
};

export const updateNews = (updatedNews: NewsArticleWithPlanId, launcherId: string) => async (dispatch: Dispatch, getState: GetState<RootState>): Promise<void> => {
  const { launcherData: { newsArticles }, landing: { landing: { merchantId, _id: landingId } } } = getState();
  const { news = [], count } = newsArticles[launcherId] || {};

  const response: $AxiosXHR<NewsArticleWithPlanId> = await api.updateNews({
    launcherId, merchantId, landingId, newsId: updatedNews.newsData.id, data: updatedNews
  });
  if (response.status !== 200) {
    return;
  }
  
  const oldNewsIndex = news.findIndex(newsItem => newsItem.newsData.id === updatedNews.newsData.id);
  if (oldNewsIndex === undefined) {
    return;
  }
  news[oldNewsIndex] = response.data;
  const sortedNews = [
    ...news
      .filter(newsItem => !!newsItem.newsData.pin_to_top_order)
      .sort((newsItem, newsItemNext) => (newsItem.newsData.date > newsItemNext.newsData.date ? -1 : 1)),
    ...news
      .filter(newsItem => !newsItem.newsData.pin_to_top_order)
      .sort((newsItem, newsItemNext) => (newsItem.newsData.date > newsItemNext.newsData.date ? -1 : 1))
  ];
  newsArticles[launcherId] = {
    news: sortedNews,
    count
  };

  dispatch({
    type: UPDATE_NEWS_ARTICLE,
    payload: newsArticles
  });
};

export const deleteNews = (deletedNews: NewsArticleWithPlanId, launcherId: string) => async (dispatch: Dispatch, getState: GetState<RootState>): Promise<void> => {
  const { launcherData: { newsArticles }, landing: { landing: { merchantId, _id: landingId } } } = getState();
  const { news = [], count } = newsArticles[launcherId] || {};

  const response: $AxiosXHR<null> = await api.deleteNews({
    launcherId, merchantId, landingId, newsId: deletedNews.newsData.id
  });
  if (response.status !== 204) {
    return;
  }

  const newNewsArticles = news.filter(newsItem => newsItem.newsData.id !== deletedNews.newsData.id);
  newsArticles[launcherId] = {
    news: [...newNewsArticles],
    count: count - 1
  };

  dispatch({
    type: DELETE_NEWS_ARTICLE,
    payload: newsArticles
  });
};

export default function launcherDataReducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case INIT_LAUNCHER_DATA:
      return { ...state, ...action.payload, loadingLauncherList: false };
    case START_LOADING_NEWS_ARTICLES:
      return { ...state, loadingNewsArticles: true };
    case END_LOADING_NEWS_ARTICLES:
      return { ...state, loadingNewsArticles: false };
    case INIT_NEWS_ARTICLES:
    case UPDATE_NEWS_ARTICLES:
    case CREATE_NEWS_ARTICLE:
    case GET_NEWS_ARTICLE:
    case UPDATE_NEWS_ARTICLE:
    case DELETE_NEWS_ARTICLE:
      return { ...state, newsArticles: action.payload };
    case INIT_NEWS_CATEGORIES:
    case UPDATE_NEWS_CATEGORIES:
      return { ...state, newsCategories: action.payload };
    default:
      return state;
  }
}
