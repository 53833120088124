"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fiveSymbolsMap = exports.allowedLanguages = void 0;
const fiveSymbolsMap = {
  'ar-AE': 'ar',
  'bg-BG': 'bg',
  'cs-CZ': 'cs',
  'de-DE': 'de',
  'en-US': 'en',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'he-IL': 'he',
  'hu-HU': 'hu',
  'it-IT': 'it',
  'ja-JP': 'ja',
  'ko-KR': 'ko',
  'pl-PL': 'pl',
  'pt-BR': 'pt',
  'ro-RO': 'ro',
  'ru-RU': 'ru',
  'th-TH': 'th',
  'tr-TR': 'tr',
  'vi-VN': 'vi',
  'zh-CN': 'cn',
  'zh-TW': 'tw'
};
exports.fiveSymbolsMap = fiveSymbolsMap;
const allowedLanguages = ['ar-AE', 'bg-BG', 'cs-CZ', 'de-DE', 'en-US', 'es-ES', 'fr-FR', 'he-IL', 'hu-HU', 'it-IT', 'ja-JP', 'ko-KR', 'pl-PL', 'pt-BR', 'ro-RO', 'ru-RU', 'th-TH', 'tr-TR', 'vi-VN', 'zh-CN', 'zh-TW'];
exports.allowedLanguages = allowedLanguages;