import type { Authorization } from '#common/model/landing';
import type { FiveSymbolLocale } from '#common/utils/locale';
import type { Theme } from '../../../utils/login/types';

import { isCustomAuth } from '../../../experiments/customAuth/utils';
import {
  UserAccountServiceWB,
  UserAccountServiceJwt,
  UserAccountServiceUserId,
} from './user-account-services';

import { AuthorizationType } from '#common/model/landing';

type IUserAccountServiceJwt = {
  isPreviewFrameMode: boolean;
  projectId: string;
  locale: FiveSymbolLocale;
  loginId: string;
  loginWidgetGeneration: 0 | 1 | null;
  loginTheme: Theme | Record<string, unknown>;
};

export const getLoginService = ({
  isPreviewFrameMode,
  projectId,
  locale,
  loginId,
  loginWidgetGeneration,
  loginTheme,
  auth,
}: IUserAccountServiceJwt & { auth: Authorization }) => {
  if (isCustomAuth()) {
    return UserAccountServiceWB.getInstance({
      isPreviewFrameMode,
      projectId,
      locale,
      loginId,
      loginWidgetGeneration,
      loginTheme,
    });
  }
  if (auth.type === AuthorizationType.LOGIN && loginId) {
    return UserAccountServiceJwt.getInstance({
      isPreviewFrameMode,
      projectId,
      locale,
      loginId,
      loginWidgetGeneration,
      loginTheme,
    });
  }
  if (
    auth.type === AuthorizationType.USER_ID ||
    auth.type === AuthorizationType.WEBHOOK
  ) {
    return UserAccountServiceUserId.getInstance();
  }
  return {};
};
