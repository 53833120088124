/** @prettier */
// @flow
import React, { useState, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { usePopperTooltip } from 'react-popper-tooltip';
import { connect } from 'react-redux';

import type { IRenderMode } from '#common/flow-types/build';
import type { Authorization } from '#common/model/landing';
import type { FiveSymbolLocale } from '#common/utils/locale';
import type { RootState } from '../../../reducers/modules/site-builder';
import type { ObjectObserver } from '../../../utils/class-helper';
import type { IntlShape } from 'react-intl';

import { createStep } from '../../../../../API/src/default-data/values/userInstruction';
import { setAssets } from '../../../reducers/modules/site-builder/assets';
import {
  selectLandingLanguages,
  selectMerchantId,
} from '../../../reducers/modules/site-builder/landing';
import { closeUserIdModal } from '../../../reducers/modules/site-builder/modals';
import { changePageSettings } from '../../../reducers/modules/site-builder/pages';
import { closeLoginSettings } from '../../../reducers/modules/site-builder/spaces';
import { analyticsManager } from '../../../scripts/afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../../../scripts/afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../../../scripts/afterBlocks/analytics/analyticsCategories';
import { UserIdInputStatus } from '../../../scripts/blocks/topup/services';
import Button from '../../../ui-components/Editor/Button';
import { EditorText } from '../../../ui-components/Editor/EditorText';
import Input from '../../../ui-components/Editor/Input';
import InstructionCard from '../../../ui-components/Editor/InstructionCard';
import InstructionCut from '../../../ui-components/Editor/InstructionCut';
import { Media } from '../../../ui-components/Editor/Media';
import ModalWindow from '../../../ui-components/Editor/ModalWindow';
import { sendClick, XAEvents } from '../../../utils/init-xsolla-analytics';
import { isMobileScreen } from '../../../utils/mobile';
import {
  getInputPlaceholderTextForUserIdModal,
  getContinueButtonTextForUserIdModal,
  getInputIncorrectTextForUserIdModal,
  getInputIncorrectCopyPasteTextForUserIdModal,
} from '../../../utils/translation';

import { RenderMode } from '#common/flow-types/build';
import { ChangeablePageSettings } from '#common/types/model/landing';

import './style.pcss';

export const Status = {
  DEFAULT: 'default',
  PROCESSING: 'processing',
  INVALID: 'invalid',
  VALID: 'valid',
};

type UserIdModalProps = {
  auth: Authorization,
  locale: FiveSymbolLocale,
  languages: FiveSymbolLocale[],
  intl: IntlShape,
  renderMode: IRenderMode,
  setAssets: Function | typeof setAssets,
  changePageSettings: Function | typeof changePageSettings,
  userIdServiceObserver: ObjectObserver,
  onSubmit: (value: string, setStatus: Function) => Promise<void>,
  closeModal: () => void,
  isShowUserIdModal: boolean,
  merchantId: string,
  closeLoginSettings?: () => void,
};

const UserIdModalPureComponent = ({
  auth,
  locale,
  languages,
  intl,
  renderMode,
  setAssets,
  changePageSettings,
  userIdServiceObserver,
  onSubmit,
  closeModal,
  isShowUserIdModal,
  merchantId,
  closeLoginSettings,
}: UserIdModalProps) => {
  /**
   * Editor handlers
   */
  const handleSave = useCallback(
    // $FlowFixMe yet another flow problem with hooks
    (updatedAuth: Authorization, needSave: boolean = true) => {
      changePageSettings({
        propName: ChangeablePageSettings.AUTH,
        propValue: updatedAuth,
        needSave,
        needSetData: false,
      });
    },
    [changePageSettings]
  );

  const handleSaveInstructionText = useCallback(
    // $FlowFixMe
    (value: string) => {
      const updatedAuth = {
        ...auth,
        instruction: {
          ...auth.instruction,
          instructionCutTextTag: {
            ...auth.instruction.instructionCutTextTag,
            [locale]: value,
          },
        },
      };
      handleSave(updatedAuth);
    },
    [handleSave, auth, locale]
  );

  const handleAddStep = useCallback(() => {
    const newStep = createStep({ languages });
    const updatedAuth = {
      ...auth,
      instruction: {
        ...auth.instruction,
        steps: [...auth.instruction.steps, newStep],
      },
    };
    handleSave(updatedAuth);
    sendClick(merchantId, XAEvents.loginSettingsInstructionAddStep);
  }, [languages, auth, handleSave, merchantId]);

  const handleDeleteStep = (stepIndex: number) => () => {
    const updatedAuth = {
      ...auth,
      instruction: {
        ...auth.instruction,
        steps: auth.instruction.steps.filter((_, index) => index !== stepIndex),
      },
    };
    handleSave(updatedAuth);
    sendClick(merchantId, XAEvents.loginSettingsInstructionDeleteStep);
  };

  const setupAssetForMedia = (fieldName: 'logo' | 'background') => () => {
    setAssets({
      assetFunction: (updatedAsset, needSave) => {
        const updatedAuth = { ...auth, [fieldName]: updatedAsset };
        handleSave(updatedAuth, needSave);
      },
      currentAsset: auth[fieldName],
    });
  };

  const setupAssetForInstructionCard = (stepIndex: number) => () => {
    setAssets({
      assetFunction: (updatedAsset, needSave) => {
        const updatedAuth = {
          ...auth,
          instruction: {
            ...auth.instruction,
            steps: auth.instruction.steps.map((step, index) =>
              index === stepIndex ? { ...step, image: updatedAsset } : step
            ),
          },
        };
        handleSave(updatedAuth, needSave);
      },
      currentAsset: auth.instruction.steps[stepIndex].image,
    });
  };

  /**
   * Landing handlers
   */
  const [status, setStatus] = useState(Status.DEFAULT);
  const [inputValue, setInputValue] = useState('');

  const handleSubmitForm = useCallback(() => {
    if (renderMode === RenderMode.EDITING) {
      return;
    }
    analyticsManager.sendEvent({
      category: AnalyticsCategories.UID_WIDGET,
      event: AnalyticsActions.CONFIRM,
      page: `landing_uid-widget_input-userid-${AnalyticsActions.CONFIRM}`,
    });
    if (inputValue === '') {
      setStatus(Status.DEFAULT);
      return;
    }
    onSubmit(inputValue, setStatus).then(() => {
      const { status } = userIdServiceObserver.target;
      switch (status) {
        case UserIdInputStatus.NOT_FOUND:
        case UserIdInputStatus.INVALID:
          setStatus(Status.INVALID);
          break;
        case UserIdInputStatus.VALID:
        case UserIdInputStatus.VALIDATION_PASSED:
          setStatus(Status.VALID);
          closeModal();
          break;
        default:
          setStatus(Status.DEFAULT);
          break;
      }
    });
  }, [
    renderMode,
    setStatus,
    inputValue,
    userIdServiceObserver,
    onSubmit,
    closeModal,
  ]);

  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef } =
    usePopperTooltip({
      placement: isMobileScreen() ? 'top-start' : 'left',
    });

  const showModal = isShowUserIdModal || renderMode !== RenderMode.EDITING;
  if (!auth || !showModal) {
    return null;
  }

  const close = () => {
    if (renderMode === RenderMode.EDITING && closeLoginSettings) {
      closeLoginSettings();
    }
    if (status === Status.INVALID || userIdServiceObserver) {
      setStatus(Status.DEFAULT);
    }
    closeModal();
    analyticsManager.sendEvent({
      category: AnalyticsCategories.UID_WIDGET,
      event: AnalyticsActions.CLOSE_MODAL_USER_ID,
      page: `landing_uid-widget_${AnalyticsActions.CLOSE}`,
    });
  };

  return (
    <ModalWindow
      className='user-id-modal'
      bodyClassName='user-id-modal__content'
      isServerSideRender={renderMode !== RenderMode.EDITING}
      enabledBorder
      closeModal={close}
      customBackground={
        <>
          {auth.background.enable && (
            <Media
              className='custom-background'
              asset={auth.background}
              onMediaButtonClick={setupAssetForMedia('background')}
              renderMode={renderMode}
              isMainImage={false}
              showCustomBackground={auth.background.enable}
              mediaType='background'
            />
          )}
        </>
      }
    >
      {auth.logo.enable && (
        <div className='user-id-modal__logo'>
          <Media
            asset={auth.logo}
            onMediaButtonClick={setupAssetForMedia('logo')}
            renderMode={renderMode}
            isMainImage
          />
        </div>
      )}
      <EditorText
        text={auth.title[locale]}
        className='user-id-modal__title'
        locale={locale}
        values={auth}
        field='title'
        onBlur={() => handleSave(auth)}
        onCheckoutBlock={() => {}}
        isServerSideRender={renderMode !== RenderMode.EDITING}
      />
      <div className='user-id-modal__input' ref={setTriggerRef}>
        <Input
          onChange={setInputValue}
          placeholder={getInputPlaceholderTextForUserIdModal(locale)}
          ariaLabel='Enter user id'
          xaName='user-id-modal-input'
          colorTheme='no-active'
          colorizeBorder={status === Status.INVALID}
          hideButton
        />
      </div>
      {status === Status.INVALID && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'user-id-modal__input-tooltip-container',
          })}
        >
          {getInputIncorrectTextForUserIdModal(locale)}
          <br />
          {getInputIncorrectCopyPasteTextForUserIdModal(locale)}
          <div
            {...getArrowProps({
              className: 'user-id-modal__input-tooltip-arrow',
            })}
          />
        </div>
      )}
      <Button
        className='user-id-modal__button'
        onClick={handleSubmitForm}
        isLoading={status === Status.PROCESSING}
        isEnabledShadow
      >
        <span className='user-id-modal__button-text'>
          {getContinueButtonTextForUserIdModal(locale)}
        </span>
      </Button>
      {auth.instruction.enable && (
        <>
          <InstructionCut
            userInstruction={auth.instruction}
            locale={locale}
            renderMode={renderMode}
            saveInstructionCutText={handleSaveInstructionText}
            onCheckoutBlock={() => {}}
            isServerSideRender={renderMode !== RenderMode.EDITING}
            inModal
          >
            {auth.instruction.steps.map((step, stepIndex) => (
              <InstructionCard
                key={step._id}
                description={step.description[locale]}
                isServerSideRender={renderMode !== RenderMode.EDITING}
                step={step}
                locale={locale}
                onCheckoutBlock={() => {}}
                saveInstructionStepText={() => handleSave(auth)}
                onMediaButtonClick={setupAssetForInstructionCard(stepIndex)}
                deleteStep={handleDeleteStep(stepIndex)}
                canDelete
              />
            ))}
          </InstructionCut>
          {renderMode === RenderMode.EDITING && (
            <div className='user-id-modal__tab-step'>
              <button
                className='user-id-modal__add-step-button'
                onClick={handleAddStep}
              >
                {intl.messages['editor.topup.addStepButton.text']}
              </button>
            </div>
          )}
        </>
      )}
    </ModalWindow>
  );
};

UserIdModalPureComponent.defaultProps = {
  changePageSettings: () => {},
  setAssets: () => {},
  isShowUserIdModal: false,
};

export const UserIdModalWithoutRedux = injectIntl(UserIdModalPureComponent);

export const UserIdModal: Function = connect(
  ({ pages, landing, modals }: RootState): $Shape<UserIdModalProps> => ({
    auth: pages.pages[pages.currentPage].auth,
    locale: landing.locale,
    languages: selectLandingLanguages(landing),
    renderMode: landing.renderMode,
    isShowUserIdModal: modals.isShowUserIdModal,
    merchantId: selectMerchantId(landing),
  }),
  {
    changePageSettings,
    setAssets,
    closeModal: closeUserIdModal,
    closeLoginSettings,
  }
)(UserIdModalWithoutRedux);
