/** @prettier */
import { xsollaAnalytics } from '../helpers/analytics';
import { XsollaMetrikaEventNames } from './analytics/analyticsEventsList';

export default () => {
  // eslint-disable-next-line no-undef
  if ($('[data-fancybox]:not([href=""])').length > 0) {
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href =
      'https://cdn.xsolla.net/site-builder/static/jquery.fancybox.min.css';

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      'https://cdn.xsolla.net/site-builder/static/jquery.fancybox.min.js';
    script.addEventListener('load', () => {
      // eslint-disable-next-line no-undef
      $('[data-fancybox]:not([href=""])').fancybox({
        /* youtube API https://developers.google.com/youtube/player_parameters?hl=ru#Parameters */
        youtube: {
          autoplay: 0,
          controls: 2,
          disablekb: 1,
          showinfo: 0,
          fs: 1,
          rel: 0,
        },
        /* vimeo API https://developer.vimeo.com/player/sdk/embed */
        vimeo: {
          byline: false,
          title: false,
          autoplay: 0,
        },
      });

      // eslint-disable-next-line no-undef
      $('[data-fancybox="images"]').fancybox({
        buttons: ['close'],
        hash: false,
        thumbs: {
          autoStart: true,
          axis: 'x',
        },
        afterClose: () => {
          xsollaAnalytics((XA) =>
            XA.elementClick(XsollaMetrikaEventNames.CLOSE_IMAGE_FANCYBOX)
          );
        },
      });
    });
    document.head.appendChild(link);
    document.body.appendChild(script);
  }
};
