/** @prettier */
/* eslint-disable class-methods-use-this */
// @flow
import type { FiveSymbolLocale } from '#common/utils/locale';
import type { Balance } from '../../../components/Blocks/Header/UserBalance';
import type { WBUserDataPayload } from '../../../experiments/customAuth/types';
import type { Theme } from '../../../utils/login/types';
import type { UserDataPayload } from './utils';

import { xsollaAnalytics } from '../../helpers/analytics';
import {
  parseJwt,
  getTokenFromCookie,
  getBaseUrl,
  getSanitizedLoginRedirectUrl,
} from '../../helpers/common';
import { getVirtualCurrencyUserBalance } from '../store/api';
import { topupUserServiceObserver, UserIdInputStatus } from '../topup/services';
import { init, getWidgetGenerationInstance } from './generation';
import { logOut } from './utils';

import { fiveSymbolsMap } from '#common/utils/locale';

type Listener = () => void;

export interface IUserAccountService {
  +isAuthorized: boolean;
  +userName: string;
  +addListener: (fn: Listener) => void;
  +logOut: () => void;
  +picture?: string;
  +balance?: Balance;
  +updateBalance?: () => Promise<void>;
  +playerId?: string;
}

/**
 * Class-adapter for topupUserServiceObserver implements IUserAccountService
 */
export class UserAccountServiceUserId implements IUserAccountService {
  static instance: UserAccountServiceUserId | null = null;

  static getInstance() {
    if (!UserAccountServiceUserId.instance) {
      UserAccountServiceUserId.instance = new UserAccountServiceUserId();
    }
    return UserAccountServiceUserId.instance;
  }

  get isAuthorized() {
    return (
      !!topupUserServiceObserver.target.inputValue &&
      (topupUserServiceObserver.target.status ===
        UserIdInputStatus.VALIDATION_PASSED ||
        topupUserServiceObserver.target.status === UserIdInputStatus.VALID)
    );
  }

  get userName() {
    return (
      topupUserServiceObserver.target.userInfo.name ||
      topupUserServiceObserver.target.inputValue
    );
  }

  addListener(fn: Listener) {
    topupUserServiceObserver.addListener(fn);
  }

  logOut() {
    topupUserServiceObserver.target.inputValue = '';
    topupUserServiceObserver.target.status = UserIdInputStatus.CLEAR;
  }
}

type IUserAccountServiceJwt = {
  isPreviewFrameMode: boolean,
  projectId: string,
  locale: FiveSymbolLocale,
  loginId: string,
  loginWidgetGeneration: 0 | 1 | null,
  loginTheme: Theme | {},
};

export class UserAccountServiceJwt implements IUserAccountService {
  get isAuthorized() {
    return this._isAuthorized;
  }

  get userName() {
    return this._userName;
  }

  get picture() {
    return this._picture;
  }

  get balance() {
    return this._balance;
  }

  get userData() {
    return this._userData;
  }

  static instance: UserAccountServiceJwt | null = null;

  constructor({
    isPreviewFrameMode,
    projectId,
    locale,
    loginId,
    loginWidgetGeneration,
    loginTheme,
  }: IUserAccountServiceJwt) {
    this._projectId = projectId;
    this._locale = locale;
    const token = getTokenFromCookie(isPreviewFrameMode);
    this._userData = parseJwt(token);
    this._token = token || '';
    if (this._userData && token) {
      this._initUserAccount(this._userData);
    } else {
      this._initLoginWidget(loginId, loginWidgetGeneration, loginTheme);
    }
  }

  async updateBalance() {
    if (this._isAuthorized) {
      const locale = fiveSymbolsMap[this._locale];
      this._balance = await getVirtualCurrencyUserBalance(
        this._projectId,
        this._token,
        locale
      );
      this._notify();
    }
  }

  addListener(fn: Listener) {
    this._listeners.push(fn);
  }

  static getInstance({
    isPreviewFrameMode,
    projectId,
    locale,
    loginId,
    loginWidgetGeneration,
    loginTheme,
  }: IUserAccountServiceJwt) {
    if (!UserAccountServiceJwt.instance) {
      UserAccountServiceJwt.instance = new UserAccountServiceJwt({
        isPreviewFrameMode,
        projectId,
        locale,
        loginId,
        loginWidgetGeneration,
        loginTheme,
      });
    }
    return UserAccountServiceJwt.instance;
  }

  logOut() {
    if (this._isAuthorized) {
      logOut();
    }
  }

  _isAuthorized: boolean = false;

  _listeners: Listener[] = [];

  _userName: string;

  _userData: ?Object;

  _notify() {
    this._listeners.forEach((fn) => fn());
  }

  _picture: string;

  _balance: Balance;

  _token: string;

  _locale: FiveSymbolLocale;

  _projectId: string;

  _initUserAccount(userData: UserDataPayload) {
    this._picture = userData.picture;
    this._userName = this._getUserNameOrEmail(userData);
    this._isAuthorized = true;

    window.dispatchEvent(
      new CustomEvent('sb_xsolla_login_success', {
        detail: this._token,
      })
    ); // event is used for restricting access to certain content https://developers.xsolla.com/doc/site-builder/how-to/how-to-restrict-access-to-certain-content-on-site/ //

    xsollaAnalytics((XA) =>
      XA.elementClick('xsolla_login_authentication_success', {
        ev: userData.provider,
      })
    );

    this.updateBalance();
  }

  _initLoginWidget(
    loginId: string,
    loginWidgetGeneration: 0 | 1 | null,
    loginTheme: Theme | {}
  ) {
    const payloadData = {};
    const callbackUrl = getBaseUrl();
    const redirectUrl = getSanitizedLoginRedirectUrl();
    if (callbackUrl !== redirectUrl) {
      payloadData.redirectUrl = redirectUrl;
    }
    if (!getWidgetGenerationInstance()) {
      init({
        loginWidgetGeneration,
        loginId,
        locale: this._locale,
        callbackUrl,
        payloadData,
        loginTheme,
      });
    }
  }

  _getUserNameOrEmail = (userData: UserDataPayload) => {
    if (!userData) {
      return '';
    }
    if (userData.name) {
      const [firstName, lastName] = userData.name.split(' ');
      if (firstName && lastName) {
        return `${firstName.charAt(0)}.${lastName}`;
      }
    }
    if (userData.email) {
      return userData.email;
    }
    return '';
  };
}

// Warner Bros. custom authorization with partner_data in jwt
export class UserAccountServiceWB extends UserAccountServiceJwt {
  _playerId: ?string = '';

  get playerId() {
    return this._playerId;
  }

  constructor({
    isPreviewFrameMode,
    projectId,
    locale,
    loginId,
    loginWidgetGeneration,
    loginTheme,
  }: IUserAccountServiceJwt) {
    super({
      isPreviewFrameMode,
      projectId,
      locale,
      loginId,
      loginWidgetGeneration,
      loginTheme,
    });
    this._playerId = this._userData?.partner_data?.player_id; // eslint-disable-line camelcase
  }

  _initUserAccount(userData: WBUserDataPayload) {
    this._picture = '';
    this._userName = userData.partner_data?.player_name; // eslint-disable-line camelcase
    this._isAuthorized = true;

    window.dispatchEvent(
      new CustomEvent('sb_xsolla_login_success', {
        detail: this._token,
      })
    ); // event is used for restricting access to certain content https://developers.xsolla.com/doc/site-builder/how-to/how-to-restrict-access-to-certain-content-on-site/ //

    xsollaAnalytics((XA) =>
      XA.elementClick('xsolla_login_authentication_success', {
        ev: userData.provider,
      })
    );

    this.updateBalance();
  }

  static getInstance({
    isPreviewFrameMode,
    projectId,
    locale,
    loginId,
    loginWidgetGeneration,
    loginTheme,
  }: IUserAccountServiceJwt) {
    if (!UserAccountServiceWB.instance) {
      UserAccountServiceWB.instance = new UserAccountServiceWB({
        isPreviewFrameMode,
        projectId,
        locale,
        loginId,
        loginWidgetGeneration,
        loginTheme,
      });
    }
    return UserAccountServiceWB.instance;
  }
}
