/**
 * @prettier
 * @flow
 * */

import type { BundleType } from '#common/flow-types/store';
import type { BundleCartItemObject } from '../../../../utils/types';

import { CartItem } from './CartItem';
import { cartItemFactory } from './cartItemFactory';
import { GameKeyCartItem } from './GameKeyCartItem';
import { VICartItem } from './VICartItem';

import { filterAuthRequired } from '#common/utils/store';

export class BundleCartItem extends CartItem {
  _bundleType: BundleType;

  _content: Array<
    BundleCartItem | GameKeyCartItem | VICartItem | CartItem | any
  >;

  get bundleType(): BundleType {
    return this._bundleType;
  }

  get content() {
    return this._content;
  }

  filterAuthRequired(): boolean {
    // $FlowFixMe
    return filterAuthRequired(this._content);
  }

  constructor(props: BundleCartItemObject) {
    const { bundleType, content } = props;
    super(props);
    if (bundleType) {
      this._bundleType = bundleType;
    } // $FlowFixMe
    this._content = content.map(cartItemFactory);
    this._canChangeQuantity = this._bundleType === 'virtual_currency_package';
  }

  get tags() {
    return this._content.map<any>((item) => {
      let res = item.name;
      if (item.quantity > 1) {
        res += ` ×${item.quantity}`;
      }
      return res;
    });
  }

  toJSON() {
    return {
      ...super.toJSON(),
      bundleType: this._bundleType,
    };
  }
}
