import { EIGHTY_LV_MERCHANT } from '../config';

const MENU_CLASS = 'menu-settings__content';

export const scrollToElement = (
  element: HTMLElement,
  scrollOptions?: ScrollIntoViewOptions
) => {
  element.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
    ...scrollOptions,
  });
};

export const scrollToBlockById = (
  id: string,
  scrollOptions?: ScrollIntoViewOptions
) => {
  const element = document.getElementById(id);
  if (element !== null) {
    scrollToElement(element, scrollOptions);
  }
};

export const twinkleElement = (
  index: number,
  elementClass: string = MENU_CLASS
) => {
  const elements = document.getElementsByClassName(elementClass);
  if (!elements || elements.length !== index + 1) {
    return false;
  }
  const element = elements[index];
  element.classList.add(`${elementClass}--twinkle`);
  setTimeout(() => {
    element.classList.remove(`${elementClass}--twinkle`);
  }, 300);
  return true;
};

export const handleInputUrlSocialEmbed = (link: string) => {
  if (!/^https?:\/\/(.)+/.test(link)) {
    return `https://${link}`;
  }
  return link;
};

export const removeHtmlTagsFromString = (value = ''): string =>
  value.replace(/<[^>]*>/gi, '');

export const removeSpaceAndLower = (value = ''): string =>
  value.replace(/\s/g, '').toLowerCase();

export const isSubsUsed = (values: any[]) => {
  const result = {
    includesSubscriptionButton: false,
  };
  if (!values) {
    return result;
  }
  values.forEach((value) => {
    if (value.type) {
      if (
        value.components &&
        value.components.length > 0 &&
        !result.includesSubscriptionButton
      ) {
        const local = isSubsUsed(value.components);
        result.includesSubscriptionButton = local.includesSubscriptionButton;
      }

      if (
        !result.includesSubscriptionButton &&
        value.type === 'customButton' &&
        value.subtype === 'buy' &&
        value.enable &&
        value.value.buy &&
        value.value.buy.type === 'subscription'
      ) {
        result.includesSubscriptionButton = true;
      }
    }
  });
  return result;
};

export const parseMerchantToNumber = (merchantId: string) =>
  merchantId === '80lv' ? EIGHTY_LV_MERCHANT : Number(merchantId);

export const detectDevice = () =>
  navigator.userAgent.match(/Android/i) ||
  navigator.userAgent.match(/webOS/i) ||
  navigator.userAgent.match(/iPhone/i) ||
  navigator.userAgent.match(/iPad/i) ||
  navigator.userAgent.match(/iPod/i) ||
  navigator.userAgent.match(/BlackBerry/i) ||
  navigator.userAgent.match(/Windows Phone/i);

export const convertHTMLStringToElement = (
  htmlString: string
): Element | null | void => {
  const d: Element = document.createElement('div');
  d.innerHTML = htmlString;
  return d.firstElementChild;
};

export const getTitleFontClass = (titleFont: string) =>
  `custom-header-font--${removeSpaceAndLower(titleFont)}`;

export const getMainFontClass = (mainFont: string) =>
  `custom-main-font--${removeSpaceAndLower(mainFont)}`;
