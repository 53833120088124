/** @prettier */
/* eslint-disable */
import analyticsEvents from './analyticsEvents';
import { XSOLLA_FACEBOOK_PIXEL_ID } from '../../../config';
import { analyticsManager, FacebookPixel } from './analytics';
import { hideGdprConsent } from '../../landingAPI/hideGdprConsent';

export default (data) => {
  const { facebookPixel, domain, projectId } = data;

  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js'
  );
  if (!hideGdprConsent()) {
    window.fbq('init', XSOLLA_FACEBOOK_PIXEL_ID);
  }
  if (facebookPixel) {
    window.fbq('init', facebookPixel);
    window.fbq('track', 'PageView');
  }
  analyticsManager.subscribe(new FacebookPixel(window.fbq));
  return true;
};
