var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "Mehr anzeigen",
  "client.header.login.button": "Anmelden",
  "client.header.logout.button": "Abmelden",
  "client.user-id-modal.input-placeholder": "Benutzer-ID",
  "client.user-id-modal.input-incorrect-id": "Falsche Benutzer-ID.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "Versuche, deine ID aus dem Spiel zu kopieren und einzufügen.",
  "client.user-id-modal.continue-button": "Weiter",
  "client.view-nickname-modal.show-more.button": "Mehr anzeigen",
  "client.privacy-settings.open-button": "Datenschutzeinstellungen",
  "client.usa-privacy-settings.open-button": "Meine Daten nicht verkaufen",
  /* default-data page texts */
  "document.pages.auth.title": "Benutzer-ID eingeben",
  "document.pages.auth.placeholder": "Beispiel: 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "Wo finde ich meine Benutzer-ID?",
  "document.pages.auth.instruction.steps.description": "Erläutern Sie, wie der Benutzer seine persönliche ID finden kann. Sie können mehrere Schritte und Bilder hinzufügen.",
  "document.pages.seo.title": "Das neue Spiel ist endlich da!",
  "document.pages.seo.description": "Dein Spiel nimmt Spieler mit auf ein großes Abenteuer voller Gegner, Monster, Goldjäger und ernster Gefahren.",
  "document.pages.seo.ogTitle": "Das neue Spiel ist endlich da!",
  "document.pages.seo.ogDescription": "Dein Spiel nimmt Spieler mit auf ein großes Abenteuer voller Gegner, Monster, Goldjäger und ernster Gefahren.",
  "default-data.templates.google-play.contact-us": "Kontakt: {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "INGAME-WALLET AUFLADEN",
  "blocks.topup.values.description": "Benutzer-ID eingeben",
  "blocks.topup.values.userIdPlaceholder": "Beispiel: 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "WO FINDE ICH MEINE BENUTZER-ID?",
  "blocks.topup.values.userInstruction.stepTitleTag": "Schrittname",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "Erläutern Sie, wie der Benutzer seine persönliche ID finden kann. Sie können mehrere Schritte und Bilder hinzufügen.",
  "default-data.templates.topup.app-support": "App-Support",
  "default-data.templates.topup.age-raiting": "Altersfreigabe",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>SPIELTITEL</h1>",
  "blocks.retailers.values.title": "<h2>Wähle deine Edition</h2>",
  "blocks.hero.values.description": "Dein Spiel nimmt Spieler mit auf ein großes Abenteuer voller Gegner, Monster, Goldjäger und ernster Gefahren.",
  "blocks.hero.values.affiliate": "Die Einnahmen aus diesem Kauf werden mit Ihrem Partner/Affiliate geteilt",
  "blocks.hero.components.platforms.label": "Verfügbar für",
  "blocks.hero.components.subscribe.label": "E-Mail-Adresse eingeben",
  "blocks.hero.components.subscribe.values.text" : "Abonniere unseren Newsletter und erhalte aktuelle News und Updates",
  "blocks.hero.components.scroll.label": "Scrollen",
  "blocks.footer.values.name": "Text eingeben",
  "blocks.footer.values.affiliate": "Am Affiliate-Programm teilnehmen",
  "blocks.hero.components.buy.label": "Jetzt kaufen",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "Nicht verfügbar",
  "blocks.hero.components.customButton.link.label": "Schaltfläche einrichten",
  "blocks.header.components.customButton.link.label": "Schaltfläche einrichten",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "Nicht verfügbar",
  "blocks.packs.components.pack.customButton.link.label": "Schaltfläche einrichten",
  "blocks.packs.components.pack.standart.components.buy.label": "Jetzt kaufen",
  "blocks.packs.components.pack.premium.components.buy.label": "Jetzt kaufen",
  "blocks.packs.components.pack.ultimate.components.buy.label": "Jetzt kaufen",
  "blocks.packs.components.pack.platinum.components.buy.label": "Jetzt kaufen",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "Nicht verfügbar",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "Nicht verfügbar",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "Nicht verfügbar",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "Nicht verfügbar",
  "blocks.packs.components.pack.standart.value.ribbon": "Beliebt",
  "blocks.packs.components.pack.premium.value.ribbon": "Beliebt",
  "blocks.packs.components.pack.ultimate.value.ribbon": "Beliebt",
  "blocks.packs.components.pack.platinum.value.ribbon": "Beliebt",
  "blocks.description.values.title": "<h2>SPIELBESCHREIBUNG</h2>",
  "blocks.description.components.text.label": "Dein Spiel nimmt Spieler mit auf ein großes Abenteuer voller Gegner, Monster, Goldjäger und ernster Gefahren. Dramatische Ereignisse haben dein Leben auf den Kopf gestellt und jetzt musst du in einer neuen feindlichen Welt ums Überleben kämpfen. Entdecke, erkunde und erobere, während du in verschiedenen Dimensionen der Realität überlebst!",
  "blocks.faq.values.title": "<h2>FAQ</h2>",
  "blocks.faq.components.question.question": "Wie kann ich Ihr Spiel spielen?",
  "blocks.faq.components.question.answer": "Um Ihr Spiel zu spielen, müssen Sie es kaufen und Ihre E-Mail-Adresse angeben. Sie erhalten dann den Aktivierungsschlüssel, den Sie übermitteln müssen und schon können Sie das Spiel genießen.",
  "blocks.news.values.title": "<h2>Neuigkeiten</h2>",
  "blocks.news.values.description": "Schau dir unsere aktuellen Beiträge an und erfahre, was es Neues gibt.",
  "blocks.store.values.title": "<h2>Online-Shop</h2>",
  "blocks.store.buy_button.text": "JETZT FÜR {amount} KAUFEN",
  "blocks.embed.values.title": "<h2>Social-Media-Widgets</h2>",
  "blocks.embed.components.twitter.title": "<h3>TWITTER-FEED</h3>",
  "blocks.embed.components.facebook.title": "<h3>FACEBOOK- FEED</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "Edition auswählen",
  "blocks.packs.values.description": "Jede Edition umfasst exklusive Vorteile, die du nach dem Kauf erhältst.",
  "blocks.packs.components.pack.standart.value.subtext": "Ab sofort verfügbar",
  "blocks.packs.components.pack.premium.value.subtext": "Ab sofort verfügbar",
  "blocks.packs.components.pack.ultimate.value.subtext": "Ab sofort verfügbar",
  "blocks.packs.components.pack.platinum.value.subtext": "Ab sofort verfügbar",
  "blocks.packs.components.pack.standart.value.title": "<h3>Standard Edition</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>Premium Edition</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>Platinum Edition",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>Ultimate Edition</h3>",
  "blocks.packs.components.pack.standart.value.description": "Spielschlüssel, exklusives Abzeichen, Soundtrack, Karte und zusätzliches Rüstungspaket",
  "blocks.packs.components.pack.premium.value.description": "Spielschlüssel, exklusives Abzeichen, Soundtrack, Karte, zusätzliches Rüstungspaket und zusätzliches Waffenpaket",
  "blocks.packs.components.pack.platinum.value.description": "Spielschlüssel, exklusives Abzeichen, Soundtrack, Karte, ein digitales Artwork-Buch, zusätzliches Rüstungspaket, zusätzliches Waffenpaket, ein zufälliges Skin-Paket",
  "blocks.packs.components.pack.ultimate.value.description": "Spielschlüssel, exklusives Abzeichen, Soundtrack, Karte, ein digitales Artwork-Buch, digitales Song-Buch, zusätzliches Rüstungspaket, zwei zusätzliche Waffenpakete und drei zufällige Skin-Pakete",
  "blocks.subscriptions.values.card.title": "Zu Premium wechseln und Vorteile sichern",
  "blocks.subscriptions.values.title": "Abonnement aktivieren",
  "blocks.subscriptions.values.heading": "Abonnements",
  "blocks.subscriptions.values.bullets.one.title": "Digitale Inhalte",
  "blocks.subscriptions.values.bullets.one.description": "Bilder, Wallpaper, Behind-the-Scenes-Content, Soundtrack aus dem Spiel",
  "blocks.subscriptions.values.bullets.two.title": "Ingame-Vorteile",
  "blocks.subscriptions.values.bullets.two.description": "Charakter-Skins, visuelle Effekte, Storyline-Karten",
  "blocks.subscriptions.values.bullets.three.title": "3 Lootboxen",
  "blocks.subscriptions.values.bullets.three.description": "5 Extrapässe für Hall of Chaos, 10 Charakter-Wiedergeburten",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "Nächste Verlängerung",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "Jetzt verlängern",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "Abo-Modell verwalten",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "Abo-Modell auswählen:",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "Abonnement konfigurieren",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial} Tag(e) kostenlos",
  "editor.subscriptions-packs.button.then.price.per.day": "Danach {amount} pro Tag",
  "editor.subscriptions-packs.button.then.price.per.week": "Danach {amount} pro Woche",
  "editor.subscriptions-packs.button.then.price.per.month": "Danach {amount} pro Monat",
  "editor.subscriptions-packs.button.then.price.per.year": "Danach {amount} pro Jahr",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "Danach {amount} pro {periodValue} Tage",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "Danach {amount} pro {periodValue} Wochen",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "Danach {amount} pro {periodValue} Monate",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "Danach {amount} pro {periodValue} Jahre",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "Danach einmalig {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "als erste Zahlung, danach {amount} pro Tag",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "als erste Zahlung, danach {amount} pro Woche",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "als erste Zahlung, danach {amount} pro Monat",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "als erste Zahlung, danach {amount} pro Jahr",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "als erste Zahlung, danach einmalig {amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "als erste Zahlung, danach {amount} pro {periodValue} Tage",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "als erste Zahlung, danach {amount} pro {periodValue} Wochen",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "als erste Zahlung, danach {amount} pro {periodValue} Monate",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "als erste Zahlung, danach {amount} pro {periodValue} Jahre",
  "editor.subscriptions-packs.button.price.per.day": "{amount} pro Tag",
  "editor.subscriptions-packs.button.price.per.week": "{amount} pro Woche",
  "editor.subscriptions-packs.button.price.per.month": "{amount} pro Monat",
  "editor.subscriptions-packs.button.price.per.year": "{amount} pro Jahr",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{amount} pro {periodValue} Tage",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{amount} pro {periodValue} Wochen",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{amount} pro {periodValue} Monate",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{amount} pro {periodValue} Jahre",
  "editor.subscriptions-packs.button.price.per.lifetime": "einmalig {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Tag für {periodsCounts} Tag(e), danach {amount} pro Tag",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Woche für {periodsCounts}Woche(n), danach {amount} pro Woche",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Monat für {periodsCounts} Monat(e), danach {amount} pro Monat",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Jahr für {periodsCounts} Jahr(e), danach {amount} pro Jahr",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Tage für {periodsCounts} Laufzeit(en), danach {amount} pro {periodValue} Tage",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Wochen für {periodsCounts} Laufzeit(en), danach {amount} pro {periodValue} Wochen",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Monate für {periodsCounts} Laufzeit(en), danach {amount} pro {periodValue} Monate",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Jahre für {periodsCounts} Laufzeit(en), danach {amount} pro {periodValue} Jahre",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "Danach {amount} als erste Zahlung, danach {amountPromo} pro Tag",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "Danach {amount} als erste Zahlung, danach {amountPromo} pro Woche",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "Danach {amount} als erste Zahlung, danach {amountPromo} pro Monat",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "Danach {amount} als erste Zahlung, danach {amountPromo} pro Jahr",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "Danach {amount} als erste Zahlung, danach {amountPromo} pro {periodValue} Tage",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "Danach {amount} als erste Zahlung, danach {amountPromo} pro {periodValue} Wochen",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "Danach {amount} als erste Zahlung, danach {amountPromo} pro {periodValue} Monate",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "Danach {amount} als erste Zahlung, danach {amountPromo} pro {periodValue} Jahre",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "Danach {amount} als erste Zahlung, danach einmalig {amountPromo}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "für {periodsCounts} Tag(e), danach {amount} pro Tag",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "für {periodsCounts} Woche(n), danach {amount} pro Woche",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "für {periodsCounts} Monat(e), danach {amount} pro Monat",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "für {periodsCounts} Jahr(e), danach {amount} pro Jahr",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "für {periodsCounts} Laufzeit(en), danach {amount} pro {periodValue} Tage",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "für {periodsCounts} Laufzeit(en), danach {amount} pro {periodValue} Wochen",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "für {periodsCounts} Laufzeit(en), danach {amount} pro {periodValue} Monate",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "für {periodsCounts} Laufzeit(en), danach {amount} pro {periodValue} Jahre",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "für {periodsCounts} Zeitspanne(n), danach einmalig {amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, die Season endet am {periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, die Season beginnt am {periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "Danach {amount}, die Season endet am {periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "Danach {amount}, die Season beginnt am {periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Tag",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Woche",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Monat",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Jahr",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Tage",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Wochen",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Monate",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Jahre",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "Danach einmalig <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price": "Danach <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Tag",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Woche",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Monat",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro Jahr",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Tage",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Wochen",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Monate",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} pro {periodValue} Jahre",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "einmalig <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.then.price": "Danach {amount}",
  "editor.subscriptions-packs.button.season.ends": "Die Season endet am {periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "Die Season beginnt am {periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount}/Tag",
  "editor.subscriptions-packs.button.price.period.week": "{amount}/Woche",
  "editor.subscriptions-packs.button.price.period.month": "{amount}/Monat",
  "editor.subscriptions-packs.button.price.period.year": "{amount}/Jahr",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount}/{periodValue} Tage",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount}/{periodValue} Wochen",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount}/{periodValue} Monate",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount}/{periodValue} Jahre",
  "editor.subscriptions-packs.button.price.period.lifetime": "einmalig {amount}",
  "blocks.requirements.values.title": "<h2>Systemanforderungen</h2>",
  "blocks.requirements.components.platform_req.value.title": "Plattform",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "Grafik",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "Betriebssystem",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "Festplattenspeicher",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "Prozessor",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "Sound-Karte",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "Arbeitsspeicher",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "Minimum",
  "default-data.values.requirements.title.recommended": "Empfohlen",
  "default-data.values.requirements.item.directx.minimum": "Version 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2,5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "DirectX9 kompatibel",
  "default-data.values.requirements.item.sound_card.recommended": "DirectX9 kompatibel",
  "default-data.values.requirements.item.directx.recommended": "Version 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "Hallo!",
  "default-data.customization.receipt.description": "Vielen Dank für deinen Kauf! Es freut uns, dass du unser Spiel spielst.",
  "default-data.customization.receipt.footer": "© Alle Rechte vorbehalten.",
  "blocks.store.components.storeSection.title": "Artikel",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "Du hast den Newsletter erfolgreich abonniert!",
  "notification.hero.components.subscribe.subscription.success": "Wir haben dir soeben eine Bestätigungs-E-Mail gesendet. Klicke auf den Link in der E-Mail, um dein Abonnement zu bestätigen.",
  "notification.hero.components.subscribe.error": "Etwas ist schiefgelaufen. Versuche es später erneut oder wende dich an unser Integrationsteam: <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "notification.hero.components.subscribe.email.taken.error": "Du hast den Newsletter bereits abonniert.",
  /* notifications values END */
  "period.years_short": "Jahre",
  "period.months_short": "Monate",
  "period.weeks_short": "Wochen",
  "period.days_short": "Tage",
  "subscription.button.text": "Für {fullCurrency} pro {periodCount}&thinsp;{periodName}</br>abonnieren",
  "client.subscription.button.current.text": "Bereits abonniert",
  "client.subscription.button.configuration": "Abonnement verwalten",
  "client.subscription.button.lifetime_text": "Lebenslanges Abo</br>für {fullCurrency}",
  "client.thank_you_modal.title": "Vielen Dank fürs Abonnieren!",
  "client.thank_you_modal.description": "Dein Abonnement verlängert sich automatisch. Die Gebühr wird regelmäßig entsprechend der Abo-Laufzeit abgebucht. Du kannst das Abo jederzeit kündigen.",
  "client.thank_you_modal.input.description": "Gib unten deinen Namen ein, wenn du in der Liste unserer Abonnenten auftauchen möchtest.",
  "client.thank_you_modal.button.default.text": "Danke, schließen",
  "client.thank_you_modal.input.placeholder": "Dein Name",
  "client.thank_you_modal.button.show_list": "Alle Abonnenten anzeigen",
  "client.subscribers_list_modal.title": "Vollständige Liste der Abonnenten",
  "client.subscribers_list_modal.description": "Vielen Dank an alle Nutzer, die das Spiel abonniert haben!",
  "client.subscribers_list_modal.show_more_button": "Mehr anzeigen",
  "client.subscribers_list_modal.anonymous": "sowie anonyme Nutzer",
  "client.footer.button.list_of_subscribers": "Liste der Abonnenten",
  "client.footer.xsollalogo.powered": "Mit Unterstützung des", // копирайт в футере
  "client.footer.xsollalogo.sb": "Xsolla-Website-Baukastens",
  "client.empty_subscribers_modal.title": "Abonnenten werden hier aufgelistet",
  "client.empty_subscribers_modal.description": "Sei der Erste, der unser Spiel abonniert.",
  "client.empty_subscribers_modal.button": "Abonnement jetzt auswählen",
  "client.news.blurred.description": "Du benötigst ein {subscription_name}-Abo, um diesen Inhalt freizuschalten.",
  "client.news.blurred.button.join": "Jetzt abonnieren",
  "client.cart_modal.title": "Warenkorb",
  "client.cart_modal.item.pre-order": "(Vorbestellung)",
  "client.cart_modal.clear_all": "Alle löschen",
  "client.cart_modal.item_purchased_description": "Du hast den Gegenstand bereits gekauft",
  "client.cart_modal.subtotal_title": "Gesamtsumme",
  "client.cart_modal.subtotal_subtitle": "Es können zusätzlich Steuern und Gebühren anfallen",
  "client.cart_modal.continue_button": "Weiter einkaufen",
  "client.cart_modal.checkout_button": "Zur Kasse",
  "client.cart_modal.footer-auth": "Zum Speichern von Gegenständen im Warenkorb oder um zuvor gespeicherte Gegenstände anzuzeigen, musst du dich <button class=\'xl_auth\' data-xl-route=\'login\'>anmelden</button> bzw. <button class=\'xl_auth\' data-xl-route=\'signup\'>registrieren</button>",
  "client.cart_modal.empty_cart_description": "Dein Warenkorb ist leer. Such dir im Online-Shop etwas Tolles aus.",
  "client.cart_modal.empty_cart_open_store_button": "Zum Online-Shop",
  "client.cart_modal.email_label": "Aktivierungsschlüssel senden an:",
  "client.cart_modal.email_description": "Du erhältst einen Schlüssel, den du aktivieren musst, um das Spiel starten zu können.",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "Prämien",
  "client.cart_modal.promo_codes.apply": "Anwenden",
  "client.cart_modal.promo_codes.remove": "Entfernen",
  "client.cart_modal.promo_codes.placeholder": "Promocode",
  "client.cart_modal.promo_codes.error_incorrect": "Falscher Promocode.",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "Das Limit für die Promocodeeinlösung wurde erreicht.",
  "client.cart_modal.promo_codes.error_expired": "Der Promocode ist abgelaufen.",
  "client.cart_modal.promo_codes.error_could_not_apply": "Promocode konnte nicht angewandt werden. Versuche es später noch einmal.",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "Der Promocode ist für die Artikel in Ihrem Warenkorb nicht gültig.",
  "client.cart_modal.promo_codes.error_select_game_platform": "Wählen Sie eine Spielplattform um fortzufahren.",
  "client.cart_modal.promo_codes.select_drm": "Plattform auswählen",
  "client.cart_modal.promo_codes.tooltip_drm": "Zum Auswählen der Plattform den Promocode entfernen und ihn dann erneut anwenden",
  "client.bundle_modal.group_items": "Im Bundle enthaltene Items",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>Bronze</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>Digitale Inhalte</h3><p>✓ Art</p><p>✓ Wallpapers</p><p><span style=\'color:rgb(127, 96, 0);\'>× Spiel-Soundtracks </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Making-of-Inhalte</span></p><p><br></p><h3>3 Lootboxen</h3><p></h3><p>✓ Charakter-Skins</p><p><span style=\'color:rgb(127, 96, 0);\'>× Visuelle Effekte </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Storyline-Karten</span></p><p><br></p><h3>Vorteile im Spiel</h3><p>✓ x1 extra Pass für die Halle des Chaos</p><p>✓ x1 Charakter-Wiedergeburt</p><p><br></p><h3>Discord</h3><p>✓ Zugang zum Gamer-Kanal</p><p><span style=\'color:rgb(127, 96, 0);\'>× Zugang zum Entwickler-Kanal</span></p><p><br></p><h3>Forum</h3><p>✓ Exklusive Nickname-Farbe</p><p><span style=\'color:rgb(127, 96, 0);\'>× Exklusives Emoji</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Animiertes Profilbild </span></p><p>✓ x2 extra Punkte zur Bewertung von Beiträgen (täglich)</p><p><br></p><h3>Merchandise</h3><p>✓ Anstecker &amp; Sticker (halbjährlich)</p><p>✓ Hochwertige Poster (halbjährlich)</p><p><span style=\'color:rgb(127, 96, 0);\'>× Markenplüschspielzeug (jährlich)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Hochwertige Figuren (jährlich)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>Offline-Events</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× Kostenloser Zutritt zu unseren Computerspielemessen</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× Besuch der Firmenbüros (jährlich)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ Zugang zu Discord</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>Silber</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>Digitale Inhalte</h3><p>✓ Art</p><p>✓ Wallpapers</p><p><span style=\"color:rgb(127, 96, 0);\">× Spiel-Soundtracks</span></p><p>✓ Making-of-Inhalte</p><p><br></p><h3>6 Lootboxen</h3><p></h3><p>✓ Charakter-Skins</p><p>✓ Visuelle Effekte</p><p><span style=\"color:rgb(127, 96, 0);\">× Storyline-Karten</span></p><p><br></p><h3>Vorteile im Spiel</h3><p>✓ x2 extra Pass für die Halle des Chaos</p><p>✓ x2 Charakter-Wiedergeburt</p><h3><br></h3><h3>Discord</h3><p>✓ Zugang zum Gamer-Kanal</p><p><span style=\"color:rgb(127, 96, 0);\">× Zugang zum Entwickler-Kanal</span></p><p><br></p><h3>Forum</h3><p>✓ Exklusive Nickname-Farbe</p><p><span style=\"color:rgb(127, 96, 0);\">× Exklusives Emoji</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Animiertes Profilbild</span></p><p>✓ 5x extra Punkte zur Bewertung von Beiträgen (täglich)</p><p><br></p><h3>Merchandise</h3><p>✓ Anstecker &amp; Sticker (halbjährlich)</p><p>✓ Hochwertige Poster (halbjährlich)</p><p><span style=\"color:rgb(127, 96, 0);\">× Markenplüschspielzeug (jährlich)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× Hochwertige Figuren (jährlich)</span></p><p><br></p><h3>Offline-Events</h3><p>✓ Kostenloser Zutritt zu unseren Computerspielemessen</p><p><span style=\"color:rgb(127, 96, 0);\">× Besuch der Firmenbüros (jährlich)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ Zugang zu Discord</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>Gold</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>Digitale Inhalte</h3><p>✓ Art</p><p>✓ Wallpapers</p><p>✓ Spiel-Soundtracks</p><p>✓ Making-of-Inhalte</p><p><br></p><h3>9 Lootboxen</h3><p>✓ Charakter-Skins</p><p>✓ Visuelle Effekte</p><p>✓ Storyline-Karten</p><p><br></p><h3>Vorteile im Spiel</h3><p>✓ x5 extra Pass für die Halle des Chaos</p><p>✓ x10 Charakter-Wiedergeburt</p><p><br></p><h3>Discord</h3><p>✓ Zugang zum Gamer-Kanal</p><p>✓ Zugang zum Entwickler-Kanal</p><p><br></p><h3>Forum</h3><p>✓ Exklusive Nickname-Farbe</p><p>✓ Exklusives Emoji</p><p>✓ Animiertes Profilbild</p><p>✓ x12 extra Punkte zur Bewertung von Beiträgen (täglich)</p><p><br></p><h3>Merchandise</h3><p>✓ Anstecker &amp; Sticker (halbjährlich)</p><p>✓ Hochwertige Poster (halbjährlich)</p><p>✓ Markenplüschspielzeug (jährlich)</p><p>✓ Hochwertige Figuren (jährlich)</p><p><br></p><h3>Offline-Events</h3><p>✓ Kostenloser Zutritt zu unseren Computerspielemessen</p><p>✓ Besuch der Firmenbüros (jährlich)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ Zugang zu Discord</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>Digitale Inhalte</h3><p>Art</p><p>Wallpapers</p><p><span>Spiel-Soundtracks</span></p><p><span>Making-of-Inhalte</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>Lootboxen</h3><p>Charakter-Skins</p><p><span>Visuelle Effekte</span></p><p><span>Storyline-Karten</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>Vorteile im Spiel</h3><p>x1 extra Pass für die Halle des Chaos</p><p>x1 Charakter-Wiedergeburt</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>Zugang zum Gamer-Kanal</p><p><span>x Zugang zum Entwickler-Kanal</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>Forum</h3><p>Exklusive Nickname-Farbe</p><p><span>x Exklusives Emoji</span></p><p><span>x Animiertes Profilbild</span></p><p>x2 extra Punkte zur Bewertung von Beiträgen (täglich)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>Merchandise</h3><p>Anstecker &amp; Sticker (halbjährlich)</p><p>Hochwertige Poster (halbjährlich)</p><p><span>Markenplüschspielzeug (jährlich)</span></p><p><span>Hochwertige Figuren (jährlich)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>Offline-Events</h3><p>✓ Kostenloser Zutritt zu unseren Computerspielemessen</p><p>✓ Besuch der Firmenbüros (jährlich)",
  "client.buy_button.text": "Jetzt kaufen",
  "client.buy_button.unavailable": "Nicht verfügbar",
  "client.buy_button.pre-order": "Vorbestellen",
  "client.buy_button.checkout": "Zur Kasse",
  "client.store.cart.add_button": "In den Warenkorb",
  "client.store.cart.header_button": "Warenkorb",
  "client.store.no_items": "Der Online-Shop ist derzeit leer. Komm später wieder!",
  "client.store.error.gt10_gk": "Zum Schutz vor Betrug kannst du immer nur 10 Schlüssel gleichzeitig kaufen.",
  "client.store.error.no_keys": "Das Spiel ist derzeit nicht lieferbar und erst in Kürze wieder erhältlich. Du kannst uns gerne bei Fragen zur Verfügbarkeit kontaktieren.",
  "client.store.item.purchased": "Bereits gekauft",
  "client.store.item.price_from": "ab {price}",
  "client.store.item.show_more": "Details anzeigen",
  "client.store.successful_purchase": "Gratulation. Vielen Dank für deinen Kauf!",
  "client.store.not_enough_vc": "Nicht genügend virtuelle Währung vorhanden.</br>Stocke sie auf, um den Kauf abzuschließen.",
  "client.store.non_consumable_item": "Du hast diesen Gegenstand bereits zuvor gekauft, deswegen ist er nicht mehr erhältlich.",
  "client.store.user_id_input_before_submit_hint": "Die Artikel werden diesem Konto zugewiesen.",
  "client.store.user_id_input_after_submit_hint": "Du kaufst für:",
  "client.common_error": "Etwas ist schiefgelaufen. Versuche es später erneut oder wende dich an unser Integrationsteam: <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "client.user_account.balance_title": "Deine Bilanz",
  "client.header.logout": "Abmelden",
  "client.retailers.edition.title": "Wähle dein Exemplar aus",
  "client.retailers.country.title": "Wähle dein Land aus",
  "client.retailers.platform.title": "Wähle die Plattform aus",
  "client.retailers.retailer.title": "Wähle den Verkäufer aus",
  "client.retailers.edition.digital": "Digital",
  "client.retailers.edition.physical": "Physisch",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "Vom Entwickler kaufen",
  "client.retailers.expand_button.collapse": "Ausblenden",
  "client.retailers.buy_button.buy": "Jetzt kaufen",
  "blocks.retailers.components.retailers.standart.value.ribbon": "Beliebt",
  "blocks.retailers.components.retailers.standart.value.subtext": "Ab sofort verfügbar",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>Standard Edition</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "Spielschlüssel, exklusives Abzeichen, Soundtrack, Karte und zusätzliches Rüstungspaket",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>SPIELTITEL</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "Dein Spiel nimmt Spieler mit auf ein großes Abenteuer voller Gegner, Monster, Goldjäger und ernster Gefahren.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "Verfügbar für",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "E-Mail-Adresse eingeben",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "Abonniere unseren Newsletter und erhalte aktuelle News und Updates",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "Schaltfläche einrichten",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "Jetzt kaufen",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "Schaltfläche einrichten",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "Schaltfläche einrichten",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "Schaltfläche einrichten",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "Nicht verfügbar",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "Schaltfläche einrichten",
  "client.topup.input.errorMessage": "Etwas ist schief gelaufen. Stellen Sie sicher, dass Ihre Benutzer-ID korrekt ist, oder versuchen Sie es später erneut.",
  "client.topup.input.successMessage": "Sie haben sich mit der {userID}-ID angemeldet. Jetzt können Sie auf der Website Einkäufe tätigen.",
  "client.topup.input.username": "Benutzername: {name}",
  "client.topup.input.id": "ID: {id}",
  "client.topup.input.level": "Level: {level}",
  "client.topup.input.user_not_found": "Wir konnten keinen Benutzer mit der von dir angegebenen ID finden.<br/>Bitte versuche es erneut.",
  "client.topup.input.serverError": "Etwas ist schiefgelaufen.<br>Solltest du diese Nachricht öfters erhalten, {contact_us}.",
  "client.topup.input.serverError.contact_us": "wende dich bitte an uns",
  "client.topup.authorized_title": "Du kaufst für:",
  "blocks.store.values.custom_title": "<h2>{gameTitle}-Shop</h2>"
};

module.exports = localizations;
