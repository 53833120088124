/**
 * @prettier
 * @flow
 */

import cn from 'classnames';
import React from 'react';

import type { CartItemTemplateProps } from '../blocks/store/types';

import Tags from './Tags';

const CartItem = ({
  sku,
  preOrderText,
  selectedDRM,
  imageGameClass,
  imageUrl,
  name,
  isPurchased,
  purchasedText,
  drms,
  oneDrmName,
  isShowSelector,
  options,
  amount,
  isDiscount,
  amountWithoutDiscount,
  bundleTags,
  bonusText,
  isTemporaryItem,
  isBonus,
  quantity,
  selectPlatformText,
}: CartItemTemplateProps) => {
  return (
    <li
      className={cn('cart-order-item', { 'cart-order-item--bonus': !amount })}
      data-cart-modal-item-sku={sku}
      data-cart-modal-item-drm={selectedDRM}
    >
      <div className='cart-order-item__image-background'>
        <img
          className={`cart-order-item__image ${imageGameClass}`}
          src={imageUrl}
          alt='cart-order-item'
        />
      </div>
      <div className='cart-order-item__description'>
        <div>
          <span className='cart-order-item__description-name'>{name}</span>
          {preOrderText && (
            <span className='cart-order-item__description-pre-order'>{` ${preOrderText}`}</span>
          )}
        </div>
        {bundleTags && <Tags items={bundleTags} />}
        {isPurchased && (
          <div className='cart-order-item__description-purchased'>
            {purchasedText}
          </div>
        )}
      </div>
      {drms &&
        (oneDrmName ? (
          <div className='cart-order-item__drm-title'>{oneDrmName}</div>
        ) : (
          <div className='cart-order-item__drm-container'>
            <select
              className='cart-order-item__drm-selector'
              defaultValue=''
              data-cart-modal-item-drm-selector=''
              data-cart-modal-item-is-temporary={isTemporaryItem ? 'true' : ''}
              data-custom-selector=''
            >
              {isTemporaryItem && (
                <option value='' disabled>
                  {selectPlatformText}
                </option>
              )}
              {drms.map(({ sku, drm_name: name }) => (
                <option key={sku} value={sku}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        ))}
      {isShowSelector && (
        <div className='cart-order-item__count-container'>
          <select
            className='cart-order-item__count-selector'
            data-cart-modal-item-count-selector
            data-custom-selector
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      )}
      {isBonus && !drms && (
        <div className='cart-order-item__counter'>{quantity}</div>
      )}
      {amount ? (
        <>
          <div className='cart-order-item__price' data-cart-item-price>
            <div className='cart-order-item__price-total'>{amount}</div>
            {isDiscount && (
              <div className='cart-order-item__price-discount'>
                {amountWithoutDiscount}
              </div>
            )}
          </div>
          <button
            className='cart-order-item__remove-item'
            data-cart-modal-item-remove
          >
            <i className='material-icons'>close</i>
          </button>
        </>
      ) : (
        <div
          className='cart-order-item__price cart-order-item__bonus'
          data-cart-item-bonus
        >
          <div className='cart-order-item__price-total'>{bonusText}</div>
        </div>
      )}
    </li>
  );
};

export default CartItem;
