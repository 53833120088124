// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';

import type { ScriptsData } from '../types';

import { userIdModalDataid } from '../../components/ModalWindows/EditorModals';
import { UserIdModalWithoutRedux } from '../../components/ModalWindows/UserIdModal';
import { topupPartnerMethod } from '../../utils/api';
import { defaultLocale } from '../../utils/translation';
import { topupUserServiceObserver } from '../blocks/topup/services';
import { getCountryCode } from '../helpers/common';
import { onMethodfulSubmit, onMethodlessSubmit } from '../helpers/topup';
import * as modalWindow from '../ui-components/editor/modalWindow';

import { AuthorizationType } from '#common/model/landing';

export const initUserIdModal = (data: ScriptsData) => {
  const mountNode = document.querySelector(`[data-id=${userIdModalDataid}]`);
  if (!mountNode) {
    return;
  }

  const {
    auth,
    merchantId,
    projectId,
    locale,
    locales,
    renderMode
  } = data;

  let onSubmit;
  if (auth?.type === AuthorizationType.WEBHOOK) {
    onSubmit = onMethodfulSubmit(
      topupUserServiceObserver,
      topupPartnerMethod({
        projectId,
        merchantId,
        country: getCountryCode(),
        method: auth.validation.url
      })
    );
  } else {
    onSubmit = onMethodlessSubmit(
      topupUserServiceObserver,
      projectId
    );
  }
  
  const closeModal = () => {
    modalWindow.closeModalWindow(mountNode);
  };
  
  const element = (
    // quirk before we setup b2c localization in constructor
    <IntlProvider locale={defaultLocale}>
      <UserIdModalWithoutRedux
        auth={auth}
        onSubmit={onSubmit}
        locale={locale}
        languages={locales}
        renderMode={renderMode}
        userIdServiceObserver={topupUserServiceObserver}
        closeModal={closeModal}
        merchantId={merchantId}
      />
    </IntlProvider>
  );
  ReactDOM.render(element, mountNode);
};
