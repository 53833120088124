import type { StoreUniversalItemTypes } from '#common/flow-types/store';
import type { ScriptsState } from '../../reducers/types/scripts-state';
import type { StoreAPIDataService } from '../blocks/store/services/StoreAPIDataService';
import type { Translations } from '../types';

import {
  isCustomAuth,
  isDevCustomAuth,
} from '../../experiments/customAuth/utils';
import { scrollToBlockById } from '../../utils/common-helper';
import { openPayStationWidget } from '../../utils/paystation/open-widget';
import {
  getTokenForTopup,
  getPayStationToken,
} from '../../utils/paystation/paystation-token';
import { BuyButtonWidgetOptionsBuilder } from '../../utils/services/buyButtonWidget';
import {
  analyticsManager,
  sendEvent,
} from '../afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../afterBlocks/analytics/analyticsCategories';
import {
  getLastLoginSource,
  clearLastLoginSource,
  isLoginLastSource,
  LOGIN_SOURCES,
  openLoginWidget,
} from '../blocks/login';
import { closeBundleInfoModal } from '../blocks/store/bundle-modal';
import events from '../events';
import { getCookie } from '../helpers/common';
import { USER_LOGIN_TOKEN } from '../helpers/cookies';
import { getStoreTopUpGameSku } from '../helpers/customCodeSettings';
import { isSandboxMode } from '../helpers/sandbox';
import { getP2PScript } from '../helpers/thirdPartyScripts';
import { initTopupButton } from '../helpers/workWithPayStation';
import { getStore } from '../store';

import { AuthorizationType } from '#common/model/landing';
import { GK_TYPE, toStoreType } from '#common/utils/store';

type UniversalItem = {
  sku: string;
  drm: string;
  target: string;
  type: StoreUniversalItemTypes;
  isPreOrder: string;
  projectId: string;
};

const getAnalyticsCategoryForUniversalItem = (item: Element) => {
  const id = item.getAttribute('id');
  if (id) {
    if (id.includes('store')) {
      return AnalyticsCategories.STORE;
    }
    if (id.includes('packs')) {
      return AnalyticsCategories.PACKS;
    }
    if (id.includes('hero')) {
      return AnalyticsCategories.CTA;
    }
    if (id.includes('promoSlider')) {
      return AnalyticsCategories.PROMO;
    }
  }
  return AnalyticsCategories.STORE;
};

export const updateStoreBuyButtonText = ({
  target,
  text,
}: {
  target: string;
  text: string;
}) => {
  const btnContainer = document.querySelector(target);
  if (!btnContainer) {
    return false;
  }
  let btnTextContainer;
  let attemptCounter = 0;
  const intervalId = setInterval(() => {
    btnTextContainer = btnContainer.querySelector('.translate-message');
    if (btnTextContainer) {
      btnTextContainer.innerText = text;
      clearInterval(intervalId);
    } else if (attemptCounter > 20) {
      clearInterval(intervalId);
    }
    // eslint-disable-next-line no-plusplus
    attemptCounter++;
  }, 500);
  return true;
};

const authorizedFlow = (renderId: string) => {
  const id = getLastLoginSource();
  if (!id || renderId !== id) {
    return;
  }
  const interval = setInterval(() => {
    const BB: HTMLElement | null = document.getElementById(id);
    if (!BB) {
      return;
    }
    scrollToBlockById(id, { block: 'center' });
    BB.click();
    clearLastLoginSource();
    clearInterval(interval);
  }, 50);
};

const initBBBWidgetForGameKey = async ({
  projectId,
  universalItem,
  locale,
  analyticsCounterId,
  renderMode,
  userToken,
}) => {
  const { sku, drm, target, type } = universalItem;
  const { XBuyButtonWidget } = await getP2PScript();
  if (!XBuyButtonWidget) {
    return;
  }
  const store = getStore();
  const { userService } = store.getState().landingServices;

  const widgetOptions = new BuyButtonWidgetOptionsBuilder({
    projectId,
    selector: target,
    analyticsCounterId,
    locale,
  })
    .withSku(sku)
    .withDrm(drm)
    .withItemType(toStoreType(type))
    .withSandbox(isSandboxMode(renderMode))
    .withLoginToken(userToken);

  if (isDevCustomAuth()) {
    widgetOptions.withCustomParameters({
      player_id: userService.playerId,
    });
  }
  XBuyButtonWidget.create(widgetOptions.construct());
};

export const renderUniversalItemButton = async ({
  data,
  universalItem,
  block,
  translations,
  storeAPIDataService,
  requiresBuyButtonWidget,
}: {
  data: ScriptsState;
  universalItem: UniversalItem;
  block: HTMLDivElement;
  translations: Translations;
  storeAPIDataService: StoreAPIDataService;
  requiresBuyButtonWidget?: boolean; // TODO: remove this parameter and add text display in custom button without bb
}) => {
  const {
    locale,
    analyticsCounterId,
    renderMode,
    merchantId,
    environment,
    auth,
    payStationReturnUrl,
  } = data;
  const { sku, target, type, isPreOrder } = universalItem;
  const projectId = universalItem.projectId || data.projectId;
  const userToken = getCookie(USER_LOGIN_TOKEN);

  const gameSKU = getStoreTopUpGameSku();
  const button: HTMLElement | null = document.querySelector(target);
  if (!button) {
    return;
  }

  let handler;
  if (
    auth.type === AuthorizationType.WEBHOOK ||
    auth.type === AuthorizationType.USER_ID ||
    gameSKU
  ) {
    handler = (e) => {
      e.stopPropagation();
      initTopupButton({
        analyticsCategory: getAnalyticsCategoryForUniversalItem(button),
        button,
        renderMode,
        projectId,
        merchantId,
        sku,
        locale,
        environment,
        getToken: getTokenForTopup({
          renderMode,
          merchantId,
          projectId,
          sku,
          locale,
          environment,
          gameSKU,
          analyticsCounterId,
          translations,
          payStationReturnUrl,
        }),
      });
    };
    // TODO: NEED REMOVED
    if (requiresBuyButtonWidget) {
      initBBBWidgetForGameKey({
        projectId,
        universalItem,
        locale,
        analyticsCounterId,
        renderMode,
        userToken,
      });
    }
  } else if (type !== GK_TYPE && !requiresBuyButtonWidget) {
    handler = async () => {
      const token = await getPayStationToken({
        userToken,
        sku,
        projectId,
        locale,
        analyticsCounterId,
        renderMode,
        payStationReturnUrl,
      });
      openPayStationWidget({
        token,
        renderMode,
        language: locale,
      });
    };
  } else {
    initBBBWidgetForGameKey({
      projectId,
      universalItem,
      locale,
      analyticsCounterId,
      renderMode,
      userToken,
    });
  }

  button.addEventListener('click', (event) => {
    if (target.includes('modal')) {
      closeBundleInfoModal(sku);
    }
    handler?.(event);
    sendEvent({
      name: button.dataset.xaName || '',
      value: button.dataset.xaValue,
      extra: button.dataset.xaExtra,
    });
  });

  if (
    userToken &&
    !isLoginLastSource(LOGIN_SOURCES.STORE_BUY_BUTTON) &&
    !isLoginLastSource(LOGIN_SOURCES.CART)
  ) {
    authorizedFlow(target.replace('#', ''));
  }

  if (block && block.classList.contains('block--store')) {
    updateStoreBuyButtonText({
      target,
      text: isPreOrder
        ? translations['client.buy_button.pre-order']
        : translations['client.buy_button.text'],
    });
  }

  /**
   * Login is needed if bundle has VI or VC but topup has own login
   */
  if (
    !userToken &&
    type === 'bundle' &&
    auth.type === AuthorizationType.LOGIN &&
    auth.loginId
  ) {
    await storeAPIDataService.loadLackItems([universalItem]);
    const bundleData = storeAPIDataService.findBySku(sku);
    if (bundleData.filterAuthRequired()) {
      /**
       * Remove `#` character
       */
      const id = target.substr(1);
      const bundleButtons = document.querySelectorAll(target);
      openLoginWidget(isCustomAuth(), bundleButtons, { source: id });
      bundleButtons.forEach((button) =>
        button.addEventListener('click', () =>
          analyticsManager.sendEvent({
            category: getAnalyticsCategoryForUniversalItem(button),
            event: AnalyticsActions.OPEN_LOGIN,
            page: `landing_${AnalyticsActions.OPEN_LOGIN}`,
          })
        )
      );
    }
  }
};

export const initUniversalItemEventHandler = () => {
  window.addEventListener(
    events.universalItem.init,
    (event) => {
      renderUniversalItemButton(event.detail);
    },
    false
  );
};

export const handleUniversalItem = ({
  data,
  universalItem,
  block,
  translations,
  storeAPIDataService,
  requiresBuyButtonWidget,
}: {
  data: ScriptsState;
  universalItem: UniversalItem;
  block: HTMLDivElement;
  translations: Translations;
  storeAPIDataService: StoreAPIDataService;
  requiresBuyButtonWidget: boolean;
}) => {
  const event = new CustomEvent(events.universalItem.init, {
    detail: {
      data,
      universalItem,
      block,
      translations,
      storeAPIDataService,
      requiresBuyButtonWidget,
    },
  });
  window.dispatchEvent(event);
};
