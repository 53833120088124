// @flow

import type { FiveSymbolLocale } from '#common/utils/locale';
import type { Theme } from '../../../../utils/login/types';

import { FirstGeneration } from './firstGeneration';
import { ZeroGeneration } from './zeroGeneration';

let widgetGenerationInstance: FirstGeneration | ZeroGeneration | null = null;

export const getWidgetGenerationInstance = () => widgetGenerationInstance;

export const init = async ({
  loginWidgetGeneration,
  loginId,
  locale,
  callbackUrl,
  payloadData,
  loginTheme
}: {
  loginWidgetGeneration: 0 | 1 | null,
  loginId: string,
  locale: FiveSymbolLocale,
  callbackUrl: string,
  payloadData: Object,
  loginTheme: Theme | {}
}) => {
  if (loginWidgetGeneration === 1) {
    widgetGenerationInstance = new FirstGeneration({
      projectId: loginId,
      preferredLocale: locale,
      callbackUrl,
      payload: JSON.stringify(payloadData),
      _generation: 1
    },
    loginTheme);
    await widgetGenerationInstance.init();
  } else
  if (loginWidgetGeneration === 0) {
    widgetGenerationInstance = new ZeroGeneration({
      payload: JSON.stringify(payloadData),
      projectId: loginId,
      callbackUrl,
      locale,
      _generation: 0
    });
    await widgetGenerationInstance.init();
  }
};
