/**
 * @prettier
 * @flow
 */

import { UniversalCart } from '../../blocks/store/cart/UniversalCart';

type CartItemAnalyticsType = {
  id: string,
  quantity: number,
};

type CartItemsDataAnalyticsType = {
  items: CartItemAnalyticsType[],
  quantity: number,
  ids: string[],
};

export const getCartItemsDataForAnalytics = (
  cart: UniversalCart
): CartItemsDataAnalyticsType => {
  if (!cart) {
    return {
      items: [],
      quantity: 0,
      ids: [],
    };
  }
  const items = cart.items.map<CartItemAnalyticsType>((item) => {
    const { sku, quantity } = item.toStoreObject();
    return {
      id: sku,
      quantity,
    };
  });
  const quantity = items.reduce((acc, item) => acc + item.quantity, 0);
  const ids = items.map<string>((item) => item.id);

  return {
    items,
    quantity,
    ids,
  };
};
