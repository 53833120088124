/** @prettier */
// @flow
import cn from 'classnames';
import React, { useState, useCallback } from 'react';

import type { IRenderMode } from '#common/flow-types/build';
import type { UserInstruction } from '#common/flow-types/values';
import type { FiveSymbolLocale } from '#common/utils/locale';

import { analyticsManager } from '../../../scripts/afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../../../scripts/afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../../../scripts/afterBlocks/analytics/analyticsCategories';
import { EditorText } from '../EditorText';

import { RenderMode } from '#common/flow-types/build';

import './style.pcss';

type Props = {|
  userInstruction: UserInstruction,
  locale: FiveSymbolLocale,
  renderMode: IRenderMode,
  saveInstructionCutText: (value: string) => void,
  onCheckoutBlock: () => void,
  children: React$Node,
  inModal?: true,
  isServerSideRender: boolean,
|};

const InstructionCut = ({
  userInstruction,
  locale,
  renderMode,
  saveInstructionCutText,
  onCheckoutBlock,
  children,
  inModal,
  isServerSideRender,
}: Props) => {
  const [isOpen, setIsOpen] = useState(!isServerSideRender);
  const handleToggleCut = useCallback(() => {
    if (renderMode !== RenderMode.EDITING) {
      setIsOpen(!isOpen);
      if (!isOpen) {
        analyticsManager.sendEvent({
          category: AnalyticsCategories.UID_WIDGET,
          event: AnalyticsActions.CLICK_INSTRUCTION,
          page: `landing_uid-widget_${AnalyticsActions.CLICK_INSTRUCTION}`,
        });
      } else {
        analyticsManager.sendEvent({
          category: AnalyticsCategories.UID_WIDGET,
          event: AnalyticsActions.CLOSE_USER_ID_INSTRUCTION,
        });
      }
    }
  }, [renderMode, setIsOpen, isOpen]);

  return (
    <>
      <div
        className={cn(
          'ui-site-instruction-cut__title',
          { 'ui-site-instruction-cut__title--preview ': isServerSideRender },
          {
            'ui-site-instruction-cut__title--in-modal':
              inModal && isServerSideRender,
          }
        )}
        data-topup-instruction-title
      >
        <button
          className='ui-site-instruction-cut__button'
          onClick={handleToggleCut}
        >
          <EditorText
            size='medium'
            text={userInstruction.instructionCutTextTag[locale]}
            locale={locale}
            values={userInstruction}
            onBlur={saveInstructionCutText}
            field='instructionCutTextTag'
            isServerSideRender={renderMode !== RenderMode.EDITING}
            onCheckoutBlock={onCheckoutBlock}
          />
        </button>
      </div>
      <div
        className={cn('ui-site-instruction-cut__steps', {
          'ui-site-instruction-cut__steps--hidden': !isOpen,
        })}
        data-topup-instruction-steps
      >
        {children}
      </div>
    </>
  );
};

export default InstructionCut;
