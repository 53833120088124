export const once = (fn: (...args: any) => void) => {
  let called = false;

  // eslint-disable-next-line consistent-return
  return (...args: any[]) => {
    if (!called) {
      called = true;
      return fn(...args);
    }
  };
};

export function debounce(fn: (...args: any) => any, ms: number) {
  let timer;
  return (...args: any[]) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      // @ts-ignore
      fn.apply(this, args);
    }, ms);
  };
}
