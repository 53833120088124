// @flow

export type App = {
  show: Function
};

let instance: App | null = null;

export const getInstance = (): App | null => instance;

export const setInstance = (app: App) => {
  instance = app;
};
