import { validateYoutubeVideoUrl, validateVimeoUrl } from '#common/utils/video';

const EMAIL_REGEXP =
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i; // eslint-disable-line

export const validateURL = (
  url: string,
  options: { requireTLD: boolean } = { requireTLD: true }
) => {
  try {
    const _url = new URL(url);

    if (!options.requireTLD) {
      return true;
    }
    // Есть ли в hostname домен первого уровня (TLD)
    const splittedHostname = _url.hostname.split('.');
    const hasTLD = splittedHostname.length > 1;
    const TLD = splittedHostname[splittedHostname.length - 1];
    return hasTLD && TLD.length >= 2;
  } catch (err) {
    return false;
  }
};

export const validateURLWithRelative = (url: string) => {
  const regular =
    /(^((?:http(s)?|ftp):\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$|^(\/|#|mailto:|tel:)+([\w\-._~:/?#[\]@%!$&'()*+,;=.]*)+$)/;
  return regular.test(url);
};

export const validateEmail = (email: string) => {
  const res = email.match(
    /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
  ); // eslint-disable-line
  return res !== null && true;
};

export const validateEmailForPayStation = (email: string) =>
  EMAIL_REGEXP.test(email);

export const validateDiscordId = (id: string) => /\d{5}/.test(id);

export const validateYoutubeUrl = (url: string): boolean =>
  /^https?:\/\/(?:www\.)?(youtu\.be|youtube\.com)\/.+/.test(url);

export const validateUrlByDomain = (domain: string) => (url: string) =>
  new RegExp(`^(https?:\\/\\/)?(www.)?${domain}\\/(.+)\\/?`).test(url);

export const validateFacebookUrl = validateUrlByDomain('facebook.com');

export const validateTwitterUrl = validateUrlByDomain('twitter.com');

export const validateKickstarterUrl = validateUrlByDomain('kickstarter.com');

export const validateWechatUrl = validateUrlByDomain('wechat.com');

export const validateDiscordUrl = (url: string): boolean =>
  /^https?:\/\/(?:www\.)?(discord|discordapp)\.com\/.+/.test(url);

export const validateVkUrl = validateUrlByDomain('vk.com');

export const validateInstagramUrl = validateUrlByDomain('instagram.com');

export const validateGamepediaUrl = validateUrlByDomain('gamepedia.com');

export const validateTwitchUrl = validateUrlByDomain('twitch.tv');

export const validateLightBoxUrl = (url: string) =>
  validateYoutubeVideoUrl(url) || validateVimeoUrl(url);
