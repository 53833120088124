/** @prettier */
// @flow

import type { Config as FirstGenerationConfig } from './generation/firstGeneration';
import type { Config as ZeroGenerationConfig } from './generation/zeroGeneration';

import { xsollaAnalytics } from '../../helpers/analytics';
import { deleteCookie, getOldLoginWidgetTheme } from '../../helpers/common';
import { USER_LOGIN_TOKEN } from '../../helpers/cookies';
import { getWidgetGenerationInstance } from './generation';

const LAST_LOGIN_SOURCE_KEY = 'last-login-source';
const SB_LOGIN_THEME =
  'https://cdn.xsolla.net/site-builder/client/xsolla-login.css';
export const LOGIN_SOURCES = {
  CART: 'cart',
  STORE_BUY_BUTTON: /^store-item-/,
  CUSTOM_AMOUNT_BUTTON: /^custom-amount-/,
  HEADER: 'header',
  'SUBSCRIPTIONS-PACKS': 'subscriptions-packs',
};

export const LOGIN_WIDGET_ROUTES: {
  LOGIN: 1,
  SIGNUP: 2,
} = {
  LOGIN: 1,
  SIGNUP: 2,
};

export type LoginWidgetRoute = 1 | 2;

export const ATTRIBUTE_TO_ROUTE = {
  login: LOGIN_WIDGET_ROUTES.LOGIN,
  signup: LOGIN_WIDGET_ROUTES.SIGNUP,
};

export const getLastLoginSource = (): string =>
  localStorage.getItem(LAST_LOGIN_SOURCE_KEY) || '';

export const clearLastLoginSource = (): void =>
  localStorage.removeItem(LAST_LOGIN_SOURCE_KEY);

export const isLoginLastSource = (source: string | RegExp) => {
  if (source instanceof RegExp) {
    return source.test(getLastLoginSource());
  }
  return source === getLastLoginSource();
};

export const openLogin = ({
  source,
  route,
  isCustomAuth,
}: $Shape<{
  source: string | null,
  route?: LoginWidgetRoute,
  isCustomAuth: boolean,
}> = {}) => {
  if (!isCustomAuth) {
    const concreteRoute = route || LOGIN_WIDGET_ROUTES.LOGIN;
    if (source) {
      localStorage.setItem(LAST_LOGIN_SOURCE_KEY, source);
    }

    const widgetInstance = getWidgetGenerationInstance();

    if (widgetInstance) {
      widgetInstance.open(concreteRoute);
    }
  }
};

type InitButtonProps = {
  button: HTMLElement,
  source?: string | null,
  route?: LoginWidgetRoute,
  isCustomAuth?: boolean,
};

export const initLoginButton = ({
  button,
  source,
  route,
  isCustomAuth,
}: InitButtonProps) => {
  button.onclick = (e) => {
    e.stopPropagation();
    openLogin({ source, route, isCustomAuth });
  };
};

export const openLoginWidget = (
  isCustomAuth: boolean,
  loginButtons: NodeList<HTMLElement> | HTMLElement[],
  {
    sourceFinder,
    ...additionData
  }: {
    ...InitButtonProps,
    sourceFinder: (HTMLElement) => string | null,
  } = {}
) => {
  [...loginButtons].forEach((button) => {
    let source;
    if (sourceFinder) {
      source = sourceFinder(button);
    }
    initLoginButton({ source, ...additionData, button, isCustomAuth });
  });
};

export const logOut = () => {
  xsollaAnalytics((XA) => XA.elementClick('xsolla_login_click_logout'));
  deleteCookie(USER_LOGIN_TOKEN);
  window.location.reload(false);
};

export const getTheme = async (loginId: string) => {
  const customTheme = await getOldLoginWidgetTheme(loginId);
  // LOGIN_THEME - [SB-1299] тема логина, инициализированная через кастом код, имеет больший приоритет для обратной совместимости
  const theme = window.LOGIN_THEME || customTheme || SB_LOGIN_THEME;
  delete window.LOGIN_THEME;
  return theme;
};

export type ConfigMiddleware = ((
  config: $Shape<ZeroGenerationConfig>
) => $Shape<ZeroGenerationConfig>) &
  ((config: $Shape<FirstGenerationConfig>) => $Shape<FirstGenerationConfig>);
let configMiddleware: ConfigMiddleware = (config: any): any => config;

export const setConfigMiddleware = (middleware: typeof configMiddleware) => {
  configMiddleware = middleware;
};

export const getConfigMiddleware = () => configMiddleware;

export type UserDataPayload = {
  email: string,
  groups: Array<{
    id: number,
    name: string,
    is_default: boolean,
  }>,
  id: string,
  is_master: boolean,
  iss: string,
  name: string,
  picture: string,
  promo_email_agreement: boolean,
  provider: string,
  publisher_id: number,
  sub: string,
  type: string,
  username: string,
  xsolla_login_access_key: string,
  payload: string,
  has_attrs: ?boolean,
};
