/** @prettier */
// @flow
import cn from 'classnames';
import React from 'react';
import { injectIntl } from 'react-intl';

import type { StepOfUserInstruction } from '#common/flow-types/values';
import type { FiveSymbolLocale } from '#common/utils/locale';
import type { IntlShape } from 'react-intl';

import deleteIconSvg from '../../../assets/images/icons/delete-icon.svg';
import { XsollaMetrikaEventNames } from '../../../scripts/afterBlocks/analytics/analyticsEventsList';
import TooltipComponent from '../../xsolla-uikit/DeleteTooltip';
import { EditorText } from '../EditorText';
import MediaWithLink from '../Media/MediaWithLink';
import './style.pcss';

type Props = {
  isServerSideRender: boolean,
  step: StepOfUserInstruction,
  locale: FiveSymbolLocale,
  onCheckoutBlock: () => void,
  saveInstructionStepText: () => void,
  onMediaButtonClick: () => void,
  intl: IntlShape,
  deleteStep: (index: number) => void,
  canDelete: boolean,
};

const InstructionCard = ({
  isServerSideRender,
  step,
  locale,
  onCheckoutBlock,
  saveInstructionStepText,
  onMediaButtonClick,
  intl,
  deleteStep,
  canDelete,
}: Props) => (
  <section
    className={cn('ui-site-instruction-card', {
      'ui-site-instruction-card--preview': isServerSideRender,
    })}
  >
    <div className='ui-site-instruction-card__text'>
      <EditorText
        size='medium'
        text={step.description[locale]}
        locale={locale}
        values={step}
        onBlur={saveInstructionStepText}
        field='description'
        isServerSideRender={isServerSideRender}
        onCheckoutBlock={onCheckoutBlock}
      />
    </div>
    <div className='ui-site-instruction-card__image'>
      <MediaWithLink
        asset={step.image}
        onMediaButtonClick={onMediaButtonClick}
        isServerSideRender={isServerSideRender}
        mediaOptions={{
          href: step.image.img,
          'data-fancybox': 'images',
          'data-thumb': step.image.img,
        }}
        xaAttributes={{
          name: XsollaMetrikaEventNames.OPEN_USER_ID_INSTRUCTION_IMAGE,
        }}
      />
      {!isServerSideRender && canDelete && (
        <button
          className='ui-site-instruction-card__delete-btn ui-site-instruction-card__delete-btn--enable'
          onMouseOut={() => {}}
          onMouseOver={() => {}}
          onBlur={() => {}}
          onFocus={() => {}}
          onClick={deleteStep}
        >
          <TooltipComponent
            cssClasses={['ui-site-instruction-card__delete-icon']}
            label={intl.messages['editor.topup.deleteStepButton.tooltip']}
          />
        </button>
      )}
      {!isServerSideRender && !canDelete && (
        <div
          className='material-icons ui-site-instruction-card__delete-btn ui-site-instruction-card__delete-btn--disable'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: deleteIconSvg }}
        />
      )}
    </div>
  </section>
);
export default injectIntl(InstructionCard);
