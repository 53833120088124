// @flow
import React from 'react';

import { UserIdModal } from './UserIdModal';

export const userIdModalDataid = 'user-id-modal';

type Props = {
  isServerSideRender: boolean
};

export const EditorModals = ({ isServerSideRender }: Props) => (
  <div data-id={userIdModalDataid} hidden={isServerSideRender}>
    <UserIdModal />
  </div>
);
