type translationKeys =
  | 'client.news.show-more.button'
  | 'client.header.login.button'
  | 'client.header.logout.button'
  | 'client.user-id-modal.input-placeholder'
  | 'client.user-id-modal.input-incorrect-id'
  | 'client.user-id-modal.input-incorrect-id-copy-and-paste'
  | 'client.user-id-modal.continue-button'
  | 'client.view-nickname-modal.show-more.button'
  | 'client.privacy-settings.open-button'
  | 'client.usa-privacy-settings.open-button'
  | 'document.pages.auth.title'
  | 'document.pages.auth.placeholder'
  | 'document.pages.auth.instruction.instructionCutTextTag'
  | 'document.pages.auth.instruction.steps.description'
  | 'document.pages.seo.title'
  | 'document.pages.seo.description'
  | 'document.pages.seo.ogTitle'
  | 'document.pages.seo.ogDescription'
  | 'default-data.templates.google-play.contact-us'
  | 'blocks.topup.values.title'
  | 'blocks.topup.values.description'
  | 'blocks.topup.values.userIdPlaceholder'
  | 'blocks.topup.values.userInstruction.instructionCutTextTag'
  | 'blocks.topup.values.userInstruction.stepTitleTag'
  | 'blocks.topup.values.userInstruction.stepDescriptionTag'
  | 'default-data.templates.topup.app-support'
  | 'default-data.templates.topup.age-raiting'
  | 'blocks.hero.values.title'
  | 'blocks.retailers.values.title'
  | 'blocks.hero.values.description'
  | 'blocks.hero.values.affiliate'
  | 'blocks.hero.components.platforms.label'
  | 'blocks.hero.components.subscribe.label'
  | 'blocks.hero.components.subscribe.values.text'
  | 'blocks.hero.components.scroll.label'
  | 'blocks.footer.values.name'
  | 'blocks.footer.values.affiliate'
  | 'blocks.hero.components.buy.label'
  | 'blocks.hero.components.customButton.buy.unavailableBuyButtonLabel'
  | 'blocks.hero.components.customButton.link.label'
  | 'blocks.header.components.customButton.link.label'
  | 'blocks.header.components.customButton.buy.unavailableBuyButtonLabel'
  | 'blocks.packs.components.pack.customButton.link.label'
  | 'blocks.packs.components.pack.standart.components.buy.label'
  | 'blocks.packs.components.pack.premium.components.buy.label'
  | 'blocks.packs.components.pack.ultimate.components.buy.label'
  | 'blocks.packs.components.pack.platinum.components.buy.label'
  | 'blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel'
  | 'blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel'
  | 'blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel'
  | 'blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel'
  | 'blocks.packs.components.pack.standart.value.ribbon'
  | 'blocks.packs.components.pack.premium.value.ribbon'
  | 'blocks.packs.components.pack.ultimate.value.ribbon'
  | 'blocks.packs.components.pack.platinum.value.ribbon'
  | 'blocks.description.values.title'
  | 'blocks.description.components.text.label'
  | 'blocks.faq.values.title'
  | 'blocks.faq.components.question.question'
  | 'blocks.faq.components.question.answer'
  | 'blocks.news.values.title'
  | 'blocks.news.values.description'
  | 'blocks.store.values.title'
  | 'blocks.store.buy_button.text'
  | 'blocks.embed.values.title'
  | 'blocks.embed.components.twitter.title'
  | 'blocks.embed.components.facebook.title'
  | 'blocks.embed.components.twitch.title'
  | 'blocks.embed.components.discord.title'
  | 'blocks.packs.values.title'
  | 'blocks.packs.values.description'
  | 'blocks.packs.components.pack.standart.value.subtext'
  | 'blocks.packs.components.pack.premium.value.subtext'
  | 'blocks.packs.components.pack.ultimate.value.subtext'
  | 'blocks.packs.components.pack.platinum.value.subtext'
  | 'blocks.packs.components.pack.standart.value.title'
  | 'blocks.packs.components.pack.premium.value.title'
  | 'blocks.packs.components.pack.platinum.value.title'
  | 'blocks.packs.components.pack.ultimate.value.title'
  | 'blocks.packs.components.pack.standart.value.description'
  | 'blocks.packs.components.pack.premium.value.description'
  | 'blocks.packs.components.pack.platinum.value.description'
  | 'blocks.packs.components.pack.ultimate.value.description'
  | 'blocks.requirements.values.title'
  | 'blocks.requirements.components.platform_req.value.title'
  | 'blocks.requirements.components.platform_req.value.requirementList.directx.name'
  | 'blocks.requirements.components.platform_req.value.requirementList.graphics.name'
  | 'blocks.requirements.components.platform_req.value.requirementList.os.name'
  | 'blocks.requirements.components.platform_req.value.requirementList.storage.name'
  | 'blocks.requirements.components.platform_req.value.requirementList.processor.name'
  | 'blocks.requirements.components.platform_req.value.requirementList.sound_card.name'
  | 'blocks.requirements.components.platform_req.value.requirementList.memory.name'
  | 'default-data.values.requirements.title.windows'
  | 'default-data.values.requirements.title.apple'
  | 'default-data.values.requirements.title.linux'
  | 'default-data.values.requirements.title.minimum'
  | 'default-data.values.requirements.title.recommended'
  | 'default-data.values.requirements.item.directx.minimum'
  | 'default-data.values.requirements.item.graphics.minimum'
  | 'default-data.values.requirements.item.os.minimum'
  | 'default-data.values.requirements.item.os.recommended'
  | 'default-data.values.requirements.item.storage.minimum'
  | 'default-data.values.requirements.item.processor.minimum'
  | 'default-data.values.requirements.item.memory.minimum'
  | 'default-data.values.requirements.item.sound_card.minimum'
  | 'default-data.values.requirements.item.sound_card.recommended'
  | 'default-data.values.requirements.item.directx.recommended'
  | 'default-data.values.requirements.item.graphics.recommended'
  | 'default-data.values.requirements.item.storage.recommended'
  | 'default-data.values.requirements.item.processor.recommended'
  | 'default-data.values.requirements.item.memory.recommended'
  | 'default-data.customization.receipt.title'
  | 'default-data.customization.receipt.description'
  | 'default-data.customization.receipt.footer'
  | 'blocks.store.components.storeSection.title'
  | 'notification.hero.components.subscribe.email.send.success'
  | 'notification.hero.components.subscribe.subscription.success'
  | 'notification.hero.components.subscribe.error'
  | 'notification.hero.components.subscribe.email.taken.error'
  | 'period.years_short'
  | 'period.months_short'
  | 'period.weeks_short'
  | 'period.days_short'
  | 'subscription.button.text'
  | 'client.subscription.button.current.text'
  | 'client.subscription.button.configuration'
  | 'client.subscription.button.lifetime_text'
  | 'client.thank_you_modal.title'
  | 'client.thank_you_modal.description'
  | 'client.thank_you_modal.input.description'
  | 'client.thank_you_modal.button.default.text'
  | 'client.thank_you_modal.input.placeholder'
  | 'client.thank_you_modal.button.show_list'
  | 'client.subscribers_list_modal.title'
  | 'client.subscribers_list_modal.description'
  | 'client.subscribers_list_modal.show_more_button'
  | 'client.subscribers_list_modal.anonymous'
  | 'client.footer.button.list_of_subscribers'
  | 'client.footer.xsollalogo.powered'
  | 'client.footer.xsollalogo.sb'
  | 'client.empty_subscribers_modal.title'
  | 'client.empty_subscribers_modal.description'
  | 'client.empty_subscribers_modal.button'
  | 'client.news.blurred.description'
  | 'client.news.blurred.button.join'
  | 'client.cart_modal.title'
  | 'client.cart_modal.item.pre-order'
  | 'client.cart_modal.clear_all'
  | 'client.cart_modal.item_purchased_description'
  | 'client.cart_modal.subtotal_title'
  | 'client.cart_modal.subtotal_subtitle'
  | 'client.cart_modal.continue_button'
  | 'client.cart_modal.checkout_button'
  | 'client.cart_modal.footer-auth'
  | 'client.cart_modal.empty_cart_description'
  | 'client.cart_modal.empty_cart_open_store_button'
  | 'client.cart_modal.email_label'
  | 'client.cart_modal.email_description'
  | 'client.cart_modal.email_placeholder'
  | 'client.cart_modal.item.bonus'
  | 'client.cart_modal.promo_codes.apply'
  | 'client.cart_modal.promo_codes.remove'
  | 'client.cart_modal.promo_codes.placeholder'
  | 'client.cart_modal.promo_codes.error_incorrect'
  | 'client.cart_modal.promo_codes.error_limit_has_been_reached'
  | 'client.cart_modal.promo_codes.error_expired'
  | 'client.cart_modal.promo_codes.error_could_not_apply'
  | 'client.cart_modal.promo_codes.error_not_valid_for_items'
  | 'client.cart_modal.promo_codes.error_select_game_platform'
  | 'blocks.packs.components.pack.standart-cfd.value.title'
  | 'blocks.packs.components.pack.standart-cfd.value.description'
  | 'blocks.packs.components.pack.standart-cfd.value.subtext'
  | 'blocks.packs.components.pack.ultimate-cfd.value.title'
  | 'blocks.packs.components.pack.ultimate-cfd.value.description'
  | 'blocks.packs.components.pack.ultimate-cfd.value.subtext'
  | 'blocks.packs.components.pack.premium-cfd.value.title'
  | 'blocks.packs.components.pack.premium-cfd.value.description'
  | 'blocks.packs.components.pack.premium-cfd.value.subtext'
  | 'default-data.cfd.blocks.packs.components.pack.value.description-1'
  | 'default-data.cfd.blocks.packs.components.pack.value.description-2'
  | 'default-data.cfd.blocks.packs.components.pack.value.description-3'
  | 'default-data.cfd.blocks.packs.components.pack.value.description-4'
  | 'default-data.cfd.blocks.packs.components.pack.value.description-5'
  | 'default-data.cfd.blocks.packs.components.pack.value.description-6'
  | 'default-data.cfd.blocks.packs.components.pack.value.description-7'
  | 'client.buy_button.text'
  | 'client.buy_button.unavailable'
  | 'client.buy_button.pre-order'
  | 'client.buy_button.checkout'
  | 'client.store.cart.add_button'
  | 'client.store.cart.header_button'
  | 'client.store.no_items'
  | 'client.store.error.gt10_gk'
  | 'client.store.error.no_keys'
  | 'client.store.item.purchased'
  | 'client.store.item.price_from'
  | 'client.store.item.show_more'
  | 'client.store.successful_purchase'
  | 'client.store.not_enough_vc'
  | 'client.store.non_consumable_item'
  | 'client.store.user_id_input_before_submit_hint'
  | 'client.store.user_id_input_after_submit_hint'
  | 'client.common_error'
  | 'client.user_account.balance_title'
  | 'client.header.logout'
  | 'client.retailers.edition.title'
  | 'client.retailers.country.title'
  | 'client.retailers.platform.title'
  | 'client.retailers.retailer.title'
  | 'client.retailers.edition.digital'
  | 'client.retailers.edition.physical'
  | 'client.retailers.platform.pc'
  | 'client.retailers.platform.ps'
  | 'client.retailers.platform.xbox_one'
  | 'client.retailers.platform.switch'
  | 'client.retailers.retailer.default_retailer'
  | 'client.retailers.expand_button.collapse'
  | 'client.retailers.buy_button.buy'
  | 'blocks.retailers.components.retailers.standart.value.ribbon'
  | 'blocks.retailers.components.retailers.standart.value.subtext'
  | 'blocks.retailers.components.retailers.standart.value.title'
  | 'blocks.retailers.components.retailers.standart.value.description'
  | 'blocks.promoSlider.components.promoSlide.value.title'
  | 'blocks.promoSlider.components.promoSlide.value.description'
  | 'blocks.promoSlider.components.promoSlide.components.platforms.label'
  | 'blocks.promoSlider.components.promoSlide.components.subscribe.label'
  | 'blocks.promoSlider.components.promoSlide.components.subscribe.values.text'
  | 'blocks.promoSlider.components.promoSlide.components.lightBox.label'
  | 'blocks.promoSlider.components.promoSlide.components.buy.label'
  | 'blocks.promoSlider.components.promoSlide.components.customButton.link.label'
  | 'blocks.promoSlider.components.promoSlide.components.customButton.label'
  | 'blocks.promoSlider.components.promoSlide.components.customButton.buy.label'
  | 'blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel'
  | 'blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label'
  | 'client.topup.input.errorMessage'
  | 'client.topup.input.successMessage'
  | 'client.topup.input.username'
  | 'client.topup.input.id'
  | 'client.topup.input.level'
  | 'client.topup.input.user_not_found'
  | 'client.topup.input.serverError'
  | 'client.topup.input.serverError.contact_us'
  | 'client.topup.authorized_title'
  | 'blocks.store.values.custom_title';

declare global {
  interface Window {
    __TRANSLATIONS__: { [key in translationKeys]: string };
  }
}

export const translations = { ...window.__TRANSLATIONS__ };
