var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "顯示更多",
  "client.header.login.button": "登入",
  "client.header.logout.button": "登出",
  "client.user-id-modal.input-placeholder": "用戶識別碼",
  "client.user-id-modal.input-incorrect-id": "錯誤的用戶識別碼。",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "請嘗試從遊戲中複製並貼上您的用戶識別碼",
  "client.user-id-modal.continue-button": "繼續",
  "client.view-nickname-modal.show-more.button": "顯示更多",
  "client.privacy-settings.open-button": "隱私權設定",
  "client.usa-privacy-settings.open-button": "請勿擅自轉賣我的資料",
  /* default-data page texts */
  "document.pages.auth.title": "輸入您的用戶識別碼",
  "document.pages.auth.placeholder": "例如：123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "找不到您的用戶識別碼？",
  "document.pages.auth.instruction.steps.description": "說明使用者如何能找到個人識別碼。您可以加入一些步驟和圖片。",
  "document.pages.seo.title": "新遊戲現已正式推出！",
  "document.pages.seo.description": "遊戲帶領玩家親自體驗壯闊歷險記，充滿各種誹謗者、怪獸、掏金者，四處危機四伏。",
  "document.pages.seo.ogTitle": "新遊戲現已正式推出！",
  "document.pages.seo.ogDescription": "遊戲帶領玩家親自體驗壯闊歷險記，充滿各種誹謗者、怪獸、掏金者，四處危機四伏。",
  "default-data.templates.google-play.contact-us": "聯絡我們{email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "充值遊戲錢包",
  "blocks.topup.values.description": "輸入用戶識別碼",
  "blocks.topup.values.userIdPlaceholder": "例如：123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "找不到您的用戶識別碼？",
  "blocks.topup.values.userInstruction.stepTitleTag": "步驟名稱",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "說明使用者如何能找到個人識別碼。您可以加入一些步驟和圖片。",
  "default-data.templates.topup.app-support": "應用支援",
  "default-data.templates.topup.age-raiting": "年齡等級",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>遊戲標題</h1>",
  "blocks.retailers.values.title": "<h2>選擇你的版本</h2>",
  "blocks.hero.values.description": "遊戲帶領玩家親自體驗壯闊歷險記，充滿各種誹謗者、怪獸、掏金者，四處危機四伏。",
  "blocks.hero.values.affiliate": "與您的盟友共享這次採購收入",
  "blocks.hero.components.platforms.label": "遊戲適用對象",
  "blocks.hero.components.subscribe.label": "輸入電子郵件",
  "blocks.hero.components.subscribe.values.text" : "訂閱我們的電子報，以便取得最新動態和更新",
  "blocks.hero.components.scroll.label": "捲動畫面",
  "blocks.footer.values.name": "輸入文字",
  "blocks.footer.values.affiliate": "加入夥伴計劃",
  "blocks.hero.components.buy.label": "立即購買",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.hero.components.customButton.link.label": "設定按鈕",
  "blocks.header.components.customButton.link.label": "設定按鈕",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.packs.components.pack.customButton.link.label": "設定按鈕",
  "blocks.packs.components.pack.standart.components.buy.label": "立即購買",
  "blocks.packs.components.pack.premium.components.buy.label": "立即購買",
  "blocks.packs.components.pack.ultimate.components.buy.label": "立即購買",
  "blocks.packs.components.pack.platinum.components.buy.label": "立即購買",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.packs.components.pack.standart.value.ribbon": "熱門遊戲",
  "blocks.packs.components.pack.premium.value.ribbon": "熱門遊戲",
  "blocks.packs.components.pack.ultimate.value.ribbon": "熱門遊戲",
  "blocks.packs.components.pack.platinum.value.ribbon": "熱門遊戲",
  "blocks.description.values.title": "<h2>遊戲說明</h2>",
  "blocks.description.components.text.label": "遊戲會帶領玩家親自體驗壯闊歷險記，充滿各種誹謗者、怪獸、掏金獵人，到處危機四伏。隨著劇情發展，戲劇化事件即將翻轉你的人生，現在新世界充滿敵意，為了求生存，你可得奮力一搏。 歡迎立即探索、勘查並征服各種虛擬實境，力求生存！",
  "blocks.faq.values.title": "<h2>常見問題</h2>",
  "blocks.faq.components.question.question": "請問這款 Your Game 遊戲怎麼玩？",
  "blocks.faq.components.question.answer": "如欲開始暢玩這款 Your Game 遊戲，必須先購買遊戲，一併附上個人電子郵件。您會收到啓動序號。只要提交序號，就能開始暢玩遊戲。",
  "blocks.news.values.title": "<h2>最新動態</h2>",
  "blocks.news.values.description": "查看近期文章，掌握最新動態。",
  "blocks.store.values.title": "<h2>存放區</h2>",
  "blocks.store.buy_button.text": "現購價{amount}",
  "blocks.embed.values.title": "<h2>社群媒體小工具</h2>",
  "blocks.embed.components.twitter.title": "<h3>Twitter消息來源</h3>",
  "blocks.embed.components.facebook.title": "<h3>Facebook消息來源</h3>",
  "blocks.embed.components.twitch.title": "<h3>Twitch</h3>",
  "blocks.embed.components.discord.title": "<h3>Discord</h3>",
  "blocks.packs.values.title": "選取版本",
  "blocks.packs.values.description": "各大版本提供獨家津貼和福利，完成選購後，即可兌換。",
  "blocks.packs.components.pack.standart.value.subtext": "現正推出",
  "blocks.packs.components.pack.premium.value.subtext": "現正推出",
  "blocks.packs.components.pack.ultimate.value.subtext": "現正推出",
  "blocks.packs.components.pack.platinum.value.subtext": "現正推出",
  "blocks.packs.components.pack.standart.value.title": "<h3>標準版</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>進階版</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>白金版</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>終極版</h3>",
  "blocks.packs.components.pack.standart.value.description": "遊戲金鑰、獨家徽章、原聲帶、地圖一應俱全，還有額外護甲套裝",
  "blocks.packs.components.pack.premium.value.description": "遊戲金鑰、獨家徽章、原聲帶、地圖、額外護甲套裝一應俱全，還有額外武器套裝",
  "blocks.packs.components.pack.platinum.value.description": "遊戲金鑰、獨家徽章、原聲帶、地圖、數位畫冊、額外護甲套裝、額外武器套裝一應俱全，還可任選一組護膚套裝",
  "blocks.packs.components.pack.ultimate.value.description": "遊戲金鑰、獨家徽章、原聲帶、地圖、數位畫冊、數位歌本、額外護甲套裝、額外武器套裝兩組一應俱全，還可任選三組護膚套裝",
  "blocks.subscriptions.values.card.title": "選用進階版，好康多多",
  "blocks.subscriptions.values.title": "啟用訂閱項目",
  "blocks.subscriptions.values.heading": "訂閱項目",
  "blocks.subscriptions.values.bullets.one.title": "數位內容",
  "blocks.subscriptions.values.bullets.one.description": "圖片、桌布、幕後花絮內容、遊戲內原聲帶",
  "blocks.subscriptions.values.bullets.two.title": "遊戲內賞金",
  "blocks.subscriptions.values.bullets.two.description": "角色造型、視覺效果、故事線卡片",
  "blocks.subscriptions.values.bullets.three.title": "遊戲內寶箱共 3 款",
  "blocks.subscriptions.values.bullets.three.description": "Hall of Chaos 還有額外 5 次闖關機會，角色重生則有 10 次機會",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "下次續約",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "立即續約",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "管理方案",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "選取方案",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "設定訂閱項目",
  "editor.subscriptions-packs.button.trial.days.for.free": "可享{trial}天免費試用",
  "editor.subscriptions-packs.button.then.price.per.day": "接下來每天只要{amount}",
  "editor.subscriptions-packs.button.then.price.per.week": "接下來每週只要{amount}",
  "editor.subscriptions-packs.button.then.price.per.month": "接下來每月只要{amount}",
  "editor.subscriptions-packs.button.then.price.per.year": "接下來每年只要{amount}",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "接下來每隔{periodValue}天，只要{amount}",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "接下來每隔{periodValue}週，只要{amount}",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "接下來每隔{periodValue}月，只要{amount}",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "接下來每隔{periodValue}年，只要{amount}",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "接下來終身訂閱只要{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "首次付款後，每天只要{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "首次付款後，每週只要{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "首次付款後，每月只要{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "首次付款後，每年只要{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "首次付款後，終身訂閱只要{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "首次付款後，每隔{periodValue}天，只要{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "首次付款後，每隔{periodValue}週，只要{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "首次付款後，每隔{periodValue}月，只要{amount}",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "首次付款後，每隔{periodValue}年，只要{amount}",
  "editor.subscriptions-packs.button.price.per.day": "每日只要{amount}",
  "editor.subscriptions-packs.button.price.per.week": "每週只要{amount}",
  "editor.subscriptions-packs.button.price.per.month": "每月只要{amount}",
  "editor.subscriptions-packs.button.price.per.year": "每年只要{amount}",
  "editor.subscriptions-packs.button.price.per.day.multiple": "每隔{periodValue}天，只要{amount}",
  "editor.subscriptions-packs.button.price.per.week.multiple": "每隔{periodValue}週，只要{amount}",
  "editor.subscriptions-packs.button.price.per.month.multiple": "每隔{periodValue}月，只要{amount}",
  "editor.subscriptions-packs.button.price.per.year.multiple": "每隔{periodValue}年，只要{amount}",
  "editor.subscriptions-packs.button.price.per.lifetime": "終身訂閱只要{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "接下來每天只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}，訂閱期為：{periodsCounts}，接下來每天只要{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "接下來每週只要<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} 訂閱期為：{periodsCounts}，接著每週只要{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "接下來每月只要<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}訂閱期為：{periodsCounts}，接著每週只要{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "接下來每年只要<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} 訂閱期為：{periodsCounts}，接著每年只要{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "接下來<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔 {periodValue}天，共計{periodsCounts} 期，接著每隔{periodValue}天，只要{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "接下來<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔 {periodValue}週，共計{periodsCounts} 期，接著每隔{periodValue} 週，只要{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "接下來<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔 {periodValue}月，共計{periodsCounts}期，接著每隔{periodValue}月，只要{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "接下來<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔 {periodValue}年，共計{periodsCounts} 期，接著每隔{periodValue}年，只要{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "首次付款後，接下來只要{amount}，接著每天只要{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "首次付款後，接下來只要{amount}，接著每週只要{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "首次付款後，接下來只要{amount}，接著每月只要{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "首次付款後，接下來只要{amount}，接著每年只要{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "接下來首次付款只要{amount}，接著每隔{periodValue}天，促銷價為{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "接下來首次付款只要{amount}，接著每隔{periodValue}週，促銷價為{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "接下來首次付款只要{amount}，接著每隔{periodValue}月，促銷價為{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "接下來首次付款只要{amount}，接著每隔{periodValue}年，促銷價為{amountPromo}",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "首次付款後，接下來只要{amount}，接著終身訂閱只要{amountPromo}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "訂閱期為：{periodsCounts}，接著每天只要{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "訂閱期為：{periodsCounts}，接著每週只要{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "訂閱期為：{periodsCounts}，接著每月只要{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "訂閱期為：{periodsCounts}，接著每年只要{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "共{periodsCounts}期，接著每隔{periodValue}天，只要{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "共{periodsCounts}期，接著每隔 {periodValue}週，只要{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "共{periodsCounts}期，接著每隔 {periodValue}月，只要{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "共{periodsCounts}期，接著每隔 {periodValue}年，只要{amount}",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "訂閱期為：{periodsCounts}，接著終身訂閱只要{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "接下來只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}，當季優惠即將結束日期{periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "接下來只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}， 當季優惠即將開始日期{periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "接下來只要{amount}，當季優惠即將結束日期{periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "接下來{amount}，當季優惠即將開始日期{periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "接下來每天只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "接下來每週只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "接下來每月只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "接下來每年只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "接下來<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔 {periodValue}天",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "接下來<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔{periodValue}週",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "接下來<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔{periodValue}月",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "接下來<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔{periodValue}年",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "接下來終身訂閱只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price.promo.price": "接下來只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "每天只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "每週只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "每月只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "每年只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔 {periodValue}天",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔 {periodValue}週",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔{periodValue}月",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}每隔 {periodValue}年",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "終身訂閱只要<span class=\'subscriptions-container__price\'>{amount}</span>{amountPromo}",
  "editor.subscriptions-packs.button.then.price": "接下來只要{amount}",
  "editor.subscriptions-packs.button.season.ends": "當季優惠即將結束日期{periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "當季優惠即將開始日期{periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "每日才{amount}",
  "editor.subscriptions-packs.button.price.period.week": "每週才{amount}",
  "editor.subscriptions-packs.button.price.period.month": "每月才{amount}",
  "editor.subscriptions-packs.button.price.period.year": "每年才{amount}",
  "editor.subscriptions-packs.button.price.period.value.day": "每隔{periodValue}天，只要{amount}",
  "editor.subscriptions-packs.button.price.period.value.week": "每隔{periodValue}週，只要{amount}",
  "editor.subscriptions-packs.button.price.period.value.month": "每隔{periodValue}月，只要{amount}",
  "editor.subscriptions-packs.button.price.period.value.year": "每隔{periodValue}年，只要{amount}",
  "editor.subscriptions-packs.button.price.period.lifetime": "終身訂閱才{amount}",
  "blocks.requirements.values.title": "<h2>系統需求</h2>",
  "blocks.requirements.components.platform_req.value.title": "平台",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "圖示",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "作業系統",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "儲存空間",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "處理器",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "音效卡",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "記憶體",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "最低門檻",
  "default-data.values.requirements.title.recommended": "強力推薦",
  "default-data.values.requirements.item.directx.minimum": "9.0c版本",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2.5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "與 DirectX9 相容",
  "default-data.values.requirements.item.sound_card.recommended": "與 DirectX9 相容",
  "default-data.values.requirements.item.directx.recommended": "9.0c版本",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "大家好！",
  "default-data.customization.receipt.description": "感謝您的選購！非常感激您來玩遊戲。",
  "default-data.customization.receipt.footer": "© 版權所有。",
  "blocks.store.components.storeSection.title": "物品",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "您已成功訂閱！",
  "notification.hero.components.subscribe.subscription.success": "我們已將電子郵件確認信傳送給您。請訪問電子郵件中的連結，即可啓用個人訂閱。",
  "notification.hero.components.subscribe.error": "出了點狀況。請稍後再試一次，或透過<a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>聯絡我們的整合團隊。",
  "notification.hero.components.subscribe.email.taken.error": "您已訂閱。",
  /* notifications values END */
  "period.years_short": "年",
  "period.months_short": "個月",
  "period.weeks_short": "週",
  "period.days_short": "天",
  "subscription.button.text": "以每 {periodCount}&thinsp;{periodName} {fullCurrency}</br>訂閱",
  "client.subscription.button.current.text": "訂閱項目",
  "client.subscription.button.configuration": "管理訂閱項目",
  "client.subscription.button.lifetime_text": "訂閱終身方案，以{fullCurrency}支付",
  "client.thank_you_modal.title": "感謝您的訂閱！",
  "client.thank_you_modal.description": "訂閱即將自動更新，會按照訂閱條款定期向您收費。可隨時取消訂閱。",
  "client.thank_you_modal.input.description": "如果希望在訂閱者清單看見自己的大名，請在下方輸入您的姓名。",
  "client.thank_you_modal.button.default.text": "感謝，關閉",
  "client.thank_you_modal.input.placeholder": "您的姓名",
  "client.thank_you_modal.button.show_list": "顯示所有訂閱者",
  "client.subscribers_list_modal.title": "訂閱者完整清單",
  "client.subscribers_list_modal.description": "非常感謝所有加入我們並訂閱這個遊戲的玩家！",
  "client.subscribers_list_modal.show_more_button": "顯示更多",
  "client.subscribers_list_modal.anonymous": "以及匿名使用者",
  "client.footer.button.list_of_subscribers": "訂閱者清單",
  "client.footer.xsollalogo.powered": "提供者", // копирайт в футере
  "client.footer.xsollalogo.sb": "愛索拉建站工具",
  "client.empty_subscribers_modal.title": "訂閱者會列在此處",
  "client.empty_subscribers_modal.description": "成為第一個訂閱遊戲的人。",
  "client.empty_subscribers_modal.button": "立即選取訂閱",
  "client.news.blurred.description": "成功訂閱 {subscription_name} 就能解鎖這個內容。",
  "client.news.blurred.button.join": "現在加入",
  "client.cart_modal.title": "購物車",
  "client.cart_modal.item.pre-order": "（預售）",
  "client.cart_modal.clear_all": "清除所有",
  "client.cart_modal.item_purchased_description": "您已購買了這件物品",
  "client.cart_modal.subtotal_title": "總金額",
  "client.cart_modal.subtotal_subtitle": "可能涉及額外的稅費及費用",
  "client.cart_modal.continue_button": "繼續購物",
  "client.cart_modal.checkout_button": "前往付費",
  "client.cart_modal.footer-auth": "<button class=\'xl_auth\' data-xl-route=\'login\'>登入</button> 或 <button class=\'xl_auth\' data-xl-route=\'signup\'>註冊</button>，稍後才能到購物車那邊，儲存道具，或查看之前儲存的道具",
  "client.cart_modal.empty_cart_description": "您的購物車空空如也，現在就前往商店找些好玩的東西購買吧。",
  "client.cart_modal.empty_cart_open_store_button": "打開商店",
  "client.cart_modal.email_label": "發送遊戲啟用金鑰至：",
  "client.cart_modal.email_description": "您將會收到一個用於啟動遊戲的金鑰，才能開始遊戲。",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "額外獎勵",
  "client.cart_modal.promo_codes.apply": "套用",
  "client.cart_modal.promo_codes.remove": "移除",
  "client.cart_modal.promo_codes.placeholder": "優惠碼",
  "client.cart_modal.promo_codes.error_incorrect": "錯誤的促銷代碼。",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "已達兌換代碼上限。",
  "client.cart_modal.promo_codes.error_expired": "此促銷代碼已過期。",
  "client.cart_modal.promo_codes.error_could_not_apply": "未能套用促銷代碼，請稍後再試。",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "購物車內的道具無法再使用優惠碼。",
  "client.cart_modal.promo_codes.error_select_game_platform": "選取遊戲平台，才能繼續使用。",
  "client.cart_modal.promo_codes.select_drm": "選取平台",
  "client.cart_modal.promo_codes.tooltip_drm": "若要變更平台，請先移除優惠碼，然後再重新套用",
  "client.bundle_modal.group_items": "搭售道具",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>銅牌</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>數位內容</h3><p>✓ 藝術</p><p>✓ 桌布</p><p><span style=\'color:rgb(127, 96, 0);\'>外加遊戲內建原聲帶 </span></p><p><span style=\'color:rgb(127, 96, 0);\'>以及幕後花絮內容</span></p><p><br></p><h3>3 套遊戲內建</h3><h3>戰利品箱</h3><p>✓ 角色換裝</p><p><span style=\'color:rgb(127, 96, 0);\'>外加視覺效果 </span></p><p><span style=\'color:rgb(127, 96, 0);\'>加碼再送故事情節卡片</span></p><p><br></p><h3>遊戲內建津貼</h3><p>✓ 追加一張通往 Hall of Chaos 的額外通行證</p><p>✓ 加碼再送角色復活一次</p><p><br></p><h3>Discord</h3><p>✓ 取得玩家頻道的存取權</p><p><span style=\'color:rgb(127, 96, 0);\'>還可取得開發商頻道的存取權</span></p><p><br></p><h3>論壇</h3><p>✓ 專屬暱稱顏色</p><p><span style=\'color:rgb(127, 96, 0);\'>外加專屬表情符號</span></p><p><span style=\'color:rgb(127, 96, 0);\'>還有動態大頭貼 </span></p><p>✓ （每天）加碼再送額外點數兩點，即可替貼文排名</p><p><br></p><h3>商品</h3><p>✓ 圖釘和貼圖（每隔六週）</p><p>✓ 高品質海報（每隔六週）</p><p><span style=\'color:rgb(127, 96, 0);\'>有牌子的填充玩具（每年）</span></p><p><span style=\'color:rgb(127, 96, 0);\'>高品質公仔（每年）</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>離線活動</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>免費取得競電慣例存取權</span></p><p><span style=\'color:rgb(127, 96, 0);\'>（每年）參訪公司辦公室</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ 提供 Discord 存取權</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>銀牌</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>數位內容</h3><p>✓ 藝術</p><p>✓ 桌布</p><p><span style=\"color:rgb(127, 96, 0);\">外加遊戲內建原聲帶</span></p><p>✓ 幕後花絮內容</p><p><br></p><h3>6 套遊戲內建</h3><h3>戰利品箱</h3><p>✓ 角色換裝</p><p>✓ 視覺效果</p><p><span style=\"color:rgb(127, 96, 0);\">加碼再送故事情節卡片</span></p><p><br></p><h3>遊戲內建津貼</h3><p>✓ 追加兩張通往 Hall of Chaos 的額外通行證</p><p>✓ 加碼再送角色復活兩次</p><h3><br></h3><h3>Discord</h3><p>✓ 取得遊戲頻道的存取權</p><p><span style=\"color:rgb(127, 96, 0);\">還可取得開發商頻道的存取權</span></p><p><br></p><h3>論壇</h3><p>✓ 專屬暱稱顏色</p><p><span style=\"color:rgb(127, 96, 0);\">外加專屬表情符號</span></p><p><span style=\"color:rgb(127, 96, 0);\">還有動態大頭貼</span></p><p>✓ （每天）加碼再送額外點數5點，即可替貼文排名</p><p><br></p><h3>商品</h3><p>✓ 圖釘和貼圖（每隔六週）</p><p>✓ 高品質海報（每隔六週）</p><p><span style=\"color:rgb(127, 96, 0);\">有牌子的填充玩具（每年）</span></p><p><span style=\"color:rgb(127, 96, 0);\">（每年）加碼再送高品質公仔</span></p><p><br></p><h3>離線活動</h3><p>✓ 免費取得競電慣例存取權</p><p><span style=\"color:rgb(127, 96, 0);\">外加（每年）參訪公司辦公室</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ 提供 Discord 存取權</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>金牌</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>數位內容</h3><p>✓ 藝術</p><p>✓ 桌布</p><p>✓遊戲內建原聲帶</p><p>✓ 幕後花絮內容</p><p><br></p><h3>9 個遊戲內建 + 1 套傳奇戰利品箱</h3><p>✓ 角色換裝</p><p>✓ 視覺效果</p><p>✓ 故事情節卡片</p><p><br></p><h3>遊戲內建津貼</h3><p>✓ 追加5張通往 Hall of Chaos 的額外通行證</p><p>✓ 加碼再送角色復活十次</p><p><br></p><h3>Discord</h3><p>✓ 取得遊戲頻道的存取權</p><p>✓ 取得開發商頻道的存取權</p><p><br></p><h3>論壇</h3><p>✓ 專屬暱稱顏色</p><p>✓ 專屬表情符號</p><p>✓ 動態大頭貼</p><p>✓ （每天）加碼再送額外點數12點，即可替貼文排名</p><p><br></p><h3>商品</h3><p>✓ 圖釘和貼圖（每隔六週）</p><p>✓ 高品質海報（每隔六週）</p><p>✓ 有牌子的填充玩具（每年）</p><p>✓ 高品質公仔（每年）</p><p><br></p><h3>離線活動</h3><p>✓ 免費取得競電慣例存取權</p><p>✓ （每年）參訪公司辦公室</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ 提供 Discord 存取權</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>數位內容</h3><p>藝術</p><p>桌布</p><p><span>遊戲內建原聲帶</span></p><p><span>幕後花絮內容</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>遊戲內建</h3><h3>戰利品箱</h3><p>角色換裝</p><p><span>視覺效果 </span></p><p><span>故事情節卡片</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>遊戲內建津貼</h3><p>，外加一張通往 Hall of Chaos 的額外通行證</p><p>，加碼再送角色復活一次</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>取得玩家頻道的存取權l</p><p><span>，外加獲得開發商頻道</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>論壇</h3><p>專屬暱稱顏色</p><p><span>，外加專屬表情符號</span></p><p><span>，以及動態大頭貼</span></p><p>，（每天）加碼再送額外點數兩點，即可替貼文排名</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>商品</h3><p>圖釘和貼圖（每隔六週）</p><p>高品質海報（每隔六週）</p><p><span>有牌子的填充玩具（每年）</span></p><p><span>高品質公仔（每年）</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>線下活動</h3><p>✓ 免費到訪我們的電動遊戲展</p><p>✓ 參觀公司辦公室（每年一度）</p>",
  "client.buy_button.text": "立即購買",
  "client.buy_button.unavailable": "不可用",
  "client.buy_button.pre-order": "預售",
  "client.buy_button.checkout": "前往付費",
  "client.store.cart.add_button": "加入購物車",
  "client.store.cart.header_button": "購物車",
  "client.store.no_items": "目前存放區是空的。請稍後再回來！",
  "client.store.error.gt10_gk": "如欲保護自己，免於詐騙威脅，一次只能選購 10 組序號。",
  "client.store.error.no_keys": "目前遊戲尚無庫存，很好就會重新補貨。如需最新動態，歡迎隨時與我們聯絡。",
  "client.store.item.purchased": "已購買",
  "client.store.item.price_from": "{price} 起",
  "client.store.item.show_more": "顯示詳情",
  "client.store.successful_purchase": "成功。感謝選購！",
  "client.store.not_enough_vc": "虛擬貨幣餘額不足。</br>請先補足餘額，才能完成選購。",
  "client.store.non_consumable_item": "之前已選購此商品，目前暫不供貨。",
  "client.store.user_id_input_before_submit_hint": "這些商品將會存入這個賬戶。",
  "client.store.user_id_input_after_submit_hint": "您正為以下賬戶購買：",
  "client.common_error": "出了點狀況。請稍後再試一次，或透過<a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>聯絡我們的整合團隊。",
  "client.user_account.balance_title": "您的餘額",
  "client.header.logout": "登出",
  "client.retailers.edition.title": "選擇你的拷貝",
  "client.retailers.country.title": "選擇你的國家",
  "client.retailers.platform.title": "選擇平台",
  "client.retailers.retailer.title": "選擇賣家",
  "client.retailers.edition.digital": "數位版",
  "client.retailers.edition.physical": "實體版",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "從開發商購買",
  "client.retailers.expand_button.collapse": "摺疊",
  "client.retailers.buy_button.buy": "立即購買",
  "blocks.retailers.components.retailers.standart.value.ribbon": "熱門遊戲",
  "blocks.retailers.components.retailers.standart.value.subtext": "現正推出",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>標準版</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "遊戲金鑰、獨家徽章、原聲帶、地圖一應俱全，還有額外護甲套裝",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>遊戲標題</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "遊戲帶領玩家親自體驗壯闊歷險記，充滿各種誹謗者、怪獸、掏金者，四處危機四伏。",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "遊戲適用對象",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "輸入電子郵件",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "訂閱我們的電子報，以便取得最新動態和更新",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "設定按鈕",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "立即購買",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "設定按鈕",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "設定按鈕",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "設定按鈕",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "設定按鈕",
  "client.topup.input.errorMessage": "發生錯誤。確認使用者識別碼，或稍後再試。",
  "client.topup.input.successMessage": "已使用{userID}識別碼。可以馬上到官網上參觀選購。",
  "client.topup.input.username": "使用者名稱：{name}",
  "client.topup.input.id": "識別碼：{id}",
  "client.topup.input.level": "關卡：{level}",
  "client.topup.input.user_not_found": "無法使用指定識別碼來搜尋使用者。<br/>請稍後再試。",
  "client.topup.input.serverError": "發生錯誤。<br>倘若有多次收到簡訊，請 {contact_us}。",
  "client.topup.input.serverError.contact_us": "聯絡我們",
  "client.topup.authorized_title": "您正為以下賬戶購買：",
  "blocks.store.values.custom_title": "<h2>{gameTitle} 商店</h2>"
};

module.exports = localizations;
