/** @prettier */
// @flow
import type { Theme } from '../../../../../utils/login/types';

import { LOGIN_WIDGET_URL } from '../../../../../config';
import { xsollaAnalytics } from '../../../../helpers/analytics';
import {
  clearLastLoginSource,
  getConfigMiddleware,
  LOGIN_WIDGET_ROUTES,
} from '../../utils';

import './style.pcss';

let loginSdk = {};
if (typeof window !== 'undefined') {
  // require widget only in client render
  loginSdk = require('@xsolla/login-sdk');
}
const { Widget, WidgetPages } = loginSdk;

export type Config = {|
  _generation: 1,
  projectId?: string,
  preferredLocale?: string,
  url?: string,
  callbackUrl?: string,
  payload?: string,
|};

export class FirstGeneration {
  static CONTAINER_ID = 'widget';

  static mapRoutes(route: $Values<typeof LOGIN_WIDGET_ROUTES>) {
    switch (route) {
      case LOGIN_WIDGET_ROUTES.SIGNUP:
        return WidgetPages?.SignUpPage;
      case LOGIN_WIDGET_ROUTES.LOGIN:
      default:
        return WidgetPages?.LogInPage;
    }
  }

  _config: Config;

  _root: HTMLElement;

  _xl: Object;

  _loginTheme: Theme | {};

  constructor(config: Config, loginTheme: Theme | {}) {
    this._config = config;
    this._loginTheme = loginTheme;
    this._root = document.createElement('div');
  }

  initRoot() {
    this._root.id = FirstGeneration.CONTAINER_ID;
    this.toggleContainerVisibility();

    const { body } = document;
    if (body) {
      body.appendChild(this._root);
    }
  }

  toggleContainerVisibility() {
    this._root.classList.toggle('login-widget-1st-generation--hidden');
  }

  open(route: $Values<typeof LOGIN_WIDGET_ROUTES>) {
    this._xl.setPage(FirstGeneration.mapRoutes(route));
    this._xl.setTheme(this._loginTheme);
    this._xl.open();
    this.toggleContainerVisibility();
  }

  init() {
    this.initRoot();

    const xl = new Widget(
      getConfigMiddleware()({
        url: LOGIN_WIDGET_URL,
        ...this._config,
        _generation: 1,
      })
    );
    window.xl = xl;
    this._xl = xl;
    xl.mount('widget');

    xl.on(xl.events.Close, () => {
      clearLastLoginSource();
      this.toggleContainerVisibility();
      xl.close();
    });

    const loginButtons = document.querySelectorAll('.xl_auth');
    loginButtons.forEach((button) => {
      button.onclick = () => {
        this.open(LOGIN_WIDGET_ROUTES.LOGIN);
      };
    });

    xsollaAnalytics((XA) => {
      xl.on(xl.events.Close, () => {
        XA.elementClick('xsolla_login_close');
      });
    });
  }
}
