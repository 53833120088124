import $ from 'jquery';

import { getSitebuilderURL } from '../../utils/url-helper';
import { getCookie } from '../helpers/common';
import { USER_LOGIN_TOKEN } from '../helpers/cookies';
import { isSandboxMode } from '../helpers/sandbox';

import { initBlock } from './index';

export default ({ data, translations }) => {
  const PRIVATE_BLOCKS_SELECTOR = '.block.block--private';
  if (document.querySelectorAll(PRIVATE_BLOCKS_SELECTOR).length === 0) {
    return;
  }
  const {
    domain, environment, isPreviewFrameMode, renderMode
  } = data;
  const postData = isPreviewFrameMode
    ? {
      isPreview: isSandboxMode(renderMode),
      adminDefaultSubs: window.__USER_TYPE__
    }
    : {};
  if (!isPreviewFrameMode) {
    postData.xsolla_login_token_sb = getCookie(USER_LOGIN_TOKEN);
  }
  $.ajax({
    url: `${getSitebuilderURL(environment)}/privateblocks/${domain}`,
    data: postData,
    xhrFields: {
      withCredentials: true
    },
    success(result) {
      if (typeof result !== 'string') {
        return;
      }

      $('.block--private').each(function updateHtml() {
        const id = $(this).attr('id');
        const $data = $($.parseHTML(result));
        const $blockHTML = $data.find(`#${id}`);
        $(this).html($blockHTML.html());

        const $block = $(`#${id}`);
        const [blockName, blockId] = $block.attr('id').split('-');
        initBlock({
          block: $block.get(0),
          blockName,
          blockId,
          data,
          translations
        });
      });
    }
  });
};
