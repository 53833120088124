var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "הראו לי עוד",
  "client.header.login.button": "התחברות",
  "client.header.logout.button": "התנתקות",
  "client.user-id-modal.input-placeholder": "מזהה משתמש",
  "client.user-id-modal.input-incorrect-id": "מזהה המשתמש שהוזן שגוי.",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "כדאי העתיק את המזהה מהמשחק ולהדביק אותו כאן.",
  "client.user-id-modal.continue-button": "להמשך",
  "client.view-nickname-modal.show-more.button": "הראו לי עוד",
  "client.privacy-settings.open-button": "הגדרות פרטיות",
  "client.usa-privacy-settings.open-button": "אין למכור את הנתונים שלי",
  /* default-data page texts */
  "document.pages.auth.title": "הזנת מזהה המשתמש שלך",
  "document.pages.auth.placeholder": "לדוגמה: 123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "לא מצאת את מזהה המשתמש?",
  "document.pages.auth.instruction.steps.description": "כאן אפשר להסביר את השלבים לאיתור מזהה המשתמש האישי, וגם להוסיף תמונות אם רוצים.",
  "document.pages.seo.title": "המשחק החדש יצא עכשיו!",
  "document.pages.seo.description": "המשחק שלך לוקח את השחקים להרפתקה גדולה שמלאה במסיחי דעת, במפלצות, בציידי זהב ובסכנה רצינית.",
  "document.pages.seo.ogTitle": "המשחק החדש יצא עכשיו!",
  "document.pages.seo.ogDescription": "המשחק שלך לוקח את השחקים להרפתקה גדולה שמלאה במסיחי דעת, במפלצות, בציידי זהב ובסכנה רצינית.",
  "default-data.templates.google-play.contact-us": "צור קשר {email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "שדרג את הארנק שלך במשחק",
  "blocks.topup.values.description": "הזן מזהה משתמש",
  "blocks.topup.values.userIdPlaceholder": "לדוגמה: 123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "לא מצאת את מזהה המשתמש?",
  "blocks.topup.values.userInstruction.stepTitleTag": "שם השלב",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "כאן אפשר להסביר את השלבים לאיתור מזהה המשתמש האישי, וגם להוסיף תמונות אם רוצים.",
  "default-data.templates.topup.app-support": "תמיכה באפליקציה",
  "default-data.templates.topup.age-raiting": "דירוג גיל",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>כותרת המשחק שלך</h1>",
  "blocks.retailers.values.title": "<h2>בחר מהדורה</h2>",
  "blocks.hero.values.description": "המשחק שלך לוקח את השחקים להרפתקה גדולה שמלאה במסיחי דעת, במפלצות, בציידי זהב ובסכנה רצינית.",
  "blocks.hero.values.affiliate": "ההכנסות מהרכישה הזו ישותפו עם השותף שלך",
  "blocks.hero.components.platforms.label": "זמין ל",
  "blocks.hero.components.subscribe.label": "הכנס את כתובת הדוא\"ל שלך",
  "blocks.hero.components.subscribe.values.text" : "נרשמים לניוזלטר שלנו ומקבלים את כל החדשות והעדכונים הטריים",
  "blocks.hero.components.scroll.label": "בצע גלילה",
  "blocks.footer.values.name": "הכנס את הטקסט שלך",
  "blocks.footer.values.affiliate": "הצטרפו לתוכנית שותפים",
  "blocks.hero.components.buy.label": "קנה עכשיו",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "לא זמין",
  "blocks.hero.components.customButton.link.label": "הגדרת כפתור",
  "blocks.header.components.customButton.link.label": "הגדרת כפתור",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "לא זמין",
  "blocks.packs.components.pack.customButton.link.label": "הגדרת כפתור",
  "blocks.packs.components.pack.standart.components.buy.label": "קנה עכשיו",
  "blocks.packs.components.pack.premium.components.buy.label": "קנה עכשיו",
  "blocks.packs.components.pack.ultimate.components.buy.label": "קנה עכשיו",
  "blocks.packs.components.pack.platinum.components.buy.label": "קנה עכשיו",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "לא זמין",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "לא זמין",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "לא זמין",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "לא זמין",
  "blocks.packs.components.pack.standart.value.ribbon": "הכי פופולרי",
  "blocks.packs.components.pack.premium.value.ribbon": "הכי פופולרי",
  "blocks.packs.components.pack.ultimate.value.ribbon": "הכי פופולרי",
  "blocks.packs.components.pack.platinum.value.ribbon": "הכי פופולרי",
  "blocks.description.values.title": "</h2>תיאור המשחק<h2>",
  "blocks.description.components.text.label": "המשחק שלך לוקח את השחקנים להרפתקה גדולה שמלאה במסיחי דעת, במפלצות, בציידי זהב ובסכנה רצינית. התפתחות של אירועים דרמטיים הפכה את החיים שלך וכעת אתה נלחם כדי לשרוד בעולם חדש ועוין. תגלה, תחקור ותכבוש בעודך שורד במימדים שונים של מציאות!",
  "blocks.faq.values.title": "<h2>שאלות נפוצות</h2>",
  "blocks.faq.components.question.question": "איך משחקים ב-Your Game?",
  "blocks.faq.components.question.answer": "כדי לשחק ב-Your Game עליך לרכוש את המשחק ולציין את כתובת האימייל שלך. לאחר מכן יהיה עליך לספק את מפתח ההפעלה שיישלח אליך באימייל, ואפשר יהיה להתחיל לשחק.",
  "blocks.news.values.title": "<h2>חדשות</h2>",
  "blocks.news.values.description": "רוצים לדעת מה חדש? קראו את המאמרים האחרונים שלנו.",
  "blocks.store.values.title": "<h2>חנות</h2>",
  "blocks.store.buy_button.text": "לקנייה ב-{amount}",
  "blocks.embed.values.title": "<h2>ווידג\'טים של מדיה חברתית</h2>",
  "blocks.embed.components.twitter.title": "<h3>היזן טוויטר</h3>",
  "blocks.embed.components.facebook.title": "<h3>הזנת פייסבוק</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "בחירת מהדורה",
  "blocks.packs.values.description": "כל מהדורה כוללת הטבות בלעדיות שתקבל לאחר הרכישה",
  "blocks.packs.components.pack.standart.value.subtext": "זמין עכשיו",
  "blocks.packs.components.pack.premium.value.subtext": "זמין עכשיו",
  "blocks.packs.components.pack.ultimate.value.subtext": "זמין עכשיו",
  "blocks.packs.components.pack.platinum.value.subtext": "זמין עכשיו",
  "blocks.packs.components.pack.standart.value.title": "<h3>מהדורה סטנדרטית</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>מהדורת פרימיום</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>מהדורת פלטינום</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>מהדורה אולטימטיבית</h3>",
  "blocks.packs.components.pack.standart.value.description": "קוד המשחק, תג בלעדי, פסקול, מפה וערכת שריונות נוספת",
  "blocks.packs.components.pack.premium.value.description": "קוד המשחק, תג בלעדי, פסקול, מפה, ערכת שריונות נוספת וערכת נשקים נוספת",
  "blocks.packs.components.pack.platinum.value.description": "קוד המשחק, תג בלעדי, פסקול, מפה, ספר אמנות דיגיטלי, ערכת שריונות נוספת, ערכת נשקים נוספת, ערכת סקינים רנדומלית אחת",
  "blocks.packs.components.pack.ultimate.value.description": "קוד המשחק, תג בלעדי, פסקול, מפה, ספר אמנות דיגיטלי, ספר שירים דיגיטלי, ערכת שריונות נוספת, שתי ערכות נשקים נוספות ושלוש ערכות סקינים רנדומליות",
  "blocks.subscriptions.values.card.title": "משדרגים למינוי פרימיום ומקבלים הטבות",
  "blocks.subscriptions.values.title": "הפעלת מינוי",
  "blocks.subscriptions.values.heading": "מינויים",
  "blocks.subscriptions.values.bullets.one.title": "תוכן דיגיטלי",
  "blocks.subscriptions.values.bullets.one.description": "תמונות, טפט, תוכן מאחורי הקלעים, פסקול משחק",
  "blocks.subscriptions.values.bullets.two.title": "הטבות במשחק",
  "blocks.subscriptions.values.bullets.two.description": "סקינים של הדמויות, אפקטים חזותיים, כרטיסי עלילה",
  "blocks.subscriptions.values.bullets.three.title": "3 קופסאות שלל במשחק",
  "blocks.subscriptions.values.bullets.three.description": "5 מעברים נוספים ל-Hall of Chaos, ‏10 חזרות לחיים של הדמות במשחק",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "החידוש הבא",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "חידוש המינוי",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "ניהול התוכנית",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "בחירת תוכנית:",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "הגדרת המינוי",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial} ימים בחינם",
  "editor.subscriptions-packs.button.then.price.per.day": "אחר כך {amount} ליום",
  "editor.subscriptions-packs.button.then.price.per.week": "אחר כך {amount} לשבוע",
  "editor.subscriptions-packs.button.then.price.per.month": "אחר כך {amount} לחודש",
  "editor.subscriptions-packs.button.then.price.per.year": "אחר כך {amount} לשנה",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "אחר כך {amount} למשך {periodValue} ימים",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "אחר כך {amount} למשך {periodValue} שבועות",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "אחר כך {amount} למשך {periodValue} חודשים",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "אחר כך {amount} למשך {periodValue} שנים",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "אחר כך {amount} לכל החיים",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "תשלום ראשון ואחר כך {amount} ליום",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "תשלום ראשון ואחר כך {amount} לשבוע",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "תשלום ראשון ואחר כך {amount} לחודש",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "תשלום ראשון ואחר כך {amount} לשנה",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "תשלום ראשון ואחר כך {amount} לכל החיים",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "תשלום ראשון, ואחר כך {amount} למשך {periodValue} ימים",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "תשלום ראשון, ואחר כך {amount} למשך {periodValue} שבועות",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "תשלום ראשון, ואחר כך {amount} למשך {periodValue} חודשים",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "תשלום ראשון, ואחר כך {amount} למשך {periodValue} שנים",
  "editor.subscriptions-packs.button.price.per.day": "{amount} ליום",
  "editor.subscriptions-packs.button.price.per.week": "{amount} לשבוע",
  "editor.subscriptions-packs.button.price.per.month": "{amount} לחודש",
  "editor.subscriptions-packs.button.price.per.year": "{amount} לשנה",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{amount} למשך {periodValue} ימים",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{amount} למשך {periodValue} שבועות",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{amount} למשך {periodValue} חודשים",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{amount} למשך {periodValue} שנים",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount} לכל החיים",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} ליום למשך {periodsCounts} ואז {amount} ליום",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לשבוע למשך {periodsCounts} ואז {amount} לשבוע",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לחודש למשך {periodsCounts} ואז {amount} לחודש",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לשנה למשך {periodsCounts} ואז {amount} לשנה",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} ימים לתקופה של {periodsCounts}, ואז {amount} למשך {periodValue} ימים",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} שבועות לתקופה של {periodsCounts}, ואז {amount} למשך {periodValue} שבועות",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} חודשים לתקופה של {periodsCounts}, ואז {amount} למשך {periodValue} חודשים",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} שנים לתקופה של {periodsCounts}, ואז {amount} למשך {periodValue} שנים",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לכל החיים למשך {periodsCounts} ואז {amount} לכל החיים",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "אחר כך תשלום ראשון בסך {amount} ואז {amountPromo} ליום",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "אחר כך תשלום ראשון בסך {amount} ואז {amountPromo} לשבוע",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "אחר כך תשלום ראשון בסך {amount} ואז {amountPromo} לחודש",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "אחר כך תשלום ראשון בסך {amount} ואז {amountPromo} לשנה",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "אחר כך {amount} תשלום ראשון, ואז {amountPromo} למשך {periodValue} ימים",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "אחר כך {amount} תשלום ראשון, ואז {amountPromo} למשך {periodValue} שבועות",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "אחר כך {amount} תשלום ראשון, ואז {amountPromo} למשך {periodValue} חודשים",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "אחר כך {amount} תשלום ראשון, ואז {amountPromo} למשך {periodValue} שנים",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "אחר כך תשלום ראשון בסך {amount} ואז {amountPromo} לכל החיים",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "למשך {periodsCounts} ואז {amount} ליום",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "למשך {periodsCounts} ואז {amount} לשבוע",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "למשך {periodsCounts} ואז {amount} לחודש",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "למשך {periodsCounts} ואז {amount} לשנה",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "לתקופה של {periodsCounts}, ואחר כך {amount} למשך {periodValue} ימים",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "לתקופה של {periodsCounts}, ואחר כך {amount} למשך {periodValue} שבועות",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "לתקופה של {periodsCounts}, ואחר כך {amount} למשך {periodValue} חודשים",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "לתקופה של {periodsCounts}, ואחר כך {amount} למשך {periodValue} שנים",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "למשך {periodsCounts} ואז {amount} לכל החיים",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, העונה מסתיימת ב-{periodEnds}",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}, העונה מתחילה ב-{periodStarts}",
  "editor.subscriptions-packs.button.then.price.season.ends": "אחר כך {amount}, העונה מסתיימת ב-{periodEnds}",
  "editor.subscriptions-packs.button.then.price.season.starts": "אחר כך {amount}, העונה מתחילה ב-{periodStarts}",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} ליום",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לשבוע",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לחודש",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לשנה",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} ימים",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} שבועות",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} חודשים",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} שנים",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לכל החיים",
  "editor.subscriptions-packs.button.then.price.promo.price": "אחר כך <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} ליום",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לשבוע",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לחודש",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לשנה",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} ימים",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} שבועות",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} חודשים",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} למשך {periodValue} שנים",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} לכל החיים",
  "editor.subscriptions-packs.button.then.price": "אחר כך {amount}",
  "editor.subscriptions-packs.button.season.ends": "העונה מסתיימת ב-{periodEnds}",
  "editor.subscriptions-packs.button.season.starts": "העונה מתחילה ב-{periodStarts}",
  "editor.subscriptions-packs.button.price.period.day": "{amount} ליום",
  "editor.subscriptions-packs.button.price.period.week": "{amount} לשבוע",
  "editor.subscriptions-packs.button.price.period.month": "{amount} לחודש",
  "editor.subscriptions-packs.button.price.period.year": "{amount} לשנה",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount} ל-{periodValue} ימים",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount} ל-{periodValue} שבועות",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount} ל-{periodValue} חודשים",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount} ל-{periodValue} שנים",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount} לכל החיים",
  "blocks.requirements.values.title": "</h2>דרישות המערכת<h2>",
  "blocks.requirements.components.platform_req.value.title": "פלטפורמה",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "גרפיקה",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "מערכת הפעלה",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "אחסון",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "מעבד",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "כרטיס שמע",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "זיכרון",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "לינוקס",
  "default-data.values.requirements.title.minimum": "מינימום",
  "default-data.values.requirements.title.recommended": "מומלץ",
  "default-data.values.requirements.item.directx.minimum": "גרסה 9.0c",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 גיגה-בתים",
  "default-data.values.requirements.item.processor.minimum": "מעבד Intel Core 2 Duo במהירות 2.5 גיגה-הרץ",
  "default-data.values.requirements.item.memory.minimum": "זיכרון RAM בנפח 1 גיגה-בתים",
  "default-data.values.requirements.item.sound_card.minimum": "תואם ל-DirectX9",
  "default-data.values.requirements.item.sound_card.recommended": "תואם ל-DirectX9",
  "default-data.values.requirements.item.directx.recommended": "גרסה 9.0c",
  "default-data.values.requirements.item.graphics.recommended": "כרטיס זיכרון מסוג NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 גיגה-בתים",
  "default-data.values.requirements.item.processor.recommended": "מעבד Intel Core i5 במהירות 3.0 גיגה-הרץ",
  "default-data.values.requirements.item.memory.recommended": "זיכרון RAM בנפח 2 גיגה-בתים",
  "default-data.customization.receipt.title": "שלום לך!",
  "default-data.customization.receipt.description": "אנחנו רוצים להודות לך על רכישתך! אנחנו מעריכים אותך על שבחרת לשחק במשחק שלנו.",
  "default-data.customization.receipt.footer": "© כל הזכויות שמורות.",
  "blocks.store.components.storeSection.title": "פריטים",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "נרשמת בהצלחה!",
  "notification.hero.components.subscribe.subscription.success": "שלחנו לך אימייל אימות. על מנת להפעיל את המנוי שלך, יש ללחוץ על הקישור בגוף האימייל.",
  "notification.hero.components.subscribe.error": "אירעה שגיאה. ניתן לנסות שוב מאוחר יותר או לפנות לצוות האינטגרציה שלנו בכתובת <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "notification.hero.components.subscribe.email.taken.error": "כבר יש לך מנוי.",
  /* notifications values END */
  "period.years_short": "שנ\'",
  "period.months_short": "חו\'",
  "period.weeks_short": "שב\'",
  "period.days_short": "ימ\'",
  "subscription.button.text": "הרשם ל-</br>{fullCurrency} על כל{periodCount}&thinsp;{periodName}",
  "client.subscription.button.current.text": "המינוי שלך",
  "client.subscription.button.configuration": "ניהול המינוי",
  "client.subscription.button.lifetime_text": "מינוי לכל החיים</br>תמורת {fullCurrency}",
  "client.thank_you_modal.title": "תודה על שנרשמת!",
  "client.thank_you_modal.description": "המינוי שלך מתחדש באופן אוטומטי. התשלום יחויב על בסיס קבוע לפי תנאי המינוי שלך. ניתן לבטלו בכל עת.",
  "client.thank_you_modal.input.description": "אם היית רוצה לראות את עצמך ברשימת המנויים שלנו, יש להכניס את שמך למטה.",
  "client.thank_you_modal.button.default.text": "תודה, סגור חלון",
  "client.thank_you_modal.input.placeholder": "שמך",
  "client.thank_you_modal.button.show_list": "הראה את כל המנויים",
  "client.subscribers_list_modal.title": "רשימת המנויים המלאה",
  "client.subscribers_list_modal.description": "תודה לכל המשתמשים שהצטרפו אלינו ונרשמו למשחק!",
  "client.subscribers_list_modal.show_more_button": "הראו לי עוד",
  "client.subscribers_list_modal.anonymous": "ומשתמשים אנונימיים",
  "client.footer.button.list_of_subscribers": "רשימת מנויים",
  "client.footer.xsollalogo.powered": "מופעל על ידי", // копирайт в футере
  "client.footer.xsollalogo.sb": "‏Xsolla בונה אתרי אינטרנט",
  "client.empty_subscribers_modal.title": "רשימת המנויים תופיע כאן",
  "client.empty_subscribers_modal.description": "הפוך למנוי הראשון של המשחק שלנו.",
  "client.empty_subscribers_modal.button": "בחר מנוי עכשיו",
  "client.news.blurred.description": "הפכו למנויים ל-{subscription_name} כדי לפתוח את התוכן הזה.",
  "client.news.blurred.button.join": "הצטרפו עכשיו",
  "client.cart_modal.title": "עגלת קניות",
  "client.cart_modal.item.pre-order": "(הזמנה מוקדמת)",
  "client.cart_modal.clear_all": "הסר הכול",
  "client.cart_modal.item_purchased_description": "כבר רכשת פריט זה",
  "client.cart_modal.subtotal_title": "סה\"כ",
  "client.cart_modal.subtotal_subtitle": "עשויים לחול מיסים ועלויות נוספות",
  "client.cart_modal.continue_button": "המשך בקניות",
  "client.cart_modal.checkout_button": "מעבר לתשלום",
  "client.cart_modal.footer-auth": "כדי לשמור פריטים בעגלה או לראות פריטים שכבר שמרת, עליך <button class=\'xl_auth\' data-xl-route=\'login\'>להתחבר</button> או <button class=\'xl_auth\' data-xl-route=\'signup\'>להירשם</button>",
  "client.cart_modal.empty_cart_description": "העגלה שלך ריקה. כדאי לעבור לחנות ולמצוא משהו מדהים לקנייה.",
  "client.cart_modal.empty_cart_open_store_button": "פתיחת חנות",
  "client.cart_modal.email_label": "",
  "client.cart_modal.email_description": "",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "בונוס",
  "client.cart_modal.promo_codes.apply": "מימוש",
  "client.cart_modal.promo_codes.remove": "הסרה",
  "client.cart_modal.promo_codes.placeholder": "קוד קידום",
  "client.cart_modal.promo_codes.error_incorrect": "",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "הגעת למגבלת המימוש של קודי קידום.",
  "client.cart_modal.promo_codes.error_expired": "",
  "client.cart_modal.promo_codes.error_could_not_apply": "",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "קוד המימוש אינו תקף לפריטים שבעגלה שלך.",
  "client.cart_modal.promo_codes.error_select_game_platform": "להמשך, נא לבחור פלטפורמת משחקים.",
  "client.cart_modal.promo_codes.select_drm": "בחירת פלטפורמה",
  "client.cart_modal.promo_codes.tooltip_drm": "להחלפת פלטפורמה, יש להסיר את קוד הקידום ולאחר מכן לממש אותו שוב",
  "client.bundle_modal.group_items": "פריטים בחבילה",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>ארד</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>דיגיטלי</h3><p>✓ אומנות</p><p>✓ טפטים</p><p><span style=\'color:rgb(127, 96, 0);\'>× פס-קול בתוך המשחק </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× תוכן מאחורי הקלעים</span></p><p><br></p><h3>3 lootboxes</h3><h3>בתוך המשחק</h3><p>✓ סקינים לדמות</p><p><span style=\'color:rgb(127, 96, 0);\'>× אפקטים ויזואלים </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× קלפי סיפור</span></p><p><br></p><h3>הטבות בתוך המשחק</h3><p>✓ x1 כניסה נוספת ל-Hall of Chaos</p><p>✓ x1 לידה מחדש לדמות</p><p><br></p><h3>Discord</h3><p>✓ גישה לערוץ הגיימרים</p><p><span style=\'color:rgb(127, 96, 0);\'>× גישה לערוץ המפתחים</span></p><p><br></p><h3>פורום</h3><p>✓ צבע בלעדי לכינוי</p><p><span style=\'color:rgb(127, 96, 0);\'>× אימוג\'י בלעדיים</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× תמונת פרופיל מונפשת </span></p><p>✓ x2 נקודות נוספות לדירוג ההודעה (ליום)</p><p><br></p><h3>סחורה</h3><p>✓ סיכות וסטיקרים (כל 6 חודשים)</p><p>✓ פוסטר באיכות גבוהה (כל 6 חודשים)</p><p><span style=\'color:rgb(127, 96, 0);\'>× פלאשים ממותגים (מדי שנה)</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× דמויות קטנות באיכות גבוהה (מדי שנה)</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>אירועים לא מקוונים</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× גישה בחינם לכנסי הגיימינג שלנו</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× ביקור במשרד החברה שלנו (מדי שנה)</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ נותן גישה ל-Discord</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>כסף</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>דיגיטלי</h3><p>✓ אומנות</p><p>✓ טפטים</p><p><span style=\"color:rgb(127, 96, 0);\">× פס-קול בתוך המשחק</span></p><p>✓ תוכן מאחורי הקלעים</p><p><br></p><h3>6 lootboxes</h3><h3>בתוך המשחק</h3><p>✓ סקינים לדמות</p><p>✓ אפקטים ויזואלים</p><p><span style=\"color:rgb(127, 96, 0);\">× קלפי סיפור</span></p><p><br></p><h3>הטבות בתוך המשחק</h3><p>✓ x2 כניסה נוספת ל-Hall of Chaos</p><p>✓ x2 לידה מחדש לדמות</p><h3><br></h3><h3>Discord</h3><p>✓ גישה לערוץ הגיימרים</p><p><span style=\"color:rgb(127, 96, 0);\">× גישה לערוץ המפתחים</span></p><p><br></p><h3>פורום</h3><p>✓ צבע בלעדי לכינוי</p><p><span style=\"color:rgb(127, 96, 0);\">× אימוג\'י בלעדיים</span></p><p><span style=\"color:rgb(127, 96, 0);\">× תמונת פרופיל מונפשת</span></p><p>✓ 5x נקודות נוספות לדירוג ההודעה (ליום)</p><p><br></p><h3>סחורה</h3><p>✓ סיכות וסטיקרים (כל 6 חודשים)</p><p>✓ פוסטר באיכות גבוהה (כל 6 חודשים)</p><p><span style=\"color:rgb(127, 96, 0);\">× פלאשים ממותגים (מדי שנה)</span></p><p><span style=\"color:rgb(127, 96, 0);\">× דמויות קטנות באיכות גבוהה (מדי שנה)</span></p><p><br></p><h3>אירועים לא מקוונים</h3><p>✓ גישה בחינם לכנסי הגיימינג שלנו</p><p><span style=\"color:rgb(127, 96, 0);\">× ביקור במשרד החברה שלנו (מדי שנה)</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ נותן גישה ל-Discord</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>זהב</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>דיגיטלי</h3><p>✓ אומנות</p><p>✓ טפטים</p><p>✓ פס-קול בתוך המשחק</p><p>✓ תוכן מאחורי הקלעים</p><p><br></p><h3>9 lootboxes</h3><h3>בתוך המשחק</h3><p>✓ סקינים לדמות</p><p>✓ אפקטים ויזואלים</p><p>✓ קלפי סיפור</p><p><br></p><h3>הטבות בתוך המשחק</h3><p>✓ x5 כניסה נוספת ל-Hall of Chaos</p><p>✓ x10 לידה מחדש לדמות</p><p><br></p><h3>Discord</h3><p>✓ גישה לערוץ הגיימרים</p><p>✓ גישה לערוץ המפתחים</p><p><br></p><h3>פורום</h3><p>✓ צבע בלעדי לכינוי</p><p>✓ אימוג\'י בלעדיים</p><p>✓ תמונת פרופיל מונפשת</p><p>✓ x12 נקודות נוספות לדירוג ההודעה (ליום)</p><p><br></p><h3>סחורה</h3><p>✓ סיכות וסטיקרים (כל 6 חודשים)</p><p>✓ פוסטר באיכות גבוהה (כל 6 חודשים)</p><p>✓ פלאשים ממותגים (מדי שנה)</p><p>✓ דמויות קטנות באיכות גבוהה (מדי שנה)</p><p><br></p><h3>אירועים לא מקוונים</h3><p>✓ גישה בחינם לכנסי הגיימינג שלנו</p><p>✓ ביקור במשרד החברה שלנו (מדי שנה)</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ נותן גישה ל-Discord</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>תוכן דיגיטלי</h3><p>אומנות</p><p>טפטים</p><p><span>פס-קול בתוך המשחק </span></p><p><span>תוכן מאחורי הקלעים</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>lootboxes</h3><h3>בתוך המשחק</h3><p>סקינים לדמות</p><p><span>אפקטים ויזואלים </span></p><p><span>קלפי סיפור</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>הטבות בתוך המשחק</h3><p>x1 כניסה נוספת ל-Hall of Chaos</p><p>x1 לידה מחדש לדמות</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>גישה לערוץ הגיימרים</p><p><span>× גישה לערוץ המפתחים</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>פורום</h3><p>צבע בלעדי לכינוי</p><p><span>× אימוג\'י בלעדיים</span></p><p><span>× תמונת פרופיל מונפשת </span></p><p>x2 נקודות נוספות לדירוג ההודעה (ליום)</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>סחורה</h3><p>סיכות וסטיקרים (כל 6 חודשים)</p><p>פוסטר באיכות גבוהה (כל 6 חודשים)</p><p><span>פלאשים ממותגים (מדי שנה)</span></p><p><span>דמויות קטנות באיכות גבוהה (מדי שנה)</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>אירועים לא מקוונים</h3><p>✓ גישה בחינם לכנסי הגיימינג שלנו</p><p>✓ ביקור במשרד החברה שלנו (מדי שנה)</p>",
  "client.buy_button.text": "קנה עכשיו",
  "client.buy_button.unavailable": "לא זמין",
  "client.buy_button.pre-order": "הזמנה מוקדמת",
  "client.buy_button.checkout": "מעבר לתשלום",
  "client.store.cart.add_button": "הוספה לעגלת הקניות",
  "client.store.cart.header_button": "עגלת קניות",
  "client.store.no_items": "החנות ריקה כרגע. כדאי לחזור לכאן מאוחר יותר!",
  "client.store.error.gt10_gk": "כדי להתגונן מפני מעשי מרמה, ניתן לרכוש רק 10 מפתחות בכל פעם.",
  "client.store.error.no_keys": "זמנית, המשחק אזל במלאי. נחדש את המלאי בקרוב. לקבלת עדכונים, ניתן ליצור איתנו קשר.",
  "client.store.item.purchased": "נרכש",
  "client.store.item.price_from": "החל מ-{price}",
  "client.store.item.show_more": "הצגת הפרטים",
  "client.store.successful_purchase": "הרכישה התקבלה. תודה!",
  "client.store.not_enough_vc": "אין מספיק כסף וירטואלי.</br>נא להוסיף אותו כדי לסיים את הרכישה.",
  "client.store.non_consumable_item": "כבר רכשת בעבר את הפריט הזה והוא לא זמין יותר.",
  "client.store.user_id_input_before_submit_hint": "הפריטים יזוכו בחשבון הזה.",
  "client.store.user_id_input_after_submit_hint": "הרכישה היא עבור:",
  "client.common_error": "אירעה שגיאה. ניתן לנסות שוב מאוחר יותר או לפנות לצוות האינטגרציה שלנו בכתובת <a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>.",
  "client.user_account.balance_title": "היתרה שלך",
  "client.header.logout": "התנתקות",
  "client.retailers.edition.title": "בחר עותק",
  "client.retailers.country.title": "בחר מדינה",
  "client.retailers.platform.title": "בחר פלטפורמה",
  "client.retailers.retailer.title": "בחר מוכר",
  "client.retailers.edition.digital": "דיגיטלי",
  "client.retailers.edition.physical": "פיזי",
  "client.retailers.platform.pc": "מחשב אישי",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "קנה מהמפתח",
  "client.retailers.expand_button.collapse": "התמוטטות",
  "client.retailers.buy_button.buy": "קנה עכשיו",
  "blocks.retailers.components.retailers.standart.value.ribbon": "הכי פופולרי",
  "blocks.retailers.components.retailers.standart.value.subtext": "זמין עכשיו",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>מהדורה סטנדרטית</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "קוד המשחק, תג בלעדי, פסקול, מפה וערכת שריונות נוספת",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>כותרת המשחק שלך</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "המשחק שלך לוקח את השחקים להרפתקה גדולה שמלאה במסיחי דעת, במפלצות, בציידי זהב ובסכנה רצינית.",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "זמין ל",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "הכנס את כתובת הדוא\"ל שלך",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "נרשמים לניוזלטר שלנו ומקבלים את כל החדשות והעדכונים הטריים",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "הגדרת כפתור",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "קנה עכשיו",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "הגדרת כפתור",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "הגדרת כפתור",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "הגדרת כפתור",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "לא זמין",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "הגדרת כפתור",
  "client.topup.input.errorMessage": "משהו השתבש. האם הזנת נכון את מזהה המשתמש שלך? אם כן, כדאי לנסות שוב מאוחר יותר.",
  "client.topup.input.successMessage": "התחברת באמצעות המזהה {userID}. עכשיו יש לך אפשרות לרכוש באתר.",
  "client.topup.input.username": "שם משתמש: {name}",
  "client.topup.input.id": "מזהה: {id}",
  "client.topup.input.level": "רמה: {level}",
  "client.topup.input.user_not_found": "לא מצאנו משתמש עם המזהה שציינת.<br/> נסה שנית.",
  "client.topup.input.serverError": "משהו השתבש.<br>אם קיבלת הודעה זו יותר מפעם אחת, {contact_us}",
  "client.topup.input.serverError.contact_us": "צור איתנו קשר.",
  "client.topup.authorized_title": "הרכישה היא עבור:",
  "blocks.store.values.custom_title": "<h2>חנות {gameTitle}</h2>"
};

module.exports = localizations;
