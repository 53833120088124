/**
 * @prettier
 * @flow
 * */

import type { IStoreDRM } from '#common/types/store';
import type { CartItemPrice } from '../../../../utils/types';
import type { GameKeyCartItemObject } from './GameKeyCartItemObject.type';

import { CartItem } from './CartItem';

export class GameKeyCartItem extends CartItem {
  _selectedDRM: $PropertyType<IStoreDRM, 'sku'>;

  _drmName: string;

  _unitItems: IStoreDRM[] | void;

  isTemporaryItem: boolean;

  constructor(props: GameKeyCartItemObject) {
    super(props);
    this._selectedDRM = props.selectedDRM;
    this._unitItems = props.unitItems;
    this._canChangeQuantity = false;
    this._drmName = props.drmName;
    this.isTemporaryItem = props.isTemporaryItem ?? false;
  }

  getAvailableUnits() {
    if (!this._unitItems) {
      return [];
    }

    if (this.isBonus) {
      return this._unitItems;
    }
    // $FlowFixMe
    return this._unitItems.filter((unit) => unit.has_keys || unit.is_pre_order);
  }

  hasKeys(): boolean {
    return this.selectedUnitItem.has_keys;
  }

  get selectedDRM() {
    return this._selectedDRM;
  }

  get isPreOrder() {
    const item = (this._unitItems || []).find(
      (item) => item.sku === this._selectedDRM
    );
    if (!item) {
      return false;
    }
    return item.is_pre_order;
  }

  get selectedUnitItem(): IStoreDRM {
    if (!this._unitItems) {
      throw Error('Unit items not initialized');
    }
    const selectedUnit = this._unitItems.find(
      ({ sku }) => this._selectedDRM === sku
    );
    if (!selectedUnit) {
      throw Error(
        `Selected DRM "${this._selectedDRM}" not found in item ${this._sku}`
      );
    }
    return selectedUnit;
  }

  set selectedDRM(drmSku: string) {
    this._selectedDRM = drmSku;
  }

  set price(newPrice: CartItemPrice) {
    this._price = newPrice;
  }

  get price() {
    return this._price;
  }

  get drmName() {
    return this._drmName;
  }

  toStoreObject() {
    return {
      sku: this._selectedDRM,
      quantity: this._quantity,
    };
  }

  toJSON() {
    return {
      ...super.toJSON(),
      selectedDRM: this._selectedDRM,
    };
  }
}
