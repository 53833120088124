"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoreUniversalItemType = void 0;

/** @prettier  */

/**
 * {@link https://gitlab.loc/common/store-api/-/blob/master/src/Pages/Validation/buy_page_query.json}
 */
const StoreUniversalItemType = {
  VIRTUAL_CURRENCY: 'virtual_currency',
  UNIT: 'unit',
  BUNDLE: 'bundle',
  VIRTUAL_GOOD: 'virtual_good',
  VIRTUAL_ITEM: 'virtual_item',
  DIGITAL_CONTENT: 'digital_content'
};
exports.StoreUniversalItemType = StoreUniversalItemType;