/** @prettier */
import type { BlockTypeEnum } from '#common/model/block';
import type { Listener } from '../../helpers/subscribers';
import type { AnalyticsEvent } from './analytics';

import { SANDBOX_SECURE_API, SECURE_API } from '../../../config';
import {
  IPayStationPostMessageDataType,
  PaystationActions,
} from '../../../types/paystation/postmessage-data';
import { Subscribers } from '../../helpers/subscribers';
import { analyticsManager } from './analytics';
import { AnalyticsActions } from './analyticsActions';
import { AnalyticsCategories } from './analyticsCategories';

const EventActionForGA = {
  [PaystationActions.OPEN]: AnalyticsActions.OPEN,
  [PaystationActions.CLOSE]: AnalyticsActions.CLOSE,
  [PaystationActions.CLICK_BTN_SHARE]: AnalyticsActions.CLICK_SHARE,
  [PaystationActions.CREATE_INVOICE]: AnalyticsActions.CLICK_PAY,
  [PaystationActions.CHOOSE_PAYMENT_WIDGET]: AnalyticsActions.CHOOSE_WIDGET,
  [PaystationActions.CHOOSE_METHOD]: AnalyticsActions.CHOOSE_METHOD,
  [PaystationActions.CHANGE_STATUS]: AnalyticsActions.CHANGE_STATUS,
};

export class PayStationPostMessages extends Subscribers<
  Listener<AnalyticsEvent>,
  AnalyticsEvent
> {
  _handlers: Array<
    (data: IPayStationPostMessageDataType, command?: string) => void
  > = [];

  block: BlockTypeEnum;

  purchaseInfo: any | null = null;

  set handler(fn) {
    this._handlers.push(fn);
  }

  constructor() {
    super();
    if (typeof window === 'object') {
      window.onmessage = (event) => {
        if ([SECURE_API, SANDBOX_SECURE_API].includes(event.origin)) {
          try {
            const {
              data,
              command,
            }: { data: IPayStationPostMessageDataType; command: string } =
              JSON.parse(event.data);
            if (data) {
              if (this._handlers.length) {
                this._handlers.forEach((fn) => {
                  fn(data, command);
                });
              }
              this.sendEventByPostMessage(data, this.block);
              this.setPurchaseInfo(data);
            }
          } catch {
            //
          }
        }
      };
    }
  }

  sendEventByPostMessage(
    data: IPayStationPostMessageDataType,
    block: BlockTypeEnum
  ) {
    const isPurchaseActon = PayStationPostMessages.isPurchaseAction(data);
    const event = isPurchaseActon
      ? AnalyticsActions.PURCHASE
      : EventActionForGA[data.action];
    if (event) {
      this.sendEvent({
        category: AnalyticsCategories.PAYSTATION,
        event,
        label: PayStationPostMessages.getLabelForByAction(data),
        page: isPurchaseActon
          ? 'landing_success_purchase'
          : `landing_paystation_${event}`,
        block,
        value: isPurchaseActon ? this.purchaseInfo : data,
      });
    }
  }

  static isPurchaseAction(data: IPayStationPostMessageDataType) {
    return (
      data.action === PaystationActions.CHANGE_STATUS && data.value === 'done'
    );
  }

  static getLabelForByAction(data: IPayStationPostMessageDataType) {
    if (
      [
        PaystationActions.CHOOSE_PAYMENT_WIDGET,
        PaystationActions.CHOOSE_METHOD,
        PaystationActions.CHANGE_STATUS,
        PaystationActions.CLICK_BTN_SHARE,
      ].includes(data.action)
    ) {
      return data.value;
    }
    if (data.action === PaystationActions.CREATE_INVOICE) {
      return data.payment_instance_name;
    }
    return window?.location?.pathname;
  }

  setPurchaseInfo(data: IPayStationPostMessageDataType) {
    if (
      data.action === PaystationActions.OPEN ||
      data.action === PaystationActions.CLOSE
    ) {
      this.purchaseInfo = null;
    }
    if (Object.prototype.hasOwnProperty.call(data, 'items')) {
      this.purchaseInfo = data;
    }
  }
}

const payStationPostMessage = new PayStationPostMessages();
payStationPostMessage.subscribe(analyticsManager);
export { payStationPostMessage };
