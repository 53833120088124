/** @prettier */
// @flow
import type { StoreUniversalItemTypes } from '#common/flow-types/store';
import type { FiveSymbolLocale } from '#common/utils/locale';

import { getPaystationWidgetUISettings } from '../../scripts/landingAPI/payStationWidgetSettings';
import { getAnalyticsTag } from '../store-helper';

import { fiveSymbolsMap } from '#common/utils/locale';

export type BuyButtonWidgetOptions = Object; // TODO describe correct type

export class BuyButtonWidgetOptionsBuilder {
  _projectId: string;

  _selector: string;

  _analyticsCounterId: number;

  _locale: FiveSymbolLocale;

  _isEnabledSandbox: boolean = false;

  _sku: ?string;

  _drm: ?string;

  _itemType: ?StoreUniversalItemTypes;

  _loginToken: ?string;

  _accessToken: ?string;

  _customParameters: ?Object;

  constructor({
    projectId,
    selector,
    analyticsCounterId,
    locale,
  }: {
    projectId: string,
    selector: string,
    analyticsCounterId: number,
    locale: FiveSymbolLocale,
  }) {
    this._projectId = projectId;
    this._selector = selector;
    this._analyticsCounterId = analyticsCounterId;
    this._locale = locale;
  }

  withAccessToken(accessToken: string) {
    this._accessToken = accessToken;
    return this;
  }

  withSku(sku: string) {
    this._sku = sku;
    return this;
  }

  withDrm(drm: string) {
    this._drm = drm;
    return this;
  }

  withItemType(itemType: StoreUniversalItemTypes) {
    this._itemType = itemType;
    return this;
  }

  withLoginToken(loginToken?: string) {
    this._loginToken = loginToken;
    return this;
  }

  withSandbox(isEnabledSandbox: boolean) {
    this._isEnabledSandbox = isEnabledSandbox;
    return this;
  }

  withCustomParameters(parameters: Object) {
    this._customParameters = parameters;
  }

  construct(): BuyButtonWidgetOptions {
    const options: BuyButtonWidgetOptions = {
      project_id: this._projectId,
      access_data: {
        settings: {
          xsolla_product_tag: getAnalyticsTag(this._analyticsCounterId),
        },
        custom_parameters: this._customParameters,
      },
      user: {
        locale: fiveSymbolsMap[this._locale],
      },
      widget_ui: {
        template: 'simple',
        target_element: this._selector,
      },
      payment_widget_ui: {
        lightbox: {
          height: '685px',
          spinner: 'round',
        },
      },
      payment_ui: getPaystationWidgetUISettings(),
      api_settings: {
        sandbox: this._isEnabledSandbox,
      },
    };

    if (this._accessToken) {
      options.access_token = this._accessToken;
    }

    if (this._sku) {
      options.sku = this._sku;
    }

    if (this._drm) {
      options.drm = this._drm;
    }

    if (this._itemType) {
      options.item_type = this._itemType;
    }

    if (this._loginToken) {
      options.user.auth = this._loginToken;
    }

    return options;
  }
}
