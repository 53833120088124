// @flow

export const NewrelicCustomEvent: {
  TOPUP_VALIDATION_SUCCESS: 'topup_validation_success',
  TOPUP_VALIDATION_FAIL: 'topup_validation_fail'
} = {
  TOPUP_VALIDATION_SUCCESS: 'topup_validation_success',
  TOPUP_VALIDATION_FAIL: 'topup_validation_fail'
};

export const nrSendEvent = (name: $Values<typeof NewrelicCustomEvent>, data?: Object) => {
  if (!window.newrelic) {
    return;
  }

  window.newrelic.addPageAction(name, {
    origin: window.location.origin,
    ...data
  });
};
