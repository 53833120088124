import { getLogins, getLoginWidgetConfiguration } from '../../../utils/api';
import { Login } from '../../../utils/types';
import { Action, Dispatch, GetState } from '../../types';
import { RootState } from '../../types/root-state';

import { Landing } from '#common/types/model/landing';

export type LoginState = {
  loginList: Login[];
  loginSettings: any;
};

export const initialState: LoginState = {
  loginList: [],
  loginSettings: {},
};

export const FETCH_LOGIN_LIST = 'FETCH_LOGIN_LIST';
export const ADD_LOGIN_TO_LOGIN_LIST = 'ADD_TO_LOGIN_LIST';
export const ADD_MAILCHIMP_TO_LOGIN = 'ADD_MAILCHIMP_TO_LOGIN';
export const REMOVE_MAILCHIMP_FROM_LOGIN = 'REMOVE_MAILCHIMP_FROM_LOGIN';
export const FETCH_LOGIN_WIDGET_SETTINGS = 'FETCH_LOGIN_WIDGET_SETTINGS';
export const SET_SERVER_LOGIN_WIDGET_SETTINGS =
  'SET_SERVER_LOGIN_WIDGET_SETTINGS';

export const fetchLoginList =
  (landing: Landing) =>
  async (dispatch: Dispatch): Promise<void> => {
    const { merchantId } = landing;
    const response = await getLogins({ merchantId });
    if (response?.status === 200) {
      dispatch({
        type: FETCH_LOGIN_LIST,
        payload: { loginList: [...(response?.data || [])] },
      });
    }
  };

export const addLoginToLoginList =
  (login: Login) => (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { loginData } = getState();
    loginData.loginList.push(login);

    dispatch({
      type: ADD_LOGIN_TO_LOGIN_LIST,
      payload: { loginList: [...loginData.loginList] },
    });
  };

export const addMailchimpToLogin =
  (loginId: string) => (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { loginData } = getState();
    const login = loginData.loginList.find((element) => element.id === loginId);
    if (!login) {
      return;
    }

    login.connectors = login.connectors || [];
    login.connectors.push('Mailchimp');

    dispatch({
      type: ADD_MAILCHIMP_TO_LOGIN,
      payload: { loginList: [...loginData.loginList] },
    });
  };

export const removeMailchimpFromLogin =
  (loginId: string) => (dispatch: Dispatch, getState: GetState<RootState>) => {
    const { loginData } = getState();
    const login = loginData.loginList.find((element) => element.id === loginId);
    if (!login || !login.connectors) {
      return;
    }

    login.connectors = login.connectors.filter(
      (element) => element !== 'Mailchimp'
    );

    dispatch({
      type: REMOVE_MAILCHIMP_FROM_LOGIN,
      payload: { loginList: [...loginData.loginList] },
    });
  };

export const fetchLoginWidgetSettings =
  (loginId: string) => async (dispatch: Dispatch) => {
    if (!loginId) {
      return;
    }
    const response = await getLoginWidgetConfiguration({ loginId });
    if (response?.status === 200) {
      dispatch({
        type: FETCH_LOGIN_WIDGET_SETTINGS,
        payload: { loginSettings: response.data || {} },
      });
    }
  };

export const setServerLoginWidgetSettings = (data: {
  // eslint-disable-next-line camelcase
  widget_generation: number;
}) => ({
  type: SET_SERVER_LOGIN_WIDGET_SETTINGS,
  payload: { loginSettings: data || {} },
});

export default function loginDataReducer(
  state: LoginState = initialState,
  action: { payload: any } & Action
) {
  switch (action.type) {
    case FETCH_LOGIN_LIST:
    case ADD_LOGIN_TO_LOGIN_LIST:
    case ADD_MAILCHIMP_TO_LOGIN:
    case REMOVE_MAILCHIMP_FROM_LOGIN:
    case FETCH_LOGIN_WIDGET_SETTINGS:
    case SET_SERVER_LOGIN_WIDGET_SETTINGS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
