import { changeHRef } from '#common/url';

export const getSitebuilderURL = (environment: string) => {
  if (environment === 'development') {
    return 'http://localhost:3000';
  }
  if (environment === 'stage') {
    return 'https://site-builder.nl-k8s-stage.srv.local';
  }
  return 'https://sitebuilder.xsolla.com';
};

export const clearUrlParams = () => {
  window.history.replaceState(null, '', window.location.pathname);
};

export { changeHRef };
