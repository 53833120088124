import { Store } from 'redux';

import createStoreManual from './create-store-manual';
import { middlewares } from './middlewares';
import rootReducer from './modules/site-builder';
import { State as LandingServicesState } from './modules/site-builder/landingServices';

export interface RootState {
  landing: any;
  pages: any;
  blocks: any;
  sideMenu: any;
  scripts: any;
  assets: any;
  features: any;
  intersection: any;
  launcherData: any;
  loginData: any;
  subscriptionData: any;
  subscriptionsPacks: any;
  storeData: any;
  projectInfoData: any;
  modals: any;
  retailersData: any;
  hiddenBlocks: any;
  loginWidgetInfo: any;
  landingServices: LandingServicesState;
}

export const createStore = (preloadedState) =>
  createStoreManual({
    rootReducer,
    customMiddlewares: middlewares,
    preloadedState,
    useDevtools: true,
  }) as Store<RootState>;
