/**
 * @prettier
 */

import { validateEmail } from '../../../utils/validations';
import { getTokenFromCookie } from '../../helpers/common';
import { Cart } from './cart/Cart';

export const toggleCartEmail = (
  modal: HTMLElement | Element | null,
  cart: Cart
) => {
  if (!modal) {
    return;
  }
  const userEmailContainer = modal.querySelector(
    '.ui-site-cart-modal__email-container'
  );
  const userEmailInput = modal.querySelector<HTMLInputElement>(
    '[data-cart-modal-email-input]'
  );
  const goToCheckout = modal.querySelector(
    '.ui-site-cart-modal__checkout-button'
  );
  const userToken = getTokenFromCookie();

  if (userEmailContainer && goToCheckout && userEmailInput) {
    if (cart.hasInventoryItems() || userToken) {
      userEmailContainer.setAttribute('hidden', '');
      goToCheckout.removeAttribute('disabled');
      return;
    }

    userEmailContainer.removeAttribute('hidden');
    if (validateEmail(userEmailInput.value)) {
      goToCheckout.removeAttribute('disabled');
    } else {
      goToCheckout.setAttribute('disabled', '');
    }
  }
};
