import type { Action, Dispatch } from '../../types';

export interface ModalsState {
  newsArticleCreateEditModalData: any;
  isNewsArticleCreateEditModalOpen: boolean;
  confirmDeleteNewsArticleModalData: any;
  isConfirmDeleteNewsArticleModalOpen: boolean;
  isShowUserIdModal: boolean;
}

const initialState: ModalsState = {
  newsArticleCreateEditModalData: {},
  isNewsArticleCreateEditModalOpen: false,
  confirmDeleteNewsArticleModalData: {},
  isConfirmDeleteNewsArticleModalOpen: false,
  isShowUserIdModal: false,
};

export const OPEN_NEWS_ARTICLE_CREATE_EDIT_MODAL =
  'OPEN_NEWS_ARTICLE_CREATE_EDIT_MODAL';
export const CLOSE_NEWS_ARTICLE_CREATE_EDIT_MODAL =
  'CLOSE_NEWS_ARTICLE_CREATE_EDIT_MODAL';
export const OPEN_CONFIRM_DELETE_NEWS_ARTICLE_MODAL =
  'OPEN_CONFIRM_DELETE_NEWS_ARTICLE_MODAL';
export const CLOSE_CONFIRM_DELETE_NEWS_ARTICLE_MODAL =
  'CLOSE_CONFIRM_DELETE_NEWS_ARTICLE_MODAL';
export const OPEN_USER_ID_MODAL = 'OPEN_USER_ID_MODAL';
export const CLOSE_USER_ID_MODAL = 'CLOSE_USER_ID_MODAL';

export const openNewsArticleCreateEditModal =
  (data: any) => (dispatch: Dispatch) =>
    dispatch({ type: OPEN_NEWS_ARTICLE_CREATE_EDIT_MODAL, data });
export const closeNewsArticleCreateEditModal = () => (dispatch: Dispatch) =>
  dispatch({ type: CLOSE_NEWS_ARTICLE_CREATE_EDIT_MODAL });
export const openConfirmDeleteNewsArticleModal =
  (data: any) => (dispatch: Dispatch) =>
    dispatch({ type: OPEN_CONFIRM_DELETE_NEWS_ARTICLE_MODAL, data });
export const closeConfirmDeleteNewsArticleModal = () => (dispatch: Dispatch) =>
  dispatch({ type: CLOSE_CONFIRM_DELETE_NEWS_ARTICLE_MODAL });
export const openUserIdModal = () => ({ type: OPEN_USER_ID_MODAL });
export const closeUserIdModal = () => ({ type: CLOSE_USER_ID_MODAL });

export default (state: ModalsState = initialState, action: Action) => {
  switch (action.type) {
    case OPEN_NEWS_ARTICLE_CREATE_EDIT_MODAL:
      return {
        ...state,
        isNewsArticleCreateEditModalOpen: true,
        newsArticleCreateEditModalData: action.data,
      };
    case CLOSE_NEWS_ARTICLE_CREATE_EDIT_MODAL:
      return {
        ...state,
        isNewsArticleCreateEditModalOpen: false,
        newsArticleCreateEditModalData: {},
      };
    case OPEN_CONFIRM_DELETE_NEWS_ARTICLE_MODAL:
      return {
        ...state,
        isConfirmDeleteNewsArticleModalOpen: true,
        confirmDeleteNewsArticleModalData: action.data,
      };
    case CLOSE_CONFIRM_DELETE_NEWS_ARTICLE_MODAL:
      return {
        ...state,
        isConfirmDeleteNewsArticleModalOpen: false,
        confirmDeleteNewsArticleModalData: {},
      };
    case OPEN_USER_ID_MODAL:
      return { ...state, isShowUserIdModal: true };
    case CLOSE_USER_ID_MODAL:
      return { ...state, isShowUserIdModal: false };
    default:
      return state;
  }
};
