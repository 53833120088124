var localizations = {
  /* client static translations  */
  "client.news.show-more.button": "显示更多",
  "client.header.login.button": "登录",
  "client.header.logout.button": "注销",
  "client.user-id-modal.input-placeholder": "用户ID",
  "client.user-id-modal.input-incorrect-id": "用户ID不正确。",
  "client.user-id-modal.input-incorrect-id-copy-and-paste": "请尝试复制粘贴您在游戏中的ID。",
  "client.user-id-modal.continue-button": "继续",
  "client.view-nickname-modal.show-more.button": "显示更多",
  "client.privacy-settings.open-button": "隐私设置",
  "client.usa-privacy-settings.open-button": "勿出售我的数据",
  /* default-data page texts */
  "document.pages.auth.title": "输入您的用户ID",
  "document.pages.auth.placeholder": "示例：123-728",
  "document.pages.auth.instruction.instructionCutTextTag": "不知道您的用户ID？",
  "document.pages.auth.instruction.steps.description": "描述用户怎样才能找到其个人ID。可添加多个步骤和图片。",
  "document.pages.seo.title": "新游戏已上线！",
  "document.pages.seo.description": "该游戏将带领玩家进入一场充斥着阴谋家、怪兽、赏金猎人和重重危机的盛大冒险。",
  "document.pages.seo.ogTitle": "新游戏已上线！",
  "document.pages.seo.ogDescription": "该游戏将带领玩家进入一场充斥着阴谋家、怪兽、赏金猎人和重重危机的盛大冒险。",
  "default-data.templates.google-play.contact-us": "联系我们：{email}",
  /* default-data topup block texts */
  "blocks.topup.values.title": "为游戏钱包充值",
  "blocks.topup.values.description": "输入用户ID",
  "blocks.topup.values.userIdPlaceholder": "示例：123-728",
  "blocks.topup.values.userInstruction.instructionCutTextTag": "不知道您的用户ID？",
  "blocks.topup.values.userInstruction.stepTitleTag": "步骤名称",
  "blocks.topup.values.userInstruction.stepDescriptionTag": "描述用户怎样才能找到其个人ID。可添加多个步骤和图片。",
  "default-data.templates.topup.app-support": "App支持",
  "default-data.templates.topup.age-raiting": "年龄分级",
  /* default-data values START */
  "blocks.hero.values.title": "<h1>游戏标题</h1>",
  "blocks.retailers.values.title": "<h2>选择您的版本</h2>",
  "blocks.hero.values.description": "该游戏将带领玩家进入一场充斥着阴谋家、怪兽、赏金猎人和重重危机的盛大冒险。",
  "blocks.hero.values.affiliate": "购买所得收入将与联盟推广者分成",
  "blocks.hero.components.platforms.label": "支持平台",
  "blocks.hero.components.subscribe.label": "输入您的电子邮箱",
  "blocks.hero.components.subscribe.values.text" : "订阅我们的新闻简讯以获取最新动态与更新",
  "blocks.hero.components.scroll.label": "滚动",
  "blocks.footer.values.name": "输入文本",
  "blocks.footer.values.affiliate": "加入联盟推广活动",
  "blocks.hero.components.buy.label": "立即购买",
  "blocks.hero.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.hero.components.customButton.link.label": "设置按钮",
  "blocks.header.components.customButton.link.label": "设置按钮",
  "blocks.header.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.packs.components.pack.customButton.link.label": "设置按钮",
  "blocks.packs.components.pack.standart.components.buy.label": "立即购买",
  "blocks.packs.components.pack.premium.components.buy.label": "立即购买",
  "blocks.packs.components.pack.ultimate.components.buy.label": "立即购买",
  "blocks.packs.components.pack.platinum.components.buy.label": "立即购买",
  "blocks.packs.components.pack.standart.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.packs.components.pack.premium.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.packs.components.pack.ultimate.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.packs.components.pack.platinum.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.packs.components.pack.standart.value.ribbon": "最热门",
  "blocks.packs.components.pack.premium.value.ribbon": "最热门",
  "blocks.packs.components.pack.ultimate.value.ribbon": "最热门",
  "blocks.packs.components.pack.platinum.value.ribbon": "最热门",
  "blocks.description.values.title": "<h2>游戏描述</h2>",
  "blocks.description.components.text.label": "该游戏将带领玩家进入一场充斥着阴谋家、怪兽、赏金猎人和重重危机的盛大冒险。颠覆角色命运的戏剧性事件将在您眼前展开。要在这个充满敌意的新世界生存，必须努力战斗。尽情在异世界求生的过程中探索、发现、征服吧！",
  "blocks.faq.values.title": "<h2>常见问答</h2>",
  "blocks.faq.components.question.question": "如何开始游戏？",
  "blocks.faq.components.question.answer": "要开始游戏，需要先购买游戏并提供您的电子邮箱。您将收到游戏激活密钥，提交该密钥后即可开始游戏。",
  "blocks.news.values.title": "<h2>新闻</h2>",
  "blocks.news.values.description": "阅读我们的最新文章了解近期动态。",
  "blocks.store.values.title": "<h2>商店</h2>",
  "blocks.store.buy_button.text": "立即购买，仅需{amount}",
  "blocks.embed.values.title": "<h2>社交媒体小组件</h2>",
  "blocks.embed.components.twitter.title": "<h3>TWITTER的饲料</h3>",
  "blocks.embed.components.facebook.title": "<h3>FACEBOOKr的饲料</h3>",
  "blocks.embed.components.twitch.title": "<h3>TWITCH</h3>",
  "blocks.embed.components.discord.title": "<h3>DISCORD</h3>",
  "blocks.packs.values.title": "选择您的版本",
  "blocks.packs.values.description": "每个版本包含购买后即可获得的独家礼遇和特权。",
  "blocks.packs.components.pack.standart.value.subtext": "现已推出",
  "blocks.packs.components.pack.premium.value.subtext": "现已推出",
  "blocks.packs.components.pack.ultimate.value.subtext": "现已推出",
  "blocks.packs.components.pack.platinum.value.subtext": "现已推出",
  "blocks.packs.components.pack.standart.value.title": "<h3>标准版</h3>",
  "blocks.packs.components.pack.premium.value.title": "<h3>高级版</h3>",
  "blocks.packs.components.pack.platinum.value.title": "<h3>白金版</h3>",
  "blocks.packs.components.pack.ultimate.value.title": "<h3>终极版</h3>",
  "blocks.packs.components.pack.standart.value.description": "游戏密钥、独家徽章、原声音乐、地图和额外护甲包",
  "blocks.packs.components.pack.premium.value.description": "游戏密钥、独家徽章、原声音乐、地图、额外护甲包和额外武器包",
  "blocks.packs.components.pack.platinum.value.description": "游戏密钥、独家徽章、原声音乐、地图、额外护甲包、额外武器包和一个随机皮肤包",
  "blocks.packs.components.pack.ultimate.value.description": "游戏密钥、独家徽章、原声音乐、地图、额外护甲包、两个额外武器包和三个随机皮肤包",
  "blocks.subscriptions.values.card.title": "升级付费版，获得丰富奖励",
  "blocks.subscriptions.values.title": "激活订阅",
  "blocks.subscriptions.values.heading": "订阅",
  "blocks.subscriptions.values.bullets.one.title": "数字内容",
  "blocks.subscriptions.values.bullets.one.description": "图片、壁纸、幕后内容、游戏原声",
  "blocks.subscriptions.values.bullets.two.title": "游戏内特权",
  "blocks.subscriptions.values.bullets.two.description": "角色皮肤、视觉特效、故事线卡片",
  "blocks.subscriptions.values.bullets.three.title": "3个游戏内宝箱",
  "blocks.subscriptions.values.bullets.three.description": "5张前往混沌之殿的额外通行证、10次角色重生机会",
  "editor.subscriptions-packs.manage.subscriptions.next.renewal": "下一次续费",
  "editor.subscriptions-packs.manage.subscriptions.renew.now": "立即续费",
  "editor.subscriptions-packs.manage.subscriptions.manage.plan": "管理订阅计划",
  "editor.subscriptions-packs.manage.subscriptions.select.plan": "选择您的订阅计划",
  "editor.subscriptions-packs.manage.subscriptions.configure.subscription": "配置订阅",
  "editor.subscriptions-packs.button.trial.days.for.free": "{trial}天免费",
  "editor.subscriptions-packs.button.then.price.per.day": "之后{amount}/天",
  "editor.subscriptions-packs.button.then.price.per.week": "之后{amount}/周",
  "editor.subscriptions-packs.button.then.price.per.month": "之后{amount}/月",
  "editor.subscriptions-packs.button.then.price.per.year": "之后{amount}/年",
  "editor.subscriptions-packs.button.then.price.per.day.multiple": "之后{amount}/{periodValue}天",
  "editor.subscriptions-packs.button.then.price.per.week.multiple": "之后{amount}/{periodValue}周",
  "editor.subscriptions-packs.button.then.price.per.month.multiple": "之后{amount}/{periodValue}个月",
  "editor.subscriptions-packs.button.then.price.per.year.multiple": "之后{amount}/{periodValue}年",
  "editor.subscriptions-packs.button.then.price.per.lifetime": "之后{amount}/终身",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day": "作为首次支付，之后{amount}/天",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week": "作为首次支付，之后{amount}/周",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month": "作为首次支付，之后{amount}/月",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year": "作为首次支付，之后{amount}/年",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.lifetime": "作为首次支付，之后{amount}/终身",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.day.multiple": "作为首次支付，之后{amount}/{periodValue}天",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.week.multiple": "作为首次支付，之后{amount}/{periodValue}周",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.month.multiple": "作为首次支付，之后{amount}/{periodValue}个月",
  "editor.subscriptions-packs.button.as.first.payment.then.price.per.year.multiple": "作为首次支付，之后{amount}/{periodValue}年",
  "editor.subscriptions-packs.button.price.per.day": "{amount}/天",
  "editor.subscriptions-packs.button.price.per.week": "{amount}/周",
  "editor.subscriptions-packs.button.price.per.month": "{amount}/月",
  "editor.subscriptions-packs.button.price.per.year": "{amount}/年",
  "editor.subscriptions-packs.button.price.per.day.multiple": "{amount}/{periodValue}天",
  "editor.subscriptions-packs.button.price.per.week.multiple": "{amount}/{periodValue}周",
  "editor.subscriptions-packs.button.price.per.month.multiple": "{amount}/{periodValue}个月",
  "editor.subscriptions-packs.button.price.per.year.multiple": "{amount}/{periodValue}年",
  "editor.subscriptions-packs.button.price.per.lifetime": "{amount}/终身",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day": "之后{periodsCounts}天<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/天，再之后{amount}/天",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week": "之后{periodsCounts}周<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/周，再之后{amount}/周",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month": "之后{periodsCounts}个月<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/月，再之后{amount}/月",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year": "之后{periodsCounts}年<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/年，再之后{amount}/年",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.day.multiple": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}天（{periodsCounts}个订阅周期），再之后{amount}/{periodValue}天",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.week.multiple": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}周（{periodsCounts}个订阅周期），再之后{amount}/{periodValue}周",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.month.multiple": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}个月（{periodsCounts}个订阅周期），再之后{amount}/{periodValue}个月",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.year.multiple": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}年（{periodsCounts}个订阅周期），再之后{amount}/{periodValue}年",
  "editor.subscriptions-packs.button.then.price.promo.price.per.period.then.per.periods.lifetime": "Then <span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo} per lifetime for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day": "之后{amount}作为首次支付，再之后{amountPromo}/天",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week": "之后{amount}作为首次支付，再之后{amountPromo}/周",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month": "之后{amount}作为首次支付，再之后{amountPromo}/月",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year": "之后{amount}作为首次支付，再之后{amountPromo}/年",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.day.multiple": "之后{amount}作为首次支付，再之后{amountPromo}/{periodValue}天",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.week.multiple": "之后{amount}作为首次支付，再之后{amountPromo}/{periodValue}周",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.month.multiple": "之后{amount}作为首次支付，再之后{amountPromo}/{periodValue}个月",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.year.multiple": "之后{amount}作为首次支付，再之后{amountPromo}/{periodValue}年",
  "editor.subscriptions-packs.button.then.price.first.payment.then.promo.price.per.period.lifetime": "之后{amount}作为首次支付，再之后{amountPromo}/终身",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day": "支付{periodsCounts}天，之后{amount}/天",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week": "支付{periodsCounts}周，之后{amount}/周",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month": "支付{periodsCounts}月，之后{amount}/月",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year": "支付{periodsCounts}年，之后{amount}/年",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.day.multiple": "支付{periodsCounts}个订阅周期，之后{amount}/{periodValue}天",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.week.multiple": "支付{periodsCounts}个订阅周期，之后{amount}/{periodValue}周",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.month.multiple": "支付{periodsCounts}个订阅周期，之后{amount}/{periodValue}个月",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.year.multiple": "支付{periodsCounts}个订阅周期，之后{amount}/{periodValue}年",
  "editor.subscriptions-packs.button.for.period.then.price.per.periods.lifetime": "for {periodsCounts} period(s), then {amount} per lifetime",
  "editor.subscriptions-packs.button.then.price.promo.price.season.ends": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}，本季将于{periodEnds}结束",
  "editor.subscriptions-packs.button.then.price.promo.price.season.starts": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}，本季将于{periodStarts}开始",
  "editor.subscriptions-packs.button.then.price.season.ends": "之后{amount}，本季将于{periodEnds}结束",
  "editor.subscriptions-packs.button.then.price.season.starts": "之后{amount}，本季将于{periodStarts}开始",
  "editor.subscriptions-packs.button.price": "{amount}",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/天",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/周",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/月",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/年",
  "editor.subscriptions-packs.button.then.price.promo.price.period.day.multiple": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}天",
  "editor.subscriptions-packs.button.then.price.promo.price.period.week.multiple": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}周",
  "editor.subscriptions-packs.button.then.price.promo.price.period.month.multiple": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}个月",
  "editor.subscriptions-packs.button.then.price.promo.price.period.year.multiple": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}年",
  "editor.subscriptions-packs.button.then.price.promo.price.period.lifetime": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/终身",
  "editor.subscriptions-packs.button.then.price.promo.price": "之后<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}",
  "editor.subscriptions-packs.button.price.promo.price.period.day": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/天",
  "editor.subscriptions-packs.button.price.promo.price.period.week": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/周",
  "editor.subscriptions-packs.button.price.promo.price.period.month": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/月",
  "editor.subscriptions-packs.button.price.promo.price.period.year": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/年",
  "editor.subscriptions-packs.button.price.promo.price.period.day.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}天",
  "editor.subscriptions-packs.button.price.promo.price.period.week.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}周",
  "editor.subscriptions-packs.button.price.promo.price.period.month.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}个月",
  "editor.subscriptions-packs.button.price.promo.price.period.year.multiple": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/{periodValue}年",
  "editor.subscriptions-packs.button.price.promo.price.period.lifetime": "<span class=\'subscriptions-container__price\'>{amount}</span> {amountPromo}/终身",
  "editor.subscriptions-packs.button.then.price": "之后{amount}",
  "editor.subscriptions-packs.button.season.ends": "本季将于{periodEnds}结束",
  "editor.subscriptions-packs.button.season.starts": "本季将于{periodStarts}开始",
  "editor.subscriptions-packs.button.price.period.day": "{amount}/天",
  "editor.subscriptions-packs.button.price.period.week": "{amount}/周",
  "editor.subscriptions-packs.button.price.period.month": "{amount}/月",
  "editor.subscriptions-packs.button.price.period.year": "{amount}/年",
  "editor.subscriptions-packs.button.price.period.value.day": "{amount}/{periodValue}天",
  "editor.subscriptions-packs.button.price.period.value.week": "{amount}/{periodValue}周",
  "editor.subscriptions-packs.button.price.period.value.month": "{amount}/{periodValue}个月",
  "editor.subscriptions-packs.button.price.period.value.year": "{amount}/{periodValue}年",
  "editor.subscriptions-packs.button.price.period.lifetime": "{amount}/终身",
  "blocks.requirements.values.title": "<h2>系统要求</h2>",
  "blocks.requirements.components.platform_req.value.title": "平台",
  "blocks.requirements.components.platform_req.value.requirementList.directx.name": "DirectX",
  "blocks.requirements.components.platform_req.value.requirementList.graphics.name": "显卡",
  "blocks.requirements.components.platform_req.value.requirementList.os.name": "操作系统",
  "blocks.requirements.components.platform_req.value.requirementList.storage.name": "存储",
  "blocks.requirements.components.platform_req.value.requirementList.processor.name": "处理器",
  "blocks.requirements.components.platform_req.value.requirementList.sound_card.name": "声卡",
  "blocks.requirements.components.platform_req.value.requirementList.memory.name": "内存",
  "default-data.values.requirements.title.windows": "Windows",
  "default-data.values.requirements.title.apple": "MacOS",
  "default-data.values.requirements.title.linux": "Linux",
  "default-data.values.requirements.title.minimum": "最低",
  "default-data.values.requirements.title.recommended": "推荐",
  "default-data.values.requirements.item.directx.minimum": "9.0c版",
  "default-data.values.requirements.item.graphics.minimum": "NVIDIA 9xxx / ATI 2xxx",
  "default-data.values.requirements.item.os.minimum": "-",
  "default-data.values.requirements.item.os.recommended": "-",
  "default-data.values.requirements.item.storage.minimum": "5 GB",
  "default-data.values.requirements.item.processor.minimum": "Intel Core 2 Duo 2.5 GHz",
  "default-data.values.requirements.item.memory.minimum": "1 GB RAM",
  "default-data.values.requirements.item.sound_card.minimum": "兼容DirectX9",
  "default-data.values.requirements.item.sound_card.recommended": "兼容DirectX9",
  "default-data.values.requirements.item.directx.recommended": "9.0c版",
  "default-data.values.requirements.item.graphics.recommended": "NVIDIA GeForce GTX 760 / AMD Radeon R9 280X",
  "default-data.values.requirements.item.storage.recommended": "5 GB",
  "default-data.values.requirements.item.processor.recommended": "Intel Core i5 3.0 GHz",
  "default-data.values.requirements.item.memory.recommended": "2 GB RAM",
  "default-data.customization.receipt.title": "嗨，亲爱的朋友！",
  "default-data.customization.receipt.description": "感谢您的购买！很高兴您选择我们的游戏。",
  "default-data.customization.receipt.footer": "© 版权所有",
  "blocks.store.components.storeSection.title": "商品",
  /* default-data values END */
  /* notifications values START */
  "notification.hero.components.subscribe.email.send.success": "您已成功订阅！",
  "notification.hero.components.subscribe.subscription.success": "我们已向您发送了一封确认电子邮件。请点击邮件中的链接激活订阅。",
  "notification.hero.components.subscribe.error": "发生了问题。请稍后重试或发送邮件至<a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>与我们的集成团队联系。",
  "notification.hero.components.subscribe.email.taken.error": "您已订阅。",
  /* notifications values END */
  "period.years_short": "年",
  "period.months_short": "月",
  "period.weeks_short": "周",
  "period.days_short": "天",
  "subscription.button.text": "订阅价：</br>每{periodCount}&thinsp;{periodName}只需{fullCurrency}",
  "client.subscription.button.current.text": "您的订阅",
  "client.subscription.button.configuration": "管理订阅",
  "client.subscription.button.lifetime_text": "终身订阅</br>仅需{fullCurrency}",
  "client.thank_you_modal.title": "感谢订阅！",
  "client.thank_you_modal.description": "您的订阅将自动续期。扣款将根据订阅条款定期自动扣取。您可以随时取消。",
  "client.thank_you_modal.input.description": "如果希望您的名字出现在订阅者名单中，请在下方输入您的名字。",
  "client.thank_you_modal.button.default.text": "谢谢，不用了",
  "client.thank_you_modal.input.placeholder": "您的名字",
  "client.thank_you_modal.button.show_list": "显示所有订阅者",
  "client.subscribers_list_modal.title": "订阅者完整名单",
  "client.subscribers_list_modal.description": "感谢加入我们并订阅了游戏的所有用户！",
  "client.subscribers_list_modal.show_more_button": "显示更多",
  "client.subscribers_list_modal.anonymous": "以及更多匿名用户",
  "client.footer.button.list_of_subscribers": "订阅者名单",
  "client.footer.xsollalogo.powered": "技术方：", // копирайт в футере
  "client.footer.xsollalogo.sb": "艾克索拉建站器",
  "client.empty_subscribers_modal.title": "将在此处显示订阅者",
  "client.empty_subscribers_modal.description": "成为我们游戏的第一位订阅者。",
  "client.empty_subscribers_modal.button": "选择立即订阅",
  "client.news.blurred.description": "成为{subscription_name}计划订阅者解锁此内容。",
  "client.news.blurred.button.join": "立即加入",
  "client.cart_modal.title": "购物车",
  "client.cart_modal.item.pre-order": "（预订）",
  "client.cart_modal.clear_all": "清除全部",
  "client.cart_modal.item_purchased_description": "您已购买了此商品",
  "client.cart_modal.subtotal_title": "总计",
  "client.cart_modal.subtotal_subtitle": "可能存在额外税费",
  "client.cart_modal.continue_button": "继续购物",
  "client.cart_modal.checkout_button": "前往结算",
  "client.cart_modal.footer-auth": "<button class=\'xl_auth\' data-xl-route=\'login\'>登录</button>或<button class=\'xl_auth\' data-xl-route=\'signup\'>注册</button>以保存购物车中的商品供后续之后或查看之前保存的商品",
  "client.cart_modal.empty_cart_description": "您的购物车为空。不妨去商店逛逛，发现好东西吧。",
  "client.cart_modal.empty_cart_open_store_button": "打开商店",
  "client.cart_modal.email_label": "将游戏激活密钥发送至：",
  "client.cart_modal.email_description": "您将收到一个密钥，激活后才能开始游戏。",
  "client.cart_modal.email_placeholder": "example@mail.com",
  "client.cart_modal.item.bonus": "奖励",
  "client.cart_modal.promo_codes.apply": "应用",
  "client.cart_modal.promo_codes.remove": "删除",
  "client.cart_modal.promo_codes.placeholder": "促销码",
  "client.cart_modal.promo_codes.error_incorrect": "促销码不正确。",
  "client.cart_modal.promo_codes.error_limit_has_been_reached": "已达到促销码兑换次数限制。",
  "client.cart_modal.promo_codes.error_expired": "该促销码已过期。",
  "client.cart_modal.promo_codes.error_could_not_apply": "无法应用促销码，请稍后重试。",
  "client.cart_modal.promo_codes.error_not_valid_for_items": "该促销码不适用于您购物车中的商品。",
  "client.cart_modal.promo_codes.error_select_game_platform": "选择一个游戏平台以继续。",
  "client.cart_modal.promo_codes.select_drm": "选择平台",
  "client.cart_modal.promo_codes.tooltip_drm": "要更改平台，请移除促销码，然后重新应用",
  "client.bundle_modal.group_items": "捆绑包中的商品",
  "blocks.packs.components.pack.standart-cfd.value.title": "<h3>铜牌</h3>",
  "blocks.packs.components.pack.standart-cfd.value.description": "<h3>数字内容</h3><p>✓ 图片</p><p>✓ 壁纸</p><p><span style=\'color:rgb(127, 96, 0);\'>× 游戏原声 </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 幕后内容</span></p><p><br></p><h3>3 游戏内</h3><h3>宝箱</h3><p>✓ 角色皮肤</p><p><span style=\'color:rgb(127, 96, 0);\'>× 视觉特效 </span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 故事线卡片</span></p><p><br></p><h3>游戏内特殊能力</h3><p>✓ x1 张喧嚣之殿额外通行证</p><p>✓ x1 次角色复活</p><p><br></p><h3>Discord</h3><p>✓ 访问玩家频道</p><p><span style=\'color:rgb(127, 96, 0);\'>× 访问开发者频道</span></p><p><br></p><h3>论坛</h3><p>✓ 专享昵称颜色</p><p><span style=\'color:rgb(127, 96, 0);\'>× 专享表情</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 动态头像 </span></p><p>✓ x2 个额外点数提升帖子排名（每天）</p><p><br></p><h3>商品</h3><p>✓ 徽章和贴纸（每6个月）</p><p>✓ 高品质海报（每6个月）</p><p><span style=\'color:rgb(127, 96, 0);\'>× 品牌公仔（每年）</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 高品质手办（每年）</span></p><p><br></p><h3><span style=\'color:rgb(127, 96, 0);\'>线下活动</span></h3><p><span style=\'color:rgb(127, 96, 0);\'>× 免费参加游戏大会</span></p><p><span style=\'color:rgb(127, 96, 0);\'>× 参观公司（每年）</span></p>",
  "blocks.packs.components.pack.standart-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ 访问Discord</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.title": "<h3>银牌</h3>",
  "blocks.packs.components.pack.ultimate-cfd.value.description": "<h3>数字内容</h3><p>✓ 图片</p><p>✓ 壁纸</p><p><span style=\"color:rgb(127, 96, 0);\">× 游戏原声</span></p><p>✓ 幕后内容</p><p><br></p><h3>6 游戏内</h3><h3>宝箱</h3><p>✓ 角色皮肤</p><p>✓ 视觉特效</p><p><span style=\"color:rgb(127, 96, 0);\">× 故事线卡片</span></p><p><br></p><h3>游戏内特殊能力</h3><p>✓ x2 张喧嚣之殿额外通行证</p><p>✓ x2 次角色复活</p><h3><br></h3><h3>Discord</h3><p>✓ 访问玩家频道</p><p><span style=\"color:rgb(127, 96, 0);\">× 访问开发者频道</span></p><p><br></p><h3>论坛</h3><p>✓ 专享昵称颜色</p><p><span style=\"color:rgb(127, 96, 0);\">× 专享表情</span></p><p><span style=\"color:rgb(127, 96, 0);\">× 动态头像</span></p><p>✓ 5x 个额外点数提升帖子排名（每天）</p><p><br></p><h3>商品</h3><p>✓ 徽章和贴纸（每6个月）</p><p>✓ 高品质海报（每6个月）</p><p><span style=\"color:rgb(127, 96, 0);\">× 品牌公仔（每年）</span></p><p><span style=\"color:rgb(127, 96, 0);\">× 高品质手办（每年）</span></p><p><br></p><h3>线下活动</h3><p>✓ 免费参加游戏大会</p><p><span style=\"color:rgb(127, 96, 0);\">× 参观公司（每年）</span></p>",
  "blocks.packs.components.pack.ultimate-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ 访问Discord</span></p>",
  "blocks.packs.components.pack.premium-cfd.value.title": "<h3>金牌</h3>",
  "blocks.packs.components.pack.premium-cfd.value.description": "<h3>数字内容</h3><p>✓ 图片</p><p>✓ 壁纸</p><p>✓ 游戏原声</p><p>✓ 幕后内容</p><p><br></p><h3>9 游戏内</h3><h3>宝箱</h3><p>✓ 角色皮肤</p><p>✓ 视觉特效</p><p>✓ 故事线卡片</p><p><br></p><h3>游戏内特殊能力</h3><p>✓ x5 张喧嚣之殿额外通行证</p><p>✓ x10 次角色复活</p><p><br></p><h3>Discord</h3><p>✓ 访问玩家频道</p><p>✓ 访问开发者频道</p><p><br></p><h3>论坛</h3><p>✓ 专享昵称颜色</p><p>✓ 专享表情</p><p>✓ 动态头像</p><p>✓ x12 个额外点数提升帖子排名（每天）</p><p><br></p><h3>商品</h3><p>✓ 徽章和贴纸（每6个月）</p><p>✓ 高品质海报（每6个月）</p><p>✓ 品牌公仔（每年）</p><p>✓ 高品质手办（每年）</p><p><br></p><h3>线下活动</h3><p>✓ 免费参加游戏大会</p><p>✓ 参观公司（每年）</p>",
  "blocks.packs.components.pack.premium-cfd.value.subtext": "<p><span style=\"color:rgb(243, 202, 82);\">✓ 访问Discord</span></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-1": "<h3>数字内容</h3><p>图片</p><p>壁纸</p><p><span>游戏原声</span></p><p><span>幕后内容</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-2": "<h3>游戏内</h3><h3>宝箱</h3><p>角色皮肤</p><p><span>视觉特效</span></p><p><span>故事线卡片</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-3": "<h3>游戏内特殊能力</h3><p>x1 张喧嚣之殿额外通行证</p><p>x1 次角色复活</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-4": "<h3>Discord</h3><p>访问玩家频道</p><p><span>× 访问开发者频道</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-5": "<h3>论坛</h3><p>专享昵称颜色</p><p><span>× 专享表情</span></p><p><span>× 动态头像 </span></p><p>x2 个额外点数提升帖子排名（每天）</p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-6": "<h3>商品</h3><p>徽章和贴纸（每6个月）</p><p>高品质海报（每6个月）</p><p><span>品牌公仔（每年）</span></p><p><span>高品质手办（每年）</span></p><p><br></p>",
  "default-data.cfd.blocks.packs.components.pack.value.description-7": "<h3>线下活动</h3><p>✓ 免费参加游戏大会</p><p>✓ 参观公司（每年）</p>",
  "client.buy_button.text": "立即购买",
  "client.buy_button.unavailable": "不可用",
  "client.buy_button.pre-order": "预订",
  "client.buy_button.checkout": "前往结算",
  "client.store.cart.add_button": "添加至购物车",
  "client.store.cart.header_button": "购物车",
  "client.store.no_items": "商店当前为空。请稍后再来！",
  "client.store.error.gt10_gk": "为防止欺诈行为，一次只能购买10个密钥。",
  "client.store.error.no_keys": "游戏暂时无货，将很快补货。欢迎联系我们了解最新信息。",
  "client.store.item.purchased": "已购买",
  "client.store.item.price_from": "{price}起",
  "client.store.item.show_more": "显示详情",
  "client.store.successful_purchase": "成功。感谢您的购买！",
  "client.store.not_enough_vc": "虚拟货币不足。</br>请充值以完成购买。",
  "client.store.non_consumable_item": "您之前已购买过此商品，不能再购买。",
  "client.store.user_id_input_before_submit_hint": "商品将充入该帐户。",
  "client.store.user_id_input_after_submit_hint": "为此用户购买：",
  "client.common_error": "发生了问题。请稍后重试或发送邮件至<a href=\"mailto:integration@xsolla.com\">integration@xsolla.com</a>与我们的集成团队联系。",
  "client.user_account.balance_title": "您的余额",
  "client.header.logout": "注销",
  "client.retailers.edition.title": "选择您的版本",
  "client.retailers.country.title": "选择您的国家/地区",
  "client.retailers.platform.title": "选择平台",
  "client.retailers.retailer.title": "选择卖家",
  "client.retailers.edition.digital": "数字",
  "client.retailers.edition.physical": "实体",
  "client.retailers.platform.pc": "PC",
  "client.retailers.platform.ps": "PS4",
  "client.retailers.platform.xbox_one": "XBOX ONE",
  "client.retailers.platform.switch": "SWITCH",
  "client.retailers.retailer.default_retailer": "向开发者购买",
  "client.retailers.expand_button.collapse": "折叠",
  "client.retailers.buy_button.buy": "立即购买",
  "blocks.retailers.components.retailers.standart.value.ribbon": "最热门",
  "blocks.retailers.components.retailers.standart.value.subtext": "现已推出",
  "blocks.retailers.components.retailers.standart.value.title": "<h3>标准版</h3>",
  "blocks.retailers.components.retailers.standart.value.description": "游戏密钥、独家徽章、原声音乐、地图和额外护甲包",
  "blocks.promoSlider.components.promoSlide.value.title": "<h1>游戏标题</h1>",
  "blocks.promoSlider.components.promoSlide.value.description": "该游戏将带领玩家进入一场充斥着阴谋家、怪兽、赏金猎人和重重危机的盛大冒险。",
  "blocks.promoSlider.components.promoSlide.components.platforms.label": "支持平台",
  "blocks.promoSlider.components.promoSlide.components.subscribe.label": "输入您的电子邮箱",
  "blocks.promoSlider.components.promoSlide.components.subscribe.values.text" : "订阅我们的新闻简讯以获取最新动态与更新",
  "blocks.promoSlider.components.promoSlide.components.lightBox.label": "设置按钮",
  "blocks.promoSlider.components.promoSlide.components.buy.label": "立即购买",
  "blocks.promoSlider.components.promoSlide.components.customButton.link.label": "设置按钮",
  "blocks.promoSlider.components.promoSlide.components.customButton.label": "设置按钮",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.label": "设置按钮",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.unavailableBuyButtonLabel": "不可用",
  "blocks.promoSlider.components.promoSlide.components.customButton.buy.value.link.label": "设置按钮",
  "client.topup.input.errorMessage": "发生了问题。请确保您的用户ID正确或稍后再试。",
  "client.topup.input.successMessage": "您已使用{userID} ID登录。现可在网站上进行购买了。",
  "client.topup.input.username": "用户名：{name}",
  "client.topup.input.id": "ID: {id}",
  "client.topup.input.level": "等级：{level}",
  "client.topup.input.user_not_found": "找不到该用户ID。<br/>请重试。",
  "client.topup.input.serverError": "发生了问题。<br>如果您反复看到此信息，请{contact_us}。",
  "client.topup.input.serverError.contact_us": "联系我们",
  "client.topup.authorized_title": "为此用户购买：",
  "blocks.store.values.custom_title": "<h2>{gameTitle}商店</h2>"
};

module.exports = localizations;
