/** @prettier */
// @flow

import uuid from 'uuid/v4';

import type { IRenderMode } from '#common/flow-types/build';
import type { StoreVirtualCurrency } from '#common/flow-types/store';
import type { AuthorizationTypes } from '#common/model/landing';
import type { FiveSymbolLocale } from '#common/utils/locale';
import type { Environment } from '../../utils/types';
import type { ILoaderService } from '../blocks/loader';
import type { Translations } from '../types';

import { mount } from '../../ui-components/settings/Loader';
import { scrollToElement } from '../../utils/common-helper';
import { openPayStationWidget } from '../../utils/paystation/open-widget';
import {
  getPSTokenForCart,
  getTokenForTopup,
} from '../../utils/paystation/paystation-token';
import { AnalyticsCategories } from '../afterBlocks/analytics/analyticsCategories';
import {
  openLogin,
  getLastLoginSource,
  clearLastLoginSource,
  isLoginLastSource,
  LOGIN_SOURCES,
} from '../blocks/login';
import { saveItemsToCart } from '../blocks/store/api';
import { getCookie } from '../helpers/common';
import { USER_LOGIN_TOKEN } from '../helpers/cookies';
import { initTopupButton } from '../helpers/workWithPayStation';

import { AuthorizationType } from '#common/model/landing';

export class CustomAmountService {
  _authType: AuthorizationTypes;

  _projectId: string;

  _locale: FiveSymbolLocale;

  _renderMode: IRenderMode;

  _userToken: string;

  _analyticsCounterId: number;

  _merchantId: string;

  _environment: Environment;

  _translations: Translations;

  _loginId: string;

  _payStationReturnUrl: string;

  constructor({
    authType,
    projectId,
    locale,
    renderMode,
    analyticsCounterId,
    merchantId,
    environment,
    translations,
    loginId,
    payStationReturnUrl,
  }: {
    authType: AuthorizationTypes,
    projectId: string,
    locale: FiveSymbolLocale,
    renderMode: IRenderMode,
    analyticsCounterId: number,
    merchantId: string,
    environment: Environment,
    translations: Translations,
    loginId: string,
    payStationReturnUrl: string,
  }) {
    this._userToken = getCookie(USER_LOGIN_TOKEN) || '';
    this._authType = authType;
    this._projectId = projectId;
    this._locale = locale;
    this._renderMode = renderMode;
    this._analyticsCounterId = analyticsCounterId;
    this._merchantId = merchantId;
    this._environment = environment;
    this._translations = translations;
    this._loginId = loginId;
    this._payStationReturnUrl = payStationReturnUrl;
  }

  async buyCustomAmount(
    buttonId: string,
    item: StoreVirtualCurrency,
    quantity: number
  ) {
    if (
      this._authType === AuthorizationType.WEBHOOK ||
      this._authType === AuthorizationType.USER_ID
    ) {
      const button = document.querySelector(`[id='${buttonId}']`);
      await initTopupButton({
        analyticsCategory: AnalyticsCategories.STORE,
        button,
        renderMode: this._renderMode,
        getToken: getTokenForTopup({
          renderMode: this._renderMode,
          merchantId: this._merchantId,
          projectId: this._projectId,
          sku: item.sku,
          quantity,
          locale: this._locale,
          environment: this._environment,
          analyticsCounterId: this._analyticsCounterId,
          translations: this._translations,
          payStationReturnUrl: this._payStationReturnUrl,
        }),
      });
      return;
    }

    if (this._userToken) {
      const unmount = mount({
        isLandingPage: true,
        spinner: 'ps',
      });
      const cartId = uuid();
      await saveItemsToCart({
        userToken: this._userToken,
        projectId: this._projectId,
        cartId,
        items: [
          {
            sku: item.sku,
            quantity,
          },
        ],
        isAuth: true,
      });

      const token = await getPSTokenForCart({
        cartId,
        isAuth: true,
        locale: this._locale,
        payStationReturnUrl: this._payStationReturnUrl,
        projectId: this._projectId,
        renderMode: this._renderMode,
        userToken: this._userToken,
      });
      unmount();
      openPayStationWidget({
        token,
        renderMode: this._renderMode,
      });
    } else {
      openLogin({ source: buttonId });
    }
  }
}
export class CustomAmountLoaderService implements ILoaderService {
  _id: string;

  constructor() {
    this._id = getLastLoginSource();
  }

  // eslint-disable-next-line class-methods-use-this
  checkAttach() {
    return isLoginLastSource(LOGIN_SOURCES.CUSTOM_AMOUNT_BUTTON);
  }

  checkDetach() {
    return (
      isLoginLastSource(LOGIN_SOURCES.CUSTOM_AMOUNT_BUTTON) &&
      !!document.querySelector(`[id='${this._id}']`)
    );
  }

  scrollToButton() {
    const button = document.querySelector(`[id='${this._id}']`);
    if (!button) {
      return;
    }
    scrollToElement(button, { block: 'center' });
    button.click();
  }

  detach() {
    this.scrollToButton();
    clearLastLoginSource();
  }
}

export const onClickCustomAmount =
  (service: CustomAmountService) =>
  ({
    buttonId,
    item,
    quantity,
  }: {
    buttonId: string,
    item: StoreVirtualCurrency,
    quantity: number,
  }) => {
    service.buyCustomAmount(buttonId, item, quantity);
  };
