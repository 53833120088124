import moneyFormatter from 'money-formatter';

import { FiveSymbolLocale } from '#common/types/locale';
import { SubscriptionPeriodType } from '#common/types/model/subscription';

const getCurrencyDisplay = (currency: string, amount: string | number) =>
  moneyFormatter.format(currency, amount) as string;

const years = {
  'en-US': 'yrs.',
  'ar-AE': 'عاما',
  'bg-BG': 'год',
  'cs-CZ': 'let',
  'de-DE': 'Jahre',
  'es-ES': 'años',
  'fr-FR': 'années',
  'he-IL': "שנ'", // eslint-disable-line
  'it-IT': 'aa.',
  'ja-JP': '年',
  'ko-KR': '연',
  'pl-PL': 'lat',
  'pt-BR': 'anos',
  'ro-RO': 'ani',
  'ru-RU': 'г.',
  'th-TH': 'ปี',
  'tr-TR': 'yıl',
  'vi-VN': 'năm',
  'zh-CN': '年',
  'zh-TW': '年',
};

const months = {
  'en-US': 'mos.',
  'ar-AE': 'شهرا',
  'bg-BG': 'мес',
  'cs-CZ': 'měs.',
  'de-DE': 'Monate',
  'es-ES': 'meses',
  'fr-FR': 'mois',
  'he-IL': "חו'", // eslint-disable-line
  'it-IT': 'mesi',
  'ja-JP': 'ヵ月',
  'ko-KR': '월',
  'pl-PL': 'm.',
  'pt-BR': 'meses',
  'ro-RO': 'luni',
  'ru-RU': 'мес.',
  'th-TH': 'เดือน',
  'tr-TR': 'ay',
  'vi-VN': 'tháng',
  'zh-CN': '月',
  'zh-TW': '個月',
};

const weeks = {
  'en-US': 'wks.',
  'ar-AE': 'أسبوع',
  'bg-BG': 'седм',
  'cs-CZ': 'týd.',
  'de-DE': 'Wochen',
  'es-ES': 'semanas',
  'fr-FR': 'semaines',
  'he-IL': "שב'", // eslint-disable-line
  'it-IT': 'set.',
  'ja-JP': '週',
  'ko-KR': '주',
  'pl-PL': 'tyg.',
  'pt-BR': 'semanas',
  'ro-RO': 'săpt.',
  'ru-RU': 'нед.',
  'th-TH': 'สัปดาห์',
  'tr-TR': 'hafta',
  'vi-VN': 'tuần',
  'zh-CN': '周',
  'zh-TW': '週',
};

const days = {
  'en-US': 'd',
  'ar-AE': 'يوم',
  'bg-BG': 'дни',
  'cs-CZ': 'dní',
  'de-DE': 'Tage',
  'es-ES': 'días',
  'fr-FR': 'jours',
  'he-IL': 'ימים', // eslint-disable-line
  'it-IT': 'gg.',
  'ja-JP': '日',
  'ko-KR': '일',
  'pl-PL': 'd.',
  'pt-BR': 'dias',
  'ro-RO': 'zile',
  'ru-RU': 'д.',
  'th-TH': 'วัน',
  'tr-TR': 'gün',
  'vi-VN': 'ngày',
  'zh-CN': '天',
  'zh-TW': '天',
};

const year = {
  'en-US': 'year',
  'ar-AE': 'سنة',
  'bg-BG': 'година',
  'cs-CZ': 'rok',
  'de-DE': 'Jahr',
  'es-ES': 'año',
  'fr-FR': 'Année',
  'he-IL': 'שנה',
  'it-IT': 'Anno',
  'ja-JP': '年間',
  'ko-KR': '년',
  'pl-PL': 'rok',
  'pt-BR': 'Ano',
  'ro-RO': 'an',
  'ru-RU': 'год',
  'th-TH': 'ปี',
  'tr-TR': 'Yıl',
  'vi-VN': 'Năm',
  'zh-CN': '年',
  'zh-TW': '年',
};

const month = {
  'en-US': 'month',
  'ar-AE': 'شهر',
  'bg-BG': 'месец',
  'cs-CZ': 'měsíc',
  'de-DE': 'Monat',
  'es-ES': 'mes',
  'fr-FR': 'Mois',
  'he-IL': 'חודש',
  'it-IT': 'Mese',
  'ja-JP': 'ヵ月間',
  'ko-KR': '개월',
  'pl-PL': 'miesiąc',
  'pt-BR': 'Mês',
  'ro-RO': 'lună',
  'ru-RU': 'месяц',
  'th-TH': 'เดือน',
  'tr-TR': 'Ay',
  'vi-VN': 'Tháng',
  'zh-CN': '个月',
  'zh-TW': '個月',
};

const week = {
  'en-US': 'week',
  'ar-AE': 'أسبوع',
  'bg-BG': 'седмица',
  'cs-CZ': 'týden',
  'de-DE': 'Woche',
  'es-ES': 'semana',
  'fr-FR': 'Semaine',
  'he-IL': 'שבוע',
  'it-IT': 'Settimana',
  'ja-JP': '週間',
  'ko-KR': '주',
  'pl-PL': 'tydzień',
  'pt-BR': 'Semana',
  'ro-RO': 'săptămână',
  'ru-RU': 'неделя',
  'th-TH': 'สัปดาห์',
  'tr-TR': 'Hafta',
  'vi-VN': 'Tuần',
  'zh-CN': '周',
  'zh-TW': '週',
};

const day = {
  'en-US': 'day',
  'ar-AE': 'يوم',
  'bg-BG': 'ден',
  'cs-CZ': 'den',
  'de-DE': 'Tag',
  'es-ES': 'día',
  'fr-FR': 'Jour',
  'he-IL': 'יום',
  'it-IT': 'giorno',
  'ja-JP': '日間',
  'ko-KR': '일',
  'pl-PL': 'dzień',
  'pt-BR': 'Dia',
  'ro-RO': 'zi',
  'ru-RU': 'день',
  'th-TH': 'วัน',
  'tr-TR': 'Gün',
  'vi-VN': 'Ngày',
  'zh-CN': '天',
  'zh-TW': '天',
};

type PeriodType = Partial<
  Record<SubscriptionPeriodType, Partial<Record<FiveSymbolLocale, string>>>
>;

const periodSingle: PeriodType = {
  year,
  month,
  week,
  day,
};

const periodMultiple: PeriodType = {
  year: years,
  month: months,
  week: weeks,
  day: days,
};

export { getCurrencyDisplay, periodSingle, periodMultiple };
