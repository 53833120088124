import { v4 } from 'uuid';

import {
  localeStorageUserId,
  localeStoragePromocode,
} from '../../../../utils/store-helper';
import { StoreAPICartService } from '../services/StoreAPICartService';
import { StoreAPIDataService } from '../services/StoreAPIDataService';
import { Cart } from './Cart';
import { GameKeyCartItem } from './GameKeyCartItem';

export class UniversalCart extends Cart {
  private unauthorizedCart?: Cart | null;

  private _promocode: string;

  private readonly cartId: string;

  private readonly localStorageKey: string;

  set promocode(newPromocode: string) {
    const localStorageKey = `${localeStoragePromocode(this.cartId)}`;
    localStorage.setItem(localStorageKey, newPromocode);
    this._promocode = newPromocode;
  }

  get promocode() {
    return this._promocode;
  }

  constructor(
    projectId: string,
    cartId: string,
    dataAPIService: StoreAPIDataService,
    authorizedUserToken = ''
  ) {
    const isAuth = !!authorizedUserToken;
    const localStorageKey = localeStorageUserId(cartId);
    const unauthorizedId = localStorage.getItem(localStorageKey);

    let userToken = authorizedUserToken;
    if (!isAuth) {
      userToken = localStorage.getItem(localStorageKey) || v4();
      localStorage.setItem(localStorageKey, userToken);
    }

    const storeAPICartService = new StoreAPICartService(
      userToken,
      projectId,
      cartId,
      dataAPIService,
      isAuth
    );
    super(storeAPICartService, dataAPIService);

    this.unauthorizedCart =
      isAuth && unauthorizedId
        ? Cart.getUnauthorizedCart(
            unauthorizedId,
            projectId,
            cartId,
            dataAPIService
          )
        : null;
    this.localStorageKey = localStorageKey;
    this.cartId = cartId;
    this._promocode =
      localStorage.getItem(
        localeStoragePromocode(this._storageService.cartId)
      ) || '';
  }

  async load() {
    await super.load();
    if (this.unauthorizedCart) {
      await this.unauthorizedCart.load();
      if (this.unauthorizedCart.items.length > 0) {
        this._items = [...this._items, ...this.unauthorizedCart.items];
        await this.save();
        this.removeUnauthorizedCart();
      }
      if (this.promocode && this._items.length > 0) {
        await super.redeemPromocode(this.promocode);
      }
    }
  }

  async clearAll() {
    await super.clearAll();
    await this.cancelRedeemPromocode();
  }

  redeemPromocode(
    promocode: string,
    selectedUnitItems?: Record<string, string>
  ) {
    this.promocode = promocode;
    return super.redeemPromocode(promocode, selectedUnitItems);
  }

  cancelRedeemPromocode() {
    this.promocode = '';
    return super.cancelRedeemPromocode();
  }

  async deleteNonExistentItems() {
    const currentItems = this.items;
    await Promise.all(
      currentItems
        .filter((item) => {
          if (item instanceof GameKeyCartItem) {
            return !(
              this._dataAPIService.findGKByDRM(item.selectedDRM) &&
              (item.hasKeys() || item.isPreOrder)
            );
          }
          return !this._dataAPIService.findBySku(item.sku);
        })
        .map((item) => this.delete(item))
    );
  }

  removeUnauthorizedCart() {
    this.unauthorizedCart = null;
    localStorage.removeItem(this.localStorageKey);
  }
}
