/**
 * @flow
 * @prettier
 */

import type { Product } from '#common/flow-types/subscriptionsPacks/types';
import type { Subscription } from '#common/model/subscription';
import type { Action } from '../../../types';

import {
  INIT_BLOCKS,
  INIT_BLOCK,
  NETWORK_ERROR,
  FETCHING_PLANS,
  FETCHED_PLANS,
  FETCHING_PRODUCTS,
  FETCHED_PRODUCTS,
  UPDATE_PRODUCT,
  UPDATE_PLAN,
} from './constants';

export type SubscriptionState = {|
  plans: Subscription[],
  products: Product[],
  loading: boolean,
  packs: {
    [string]: {
      groupId: null | string | number,
      productId: null | string | number,
    },
  },
|};

const initialState: SubscriptionState = {
  plans: [],
  products: [],
  loading: false,
  packs: {
    default: {
      groupId: null,
      productId: null,
    },
  },
};

export default function subscriptionsPacksReducer(
  state: SubscriptionState = initialState,
  action: Action
) {
  switch (action.type) {
    case INIT_BLOCKS: {
      return { ...state, ...{ packs: {} } };
    }
    case INIT_BLOCK: {
      const {
        payload: {
          subscriptionsPack: {
            _id,
            components,
            values: { groupId },
          },
        },
      } = action;

      const productIdIndex = components.findIndex(
        (el) =>
          el.type === 'product' &&
          el.value.productId !== '' &&
          el.value.productId !== '0' &&
          el.value.productId !== 0
      );
      const productId =
        productIdIndex >= 0 ? components[productIdIndex].value.productId : null;

      const pack = {
        [_id]: {
          groupId: groupId !== '0' && groupId !== 0 ? groupId : null,
          productId,
        },
      };

      return { ...state, ...{ packs: { ...state.packs, ...pack } } };
    }
    case NETWORK_ERROR: {
      return { ...state, ...{ loading: false } };
    }
    case FETCHING_PLANS:
    case FETCHING_PRODUCTS: {
      return { ...state, ...{ loading: true } };
    }
    case FETCHED_PLANS: {
      const {
        payload: { plans },
      } = action;
      return { ...state, ...{ plans, loading: false } };
    }
    case FETCHED_PRODUCTS: {
      const {
        payload: { products },
      } = action;
      return { ...state, ...{ products, loading: false } };
    }
    case UPDATE_PRODUCT: {
      const {
        payload: { id, updateObj },
      } = action;

      return { ...state, packs: { ...state.packs, ...{ [id]: updateObj } } };
    }
    case UPDATE_PLAN:
    default:
      return state;
  }
}
