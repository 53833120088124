// @flow

import React from 'react';

import './style.pcss';

type Props = {
  items: string[]
}

const Tags = ({ items }: Props) => (
  <div className='tags__container'>
    {items.map(text => <span key={text} className='tags__item'>{text}</span>)}
  </div>
);

export default Tags;
