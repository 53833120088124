"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.selectSelfOrParentDocumentLogin = exports.selectDocumentParent = exports.selectDocumentLogin = void 0;

const selectDocumentLogin = document => {
  const [defaultPage] = document?.pages;
  return defaultPage?.auth?.loginId;
};

exports.selectDocumentLogin = selectDocumentLogin;

const selectDocumentParent = document => document?.site?.parent;

exports.selectDocumentParent = selectDocumentParent;

const selectSelfOrParentDocumentLogin = document => {
  const parentDocument = selectDocumentParent(document);
  return selectDocumentLogin(parentDocument || document);
};

exports.selectSelfOrParentDocumentLogin = selectSelfOrParentDocumentLogin;