// @flow
import type { StoreBlock, HeaderBlock } from '#common/flow-types/blocks';
import type { IRenderMode, Environment } from '#common/flow-types/build';
import type { Authorization, ThemeType, WidgetsType } from '#common/model/landing';
import type { Subscription } from '#common/model/subscription';
import type { FiveSymbolLocale } from '#common/utils/locale';
import type { Theme as LoginTheme } from '../../../utils/login/types';
import type { NewsBlockTemplate } from '../../../utils/types';
import type { Action, Dispatch, GetState } from '../../types';
import type { RootState } from './index';

import {RetailersType} from "./retailers";

import {CartSettings} from "#common/types/model/landing";

// TODO привести дефолтное состояние к одному виду
export type ScriptsState = {|
  auth: null | Authorization,
  accentColor: string,
  analyticsCounterId: number,
  bannerBackgroundColor: string,
  buttonBorderRadius: number,
  buttonTextColor: string,
  domain: string,
  embeds: Object[],
  environment: Environment,
  facebookPixel?: string,
  fb?: ?string,
  landingId: string,
  landingType: string,
  landingTheme: ThemeType,
  gallerys: Object[],
  sliders: Object[],
  googleAnalytics?: string,
  googleOptimize?: string,
  gtm: string,
  headerFont: string,
  isNewScheme: boolean,
  isPreviewFrameMode: boolean,
  isStoreBlockEnabled?: boolean,
  isTokenlessIntegration: ?boolean,
  isVideo?: boolean,
  launcherNewsInfo: Array<{
    blockId: string,
    launcherId: string,
    template: NewsBlockTemplate
  }>,
  locale: FiveSymbolLocale,
  locales: FiveSymbolLocale[],
  loginId: string,
  loginWidgetGeneration: 0 | 1 | null,
  mainFont: string,
  merchantId: string,
  modalBackgroundColor: string,
  originalTextColor: string,
  projectId: string,
  retailersData: RetailersType[],
  renderMode: IRenderMode,
  blocksStructures: Array<StoreBlock | HeaderBlock>,
  widgets: WidgetsType | null,
  storeItemsGroups: Object[],
  subscriptionPurchase: Subscription[],
  subscriptions: Object[],
  textColor: string,
  tweet: ?string,
  twitterPixel: string,
  isMultipage: boolean,
  cartId: string,
  userIdPlaceholders: Array<{
    text: string,
    blockId: string
  }>,
  payStationReturnUrl: string,
  loginTheme: LoginTheme | {},
  cart: CartSettings,
|};

const scripts: ScriptsState = {
  auth: null,
  gallerys: [],
  sliders: [],
  subscriptions: [],
  locale: 'en-US',
  locales: [],
  domain: '',
  gtm: '',
  fb: '',
  landingId: '',
  landingType: '',
  landingTheme: {
    accentColor: '',
    backgroundBlur: 0,
    buttonBorderRadius: 0,
    buttonTextColor: '',
    mainFont: '',
    headerFont: '',
    textColor: '',
    borderColor: '',
    secondaryColor: '',
    overlayColor: ''
  },
  tweet: '',
  twitterPixel: '',
  analyticsCounterId: 0,
  merchantId: '',
  projectId: '',
  loginId: '',
  embeds: [],
  loginWidgetGeneration: null,
  launcherNewsInfo: [],
  accentColor: '',
  buttonTextColor: '',
  textColor: '',
  originalTextColor: '',
  headerFont: '',
  mainFont: '',
  buttonBorderRadius: 0,
  bannerBackgroundColor: '',
  modalBackgroundColor: '',
  isNewScheme: true,
  isPreviewFrameMode: false,
  isTokenlessIntegration: null,
  subscriptionPurchase: [],
  environment: 'development',
  blocksStructures: [],
  widgets: null,
  storeItemsGroups: [],
  retailersData: [],
  renderMode: 'preview',
  isMultipage: false,
  cartId: '',
  userIdPlaceholders: [],
  payStationReturnUrl: '',
  loginTheme: {},
  cart: {
    enable: false
  },
};

export const ADD_SCRIPT_DATA = 'ADD_SCRIPT_DATA';
export const SET_SERVER_DATA = 'SET_SERVER_DATA';

export const addScriptData = (name: string, data: mixed) => (
  dispatch: Dispatch, getState: GetState<RootState>
) => {
  const { landing: { isServerSideRender } } = getState();
  if (isServerSideRender) {
    dispatch({
      type: ADD_SCRIPT_DATA,
      name,
      data
    });
  }
};

export const setServerData = (name: string, data: mixed) => (
  dispatch: Dispatch, getState: GetState<RootState>
) => {
  const { landing: { isServerSideRender } } = getState();
  if (isServerSideRender) {
    dispatch({
      type: SET_SERVER_DATA,
      name,
      data
    });
  }
};

export default function scriptsReducer(state: ScriptsState = scripts, action: Action) {
  switch (action.type) {
    case ADD_SCRIPT_DATA:
      return { ...state, [action.name]: [...state[action.name], action.data] };
    case SET_SERVER_DATA:
      return { ...state, [action.name]: action.data };
    default:
      return state;
  }
}
