/** @prettier */
// @flow

import type { ObjectObserver } from '../../utils/class-helper';
import type { IUserInfo } from '../blocks/topup/services';
import type { AxiosPromise } from 'axios';

import { Status as InputStatus } from '../../ui-components/Editor/Input';
import { analyticsManager } from '../afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../afterBlocks/analytics/analyticsCategories';
import { UserIdInputStatus } from '../blocks/topup/services';
import { setUserAttribute, getTokenFromCookie } from './common';
import { getStoreTopUpGameSku, validateUserID } from './customCodeSettings';
import { NewrelicCustomEvent, nrSendEvent } from './newrelic';

export const saveUserIdInLoginAttribute = async (
  value: string,
  projectId: string
): Promise<void> => {
  const userToken = getTokenFromCookie() || '';
  const gameSKU = getStoreTopUpGameSku();
  const requestBody = {
    attributes: [
      {
        key: `${gameSKU}_userID`,
        value,
        permission: 'public',
      },
    ],
    publisher_project_id: Number(projectId),
  };
  const result = await setUserAttribute(userToken, requestBody);
  if (result.status !== 204) {
    throw new Error('Occurred error with save user-id value');
  }
};

/**
 * It uses gameloft custom integration with method in custom code
 * Sets status of submission as valid otherwise (topup without method)
 */
export const onMethodlessSubmit =
  (userIdServiceObserver: ObjectObserver, projectId: string) =>
  async (
    value: string,
    setStatusState: (value: $Values<typeof InputStatus>) => void
  ): Promise<void> => {
    setStatusState(InputStatus.PROCESSING);
    userIdServiceObserver.target.status = UserIdInputStatus.LOADING;
    // partner validation flow (e.g. gameloft flow)
    if (getStoreTopUpGameSku() && validateUserID) {
      try {
        const { status } = await validateUserID(value);
        if (status !== 'ok') {
          userIdServiceObserver.target.status = UserIdInputStatus.INVALID;
          throw new Error(
            'Occurred error with validation through partner method'
          );
        } else {
          await saveUserIdInLoginAttribute(value, projectId);
          userIdServiceObserver.target.status =
            UserIdInputStatus.VALIDATION_PASSED;
        }
      } catch (error) {
        userIdServiceObserver.target.status = UserIdInputStatus.INVALID;
      }
    } else {
      userIdServiceObserver.target.status = UserIdInputStatus.VALIDATION_PASSED;
    }

    userIdServiceObserver.target.userInfo = {
      id: value,
    };
    userIdServiceObserver.target.inputValue = value;
  };

const USER_NOT_FOUND_STATUS_CODE = 455;

export const onMethodfulSubmit =
  (
    userIdServiceObserver: ObjectObserver,
    request: (string) => AxiosPromise<{ user: IUserInfo }>
  ) =>
  async (
    value: string,
    setStatusState: (value: $Values<typeof InputStatus>) => void
  ): Promise<void> => {
    setStatusState(InputStatus.PROCESSING);
    userIdServiceObserver.target.status = UserIdInputStatus.LOADING;

    try {
      analyticsManager.sendEvent({
        category: AnalyticsCategories.UID_WIDGET,
        event: AnalyticsCategories.USER_ID_PARTNER_METHOD_VALIDATION_START,
      });
      const res = await request(value);
      userIdServiceObserver.target.userInfo = res.data.user;
      userIdServiceObserver.target.status = UserIdInputStatus.VALIDATION_PASSED;
      analyticsManager.sendEvent({
        category: AnalyticsCategories.UID_WIDGET,
        event: AnalyticsActions.SUCCESS_ID,
        page: `landing_uid-widget_${AnalyticsActions.SUCCESS_ID}`,
        value: res.data.user,
      });
      nrSendEvent(NewrelicCustomEvent.TOPUP_VALIDATION_SUCCESS);
    } catch (error) {
      if (
        error.response &&
        error.response.status === USER_NOT_FOUND_STATUS_CODE
      ) {
        userIdServiceObserver.target.status = UserIdInputStatus.NOT_FOUND;
      } else if (
        error.response &&
        error.response.status !== USER_NOT_FOUND_STATUS_CODE
      ) {
        analyticsManager.sendEvent({
          category: AnalyticsCategories.UID_WIDGET,
          event: AnalyticsActions.ERROR,
          page: `landing_uid-widget_${AnalyticsActions.ERROR}`,
          value: error.response.status,
        });
        nrSendEvent(NewrelicCustomEvent.TOPUP_VALIDATION_FAIL);
        userIdServiceObserver.target.status = UserIdInputStatus.INVALID;
      } else {
        userIdServiceObserver.target.status = UserIdInputStatus.INVALID;
      }
      setStatusState(InputStatus.INVALID);
    }
    userIdServiceObserver.target.inputValue = value;
  };
