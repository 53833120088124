"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeHRef = exports.INTERNAL_CONSTRUCTOR_PATHS = void 0;
// name INTERNAL_CONSTRUCTOR_PATHS is inlined into API/src/views/ssr/scripts/autolocale.js
const INTERNAL_CONSTRUCTOR_PATHS = ['game', 'preview']; // check API/src/views/ssr/scripts/autolocale.js in any change of changeHRef function
// it should not have any external dependencies

exports.INTERNAL_CONSTRUCTOR_PATHS = INTERNAL_CONSTRUCTOR_PATHS;

const changeHRef = ({
  location,
  nextLocale,
  availableLocales
}) => {
  const {
    origin,
    pathname,
    search,
    hash
  } = location;
  const DEFAULT_LOCALE = 'en-US';
  const sanitizedNextLocale = availableLocales.includes(nextLocale) ? nextLocale : DEFAULT_LOCALE;
  const isNextLocaleDefault = sanitizedNextLocale === DEFAULT_LOCALE;
  const paths = pathname.replace(/\/$/, '').split('/').filter(Boolean);
  const localeInUrl = paths.findIndex(path => availableLocales.includes(path));

  if (localeInUrl !== -1 && isNextLocaleDefault) {
    // .../es-ES/page... -> .../page...
    paths.splice(localeInUrl, 1);
  } else if (localeInUrl !== -1 && !isNextLocaleDefault) {
    // .../es-ES/page... -> .../ru-RU/page...
    paths.splice(localeInUrl, 1, sanitizedNextLocale);
  } else if (INTERNAL_CONSTRUCTOR_PATHS.includes(paths[0]) && !isNextLocaleDefault) {
    // .../game/domain/page... -> .../game/domain/es-ES/page/...
    paths.splice(2, 0, sanitizedNextLocale);
  } else if (!isNextLocaleDefault) {
    // .../page... -> .../es-ES/page/...
    paths.unshift(sanitizedNextLocale);
  }

  const path = paths.length === 0 ? '' : `/${paths.join('/')}`;
  return origin + path + search + hash;
};

exports.changeHRef = changeHRef;