/**
 * @prettier
 * @flow
 * */

import type { CartPrice } from '#common/flow-types/store';
import type { Rewards, StoreError } from '../../../helpers/common';
import type { StorageCartService } from '../types';
import type { ErrorCartClient } from './ErrorCartClient.type';

import { ANTIFRAUD_LIMIT_NUMBER } from '../../../../utils/cart/constants';
import { StoreAPICartService } from '../services/StoreAPICartService';
import { StoreAPIDataService } from '../services/StoreAPIDataService';
import { BundleCartItem } from './BundleCartItem';
import CartErrors from './CartErrors';
import { CartItem } from './CartItem';
import { GameKeyCartItem } from './GameKeyCartItem';
import { VICartItem } from './VICartItem';

type Item = CartItem | GameKeyCartItem | VICartItem;

export class Cart {
  static getUnauthorizedCart(
    unauthorizedId: string,
    projectId: string,
    cartId: string,
    dataAPIService: StoreAPIDataService
  ): Cart {
    return new Cart(
      new StoreAPICartService(
        unauthorizedId,
        projectId,
        cartId,
        dataAPIService,
        false
      ),
      dataAPIService
    );
  }

  _items: Item[];

  _storageService: StorageCartService;

  _listeners: Function[];

  _dataAPIService: StoreAPIDataService;

  _isWaitingRedeem: boolean;

  _amount: CartPrice;

  constructor(
    storageService: StorageCartService,
    dataAPIService: StoreAPIDataService
  ) {
    this._storageService = storageService;
    this._items = [];
    this._listeners = [];
    this._dataAPIService = dataAPIService;
  }

  get items() {
    return this._items;
  }

  addListener(fn: Function) {
    this._listeners.push(fn);
  }

  _notify() {
    this._listeners.forEach((fn) => fn());
  }

  get quantity() {
    return this._items.reduce((acc, { quantity }) => acc + quantity, 0);
  }

  set items(value: Item[]) {
    this._items = value;
    this._notify();
  }

  addItem(item: Item) {
    this._items.push(item);
    this._notify();
  }

  get userToken() {
    return this._storageService.userToken;
  }

  async load() {
    const cart = await this._storageService.load();
    if (cart && cart.items) {
      this._amount = cart.price;
      this.items = cart.items;
    }
    this._notify();
  }

  async save() {
    const cart = await this._storageService.save(this._items);
    if (!cart.errorCode) {
      this.items = cart.items;
      this._amount = cart.price;
    }
    this._notify();
  }

  async clearAll() {
    await this._storageService.clearAll();
    this.items = [];
  }

  amount() {
    return this._amount;
  }

  getItemBySKU(
    sku: string,
    drm: string = ''
  ): GameKeyCartItem | CartItem | void {
    return this._items.find((item) =>
      item instanceof GameKeyCartItem && !!drm
        ? item.selectedDRM === drm
        : item.hasSKU(sku)
    );
  }

  setQuantityBySku(quantity: number, sku: string): boolean {
    const found = this._items.find((item) => item._sku === sku);
    if (found) {
      found._quantity = quantity;
      return true;
    }
    return false;
  }

  async add(
    item: GameKeyCartItem | CartItem
  ): Promise<StoreError | ErrorCartClient | void> {
    const { sku } = item;
    const addOne = (sku) => {
      if (
        item instanceof BundleCartItem &&
        item.bundleType === 'virtual_currency_package'
      ) {
        const found = this._items.find((item) => item.sku === sku);
        if (found && found.quantity < ANTIFRAUD_LIMIT_NUMBER) {
          found.quantity += 1;
          return found.quantity;
        }
      }
      return 1;
    };

    const drm = item instanceof GameKeyCartItem ? item.selectedDRM : '';
    if (
      item instanceof GameKeyCartItem &&
      this.gameKeysNumber() >= ANTIFRAUD_LIMIT_NUMBER
    ) {
      return {
        status: 'error',
        error: CartErrors.gt10_gk,
      };
    }
    if (
      item instanceof GameKeyCartItem &&
      !item.hasKeys() &&
      !item.isPreOrder
    ) {
      return {
        status: 'error',
        error: CartErrors.no_keys,
      };
    }
    if (
      item instanceof VICartItem &&
      item.virtualItemType === 'non_consumable' &&
      this.hasSKU(sku)
    ) {
      return {
        status: 'error',
        error: CartErrors.non_consumable,
      };
    }
    const result = await this._storageService.add({
      sku: drm || sku,
      quantity: addOne(sku),
    });
    if (!result?.errorCode) {
      this.load();
    }
    return result;
  }

  async delete(deletedItem: GameKeyCartItem | CartItem) {
    const foundItem = this._items.find((item) => item.sku === deletedItem.sku);
    if (!foundItem) {
      throw Error(`item for delete not found in cart, sku: ${deletedItem.sku}`);
    }
    await this._storageService.delete(deletedItem);
  }

  async deleteBySku(sku: string) {
    if (this._storageService) {
      await this._storageService.deleteBySku(sku);
    }
  }

  hasTemporaryItemsWithoutDRM() {
    return (
      this.items
        // $FlowFixMe
        .filter((item) => item.isTemporaryItem)
        // $FlowFixMe
        .some(({ selectedDRM }) => !selectedDRM)
    );
  }

  gameKeysNumber() {
    return this._items.filter((item) => item instanceof GameKeyCartItem).length;
  }

  hasInventoryItems() {
    return (
      this._items.length -
        this.gameKeysNumber() -
        this.bundleWithGamesNumber() >
      0
    );
  }

  hasGameKeys() {
    return this.gameKeysNumber() > 0;
  }

  bundleWithGamesNumber() {
    return this._items.filter(
      (item) =>
        item instanceof BundleCartItem &&
        item.bundleType === 'standard' &&
        !item.filterAuthRequired()
    ).length;
  }

  hasSKU(sku: string, drm: string = '') {
    return !!this.getItemBySKU(sku, drm);
  }

  warnings() {
    return this._storageService.warnings();
  }

  async rewardPromocode(promocode: string): Promise<StoreError | Rewards> {
    const reward = await this._storageService.rewardPromocode(promocode);
    if (reward.is_selectable) {
      return reward;
    }

    if (!reward.discounted_items) {
      return reward;
    }

    const foundItems = this.items.filter((item) => {
      const sku = item instanceof GameKeyCartItem ? item.selectedDRM : item.sku;
      return reward.discounted_items.find(
        (discountItem) => discountItem.sku === sku
      );
    });

    if (foundItems.length !== reward.discounted_items.length) {
      return {
        errorCode: 9809,
        errorMessage:
          'This promo code can’t be applied to the items you selected.',
      };
    }
    return reward;
  }

  async redeemPromocode(
    promocode: string,
    selectedUnitItems?: { [key: string]: string }
  ) {
    const response = await this._storageService.redeemPromocode(
      promocode,
      selectedUnitItems
    );
    if (response && response.items) {
      this._amount = response.price;
      this.items = response.items;
    }
    return response;
  }

  async cancelRedeemPromocode() {
    const response = await this._storageService.cancelPromocode();
    if (response && response.items) {
      this._amount = response.price;
      this.items = response.items;
    }
    return response;
  }
}
