// @flow
import type { IRenderMode } from '#common/flow-types/build';

import { RenderMode } from '#common/flow-types/build';


let landingAPISetting: null | boolean = null;

export const setSandboxMode = (value: boolean) => {
  landingAPISetting = value;
};

export const isSandboxMode = (renderMode: IRenderMode = 'preview') => {
  if (landingAPISetting != null) {
    return landingAPISetting;
  }
  return renderMode === RenderMode.PREVIEW;
};
