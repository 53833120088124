// @flow
export default {
  header: {
    init: 'custom-event:header:init'
  },
  gallery: {
    init: 'custom-event:gallery:init'
  },
  store: {
    init: 'custom-event:store:init',
    dataLoaded: 'custom-event:store:data-loaded'
  },
  gameKey: {
    init: 'custom-event:gameKey:init'
  },
  subscription: {
    init: 'custom-event:subscription:init'
  },
  cart: {
    init: 'custom-event:cart:init',
    update: 'custom-event:cart:update'
  },
  universalItem: {
    init: 'custom-event.universalItem:init'
  },
  promoSlider: {
    init: 'custom-event:promoSlider:init'
  }
};
