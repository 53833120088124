/** @prettier */
/* eslint-disable */
import { analyticsManager, GoogleAnalytics, DataLayer } from './analytics';
import { XSOLLA_GOOGLE_ANALYTICS_ID, SECURE_HOST } from '../../../config';
import { hideGdprConsent } from '../../landingAPI/hideGdprConsent';

export default (data) => {
  const { googleAnalytics: partnerGA, googleOptimize } = data;

  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${XSOLLA_GOOGLE_ANALYTICS_ID}`;
  const head = document.getElementsByTagName('head')[0];
  head.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  window.gtag =
    window.gtag ||
    function () {
      dataLayer.push(arguments);
    };
  gtag('js', new Date());

  if (!hideGdprConsent()) {
    gtag('config', XSOLLA_GOOGLE_ANALYTICS_ID, {
      linker: {
        domains: [SECURE_HOST, window.location.hostname],
      },
      custom_map: {
        dimension8: 'projectID',
        dimension10: 'projectName',
      },
    });
  }

  if (partnerGA) {
    gtag('config', partnerGA, {
      optimize_id: googleOptimize,
    });
    analyticsManager.subscribe(new GoogleAnalytics(partnerGA));
  }

  return true;
};
