import { documentClickListener } from '../../../utils/document-listener';
import { isMobileScreen } from '../../../utils/mobile';
import { analyticsManager } from '../../afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../../afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../../afterBlocks/analytics/analyticsCategories';
import {
  closeModalWindow,
  IModalWindow,
  openModalWindow,
} from '../../ui-components/editor/modalWindow';

export const showMoreBundle = () => {
  const handleShowMore = (showMoreClickableArea) => {
    const sku = showMoreClickableArea.getAttribute('data-sku');
    const modal = document.querySelector(
      `[data-sku="${sku}"][data-type="bundle-content-modal"]`
    );
    openModalWindow(modal, 'Bundle');
    analyticsManager.sendEvent({
      category: AnalyticsCategories.STORE,
      event: AnalyticsActions.CLICK_MORE_INFO,
      page: `landing_store_${AnalyticsActions.CLICK_MORE_INFO}`,
      label: showMoreClickableArea.getAttribute('data-sku'),
    });
  };
  documentClickListener.addListener({
    selector: '[data-button-type="show-more"]',
    handle: handleShowMore,
  });

  if (isMobileScreen()) {
    documentClickListener.addListener({
      selector: '[data-clickable="true"]',
      handle: handleShowMore,
    });
  }
};

export const closeBundleInfoModal = (sku: string) => {
  const modal = document.querySelector(
    `[data-sku="${sku}"][data-type="bundle-content-modal"]`
  );
  if (modal) {
    closeModalWindow(modal as IModalWindow);
  }
  analyticsManager.sendEvent({
    category: AnalyticsCategories.STORE,
    event: AnalyticsActions.CLOSE_BUNDLE_MODAL,
    page: `landing_store_${AnalyticsActions.CLOSE_BUNDLE_MODAL}`,
  });
};
