/** @prettier */
import { initCustomAuthorization } from '../../experiments/customAuth/utils';
import { subscriptionsService } from '../actions/subscriptions';
import { initLazy } from './lazyLoad';
import relativeLinks from './relativeLinks';
import { saveSearchParams } from './urlSearchParams';
import { checkYoutubeThumbnail } from './youtubeThumbnail';

export default async (data) => {
  const needRedirect = saveSearchParams();
  checkYoutubeThumbnail(data);
  relativeLinks(data);
  await subscriptionsService.init(data);
  initCustomAuthorization(data);
  initLazy();
  return needRedirect;
};
