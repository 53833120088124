/** @prettier */

import { AnalyticsActions } from './analyticsActions';

export enum XsollaMetrikaEventNames {
  ADD_TO_CART = 'sb_l_store_add-to-cart',
  ADD_ITEM_PROMOCODE = 'sb_l_cart_promocode_additem',
  AUTH_SUCCESS = 'sb_l_authorization-success',
  BUNDLE_BUTTON = 'bundle_button',
  CART_CHECKOUT = 'sb_cart_checkout',
  CART_SALE_PROMOCODE = 'sb_l_cart_promocode_cartsale',
  CHOOSE_DRM_PROMOCODE = 'sb_l_cart_promocode_choosedrm',
  CLEAR_CART = 'sb_l_cart-clear',
  CLICK_APPSTORE_BUTTON = 'apple_store_button',
  CLICK_CART_IN_HEADER = 'sb_l_header_click-cart',
  CLICK_GO_TO_CHECKOUT = 'sb_l_store_go-to-cart',
  CLICK_GOOGLEPLAY_BUTTON = 'google_play_button',
  CLICK_LOGIN = 'xsolla_login_click_login',
  CLOSE_BUNDLE_MODAL = 'sb_l_pop-up-bundle_close',
  CLOSE_CART = 'sb_l_cart-close',
  CLOSE_IMAGE_FANCYBOX = 'sb_fancybox_image_close',
  CLOSE_MODAL_USER_ID = 'sb_l_uid-modal_close',
  CLOSE_USER_ID_INSTRUCTION = 'sb_l_uid-modal_user-instruction_close',
  CONTINUE_PURCHASES = 'sb_l_cart-continue',
  CUSTOM_AMOUNT_PLUS = 'sb_store-block_custom-vc_plus',
  CUSTOM_AMOUNT_MINUS = 'sb_store-block_custom-vc_minus',
  DELETE_PROMOCODE = 'sb_l_cart_promocode_delete',
  DELETE_FROM_CART = 'sb_l_cart-delete',
  ERROR_PROMOCODE = 'sb_l_cart_promocode_error',
  EMAIL_INPUT_FILLING = 'sb_l_cart-input-email-start',
  FOCUS_ON_USER_ID_INPUT = 'sb_l_uid-modal_input-focus',
  FOCUS_PROMOCODE = 'sb_l_cart_promocode_focus',
  IDENTIFICATION_BY_USER_ID = 'sb_l_webshop_userid_auth',
  ITEM_SALE_PROMOCODE = 'sb_l_cart_promocode_itemsale',
  OPEN_BUNDLE_POPUP = 'sb_l_pop-up-bundle_open',
  OPEN_CART = 'sb_l_open_cart',
  OPEN_DESCRIPTION_IMAGE = 'sb_description_image_open',
  OPEN_LOGIN = 'xsolla_login_click_login',
  OPEN_MODAL_USER_ID = 'sb_l_uid-modal_open',
  OPEN_MODAL_USER_ID_FROM_LOGIN = 'sb_l_login-click_open-uid-modal',
  OPEN_MODAL_USER_ID_ON_BUY_NOW_BUTTON = 'sb_l_buy-click_open-uid-modal',
  OPEN_PS_FROM_STORE = 'sb_store-block_buy-button_open-ps',
  OPEN_USER_ID_INSTRUCTION = 'sb_l_uid-modal_user-instruction_open',
  OPEN_USER_ID_INSTRUCTION_IMAGE = 'sb_l_uid-modal_user-instruction_open-media',
  REDEEM_PROMOCODE = 'sb_l_cart_promocode_redeem',
  RESET_TOPUP_INPUT = 'sb_topup_change-user',
  SKU_BUTTON = 'sku_button',
  SUBSCRIBE_TO_NEWS = 'subscribe',
  SUBSCRIPTION_BUTTON = 'subscription_button',
  SCROLL_TO_TOPUP_INPUT_FROM_STORE = 'sb_store-block_buy-button_scroll-to-topup-input',
  USER_ID_CONFIRM = 'sb_l_uid-modal_uid-confirm',
  USER_ID_PARTNER_METHOD_VALIDATION_START = 'sb_l_uid-modal_partner-validation_start',
  USER_ID_PARTNER_METHOD_VALIDATION_SUCCESS = 'sb_l_uid-modal_partner-validation_success',
  USER_ID_PARTNER_METHOD_VALIDATION_FAIL = 'sb_l_uid-modal_partner-validation_fails',
}

export const EventForXsollaMetrika = {
  [AnalyticsActions.ADD]: XsollaMetrikaEventNames.ADD_TO_CART,
  [AnalyticsActions.ADD_VC]: XsollaMetrikaEventNames.CUSTOM_AMOUNT_PLUS,
  [AnalyticsActions.CHECKOUT]: XsollaMetrikaEventNames.CART_CHECKOUT,
  [AnalyticsActions.CLEAR]: XsollaMetrikaEventNames.CLEAR_CART,
  [AnalyticsActions.CLICK_APPSTORE]:
    XsollaMetrikaEventNames.CLICK_APPSTORE_BUTTON,
  [AnalyticsActions.CLICK_CART]: XsollaMetrikaEventNames.CLICK_CART_IN_HEADER,
  [AnalyticsActions.CLICK_CHECKOUT]:
    XsollaMetrikaEventNames.CLICK_GO_TO_CHECKOUT,
  [AnalyticsActions.CLICK_GOOGLEPLAY]:
    XsollaMetrikaEventNames.CLICK_GOOGLEPLAY_BUTTON,
  [AnalyticsActions.CLICK_INSTRUCTION]:
    XsollaMetrikaEventNames.OPEN_USER_ID_INSTRUCTION,
  [AnalyticsActions.CLICK_LOGIN]: XsollaMetrikaEventNames.CLICK_LOGIN,
  [AnalyticsActions.CLICK_MORE_INFO]: XsollaMetrikaEventNames.OPEN_BUNDLE_POPUP,
  [AnalyticsActions.CONTINUE]: XsollaMetrikaEventNames.CONTINUE_PURCHASES,
  [AnalyticsActions.CONFIRM]: XsollaMetrikaEventNames.USER_ID_CONFIRM,
  [AnalyticsActions.DELETE]: XsollaMetrikaEventNames.DELETE_FROM_CART,
  [AnalyticsActions.ENTER_EMAIL]: XsollaMetrikaEventNames.SUBSCRIBE_TO_NEWS,
  [AnalyticsActions.ERROR]:
    XsollaMetrikaEventNames.USER_ID_PARTNER_METHOD_VALIDATION_FAIL,
  [AnalyticsActions.INPUT]: XsollaMetrikaEventNames.FOCUS_ON_USER_ID_INPUT,
  [AnalyticsActions.INPUT_EMAIL]: XsollaMetrikaEventNames.EMAIL_INPUT_FILLING,
  [AnalyticsActions.OPEN_CART]: XsollaMetrikaEventNames.OPEN_CART,
  [AnalyticsActions.OPEN_LOGIN]: XsollaMetrikaEventNames.OPEN_LOGIN,
  [AnalyticsActions.OPEN_UID_WIDGET]:
    XsollaMetrikaEventNames.OPEN_MODAL_USER_ID_FROM_LOGIN,
  [AnalyticsActions.OPEN_UID_WIDGET_FROM_BUY_NOW]:
    XsollaMetrikaEventNames.OPEN_MODAL_USER_ID_ON_BUY_NOW_BUTTON,
  [AnalyticsActions.REDUCE_VC]: XsollaMetrikaEventNames.CUSTOM_AMOUNT_MINUS,
  [AnalyticsActions.SUCCESS_ID]:
    XsollaMetrikaEventNames.USER_ID_PARTNER_METHOD_VALIDATION_SUCCESS,
  [AnalyticsActions.INPUT_CODE]: XsollaMetrikaEventNames.FOCUS_PROMOCODE,
  [AnalyticsActions.CONFIRM_CODE]: XsollaMetrikaEventNames.REDEEM_PROMOCODE,
  [AnalyticsActions.REMOVE_CODE]: XsollaMetrikaEventNames.DELETE_PROMOCODE,
  [AnalyticsActions.ERROR_CODE]: XsollaMetrikaEventNames.ERROR_PROMOCODE,
  [AnalyticsActions.DISCOUNT_CART]: XsollaMetrikaEventNames.CART_SALE_PROMOCODE,
  [AnalyticsActions.DISCOUNT_ITEM]: XsollaMetrikaEventNames.ITEM_SALE_PROMOCODE,
  [AnalyticsActions.ADD_BONUS]: XsollaMetrikaEventNames.ADD_ITEM_PROMOCODE,
  [AnalyticsActions.SELECT_DRM]: XsollaMetrikaEventNames.CHOOSE_DRM_PROMOCODE,
  [AnalyticsActions.AUTH_SUCCESS]: XsollaMetrikaEventNames.AUTH_SUCCESS,
  [AnalyticsActions.CLOSE_CART]: XsollaMetrikaEventNames.CLOSE_CART,
  [AnalyticsActions.CLOSE_MODAL_USER_ID]:
    XsollaMetrikaEventNames.CLOSE_MODAL_USER_ID,
  [AnalyticsActions.USER_ID_PARTNER_METHOD_VALIDATION_START]:
    XsollaMetrikaEventNames.USER_ID_PARTNER_METHOD_VALIDATION_START,
  [AnalyticsActions.CLOSE_USER_ID_INSTRUCTION]:
    XsollaMetrikaEventNames.CLOSE_USER_ID_INSTRUCTION,
  [AnalyticsActions.CLOSE_BUNDLE_MODAL]:
    XsollaMetrikaEventNames.CLOSE_BUNDLE_MODAL,
};

export enum XsollaMetrikaSocialLinkEventNames {
  DISCORD = 'sb_footer_social_click-discord',
  FACEBOOK = 'sb_footer_social_click-facebook',
  GAMEPEDIA = 'sb_footer_social_click-gamepedia',
  INSTAGRAM = 'sb_footer_social_click-instagram',
  KICKSTARTER = 'sb_footer_social_click-kickstarter',
  TWITCH = 'sb_footer_social_click-twitch',
  TWITTER = 'sb_footer_social_click-twitter',
  VK = 'sb_footer_social_click-vk',
  WECHAT = 'sb_footer_social_click-wechat',
  YOUTUBE = 'sb_footer_social_click-youtube',
}

export const EventForGA = {
  [AnalyticsActions.CLOSE_CART]: AnalyticsActions.CLOSE,
  [AnalyticsActions.CLOSE_MODAL_USER_ID]: AnalyticsActions.CLOSE,
  [XsollaMetrikaEventNames.BUNDLE_BUTTON]: AnalyticsActions.CLICK_BUY,
  [XsollaMetrikaEventNames.SKU_BUTTON]: AnalyticsActions.CLICK_BUY,
  [XsollaMetrikaEventNames.SUBSCRIPTION_BUTTON]: AnalyticsActions.CLICK_BUY,
  [XsollaMetrikaSocialLinkEventNames.DISCORD]: AnalyticsActions.SOCIAL,
  [XsollaMetrikaSocialLinkEventNames.FACEBOOK]: AnalyticsActions.SOCIAL,
  [XsollaMetrikaSocialLinkEventNames.GAMEPEDIA]: AnalyticsActions.SOCIAL,
  [XsollaMetrikaSocialLinkEventNames.INSTAGRAM]: AnalyticsActions.SOCIAL,
  [XsollaMetrikaSocialLinkEventNames.KICKSTARTER]: AnalyticsActions.SOCIAL,
  [XsollaMetrikaSocialLinkEventNames.TWITCH]: AnalyticsActions.SOCIAL,
  [XsollaMetrikaSocialLinkEventNames.TWITTER]: AnalyticsActions.SOCIAL,
  [XsollaMetrikaSocialLinkEventNames.VK]: AnalyticsActions.SOCIAL,
  [XsollaMetrikaSocialLinkEventNames.YOUTUBE]: AnalyticsActions.SOCIAL,
  [XsollaMetrikaSocialLinkEventNames.WECHAT]: AnalyticsActions.SOCIAL,
  [AnalyticsActions.OPEN_UID_WIDGET_FROM_BUY_NOW]:
    AnalyticsActions.OPEN_UID_WIDGET,
};
