import { Controller } from '@hotwired/stimulus';
import { createPopper, Instance, OptionsGeneric } from '@popperjs/core';
import throttle from 'lodash/throttle';

import { analyticsManager } from '../../afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../../afterBlocks/analytics/analyticsActions';
import { AnalyticsCategories } from '../../afterBlocks/analytics/analyticsCategories';
import {
  BonusItem,
  BonusItems,
  Rewards,
  StoreError,
} from '../../blocks/store/cart/Cart';
// @ts-ignore
import { GameKeyCartItem } from '../../blocks/store/cart/GameKeyCartItem';
import { GlobalUniversalCart } from '../../blocks/store/cart/GlobalUniversalCart';
import { UniversalCart } from '../../blocks/store/cart/UniversalCart';
import { toggleCartEmail } from '../../blocks/store/cartEmail';
import { translations } from '../../translations';

export enum ButtonTypes {
  APPLY = 'APPLY',
  REMOVE = 'REMOVE',
}

const buttonTranslationMap: Record<ButtonTypes, string> = {
  [ButtonTypes.APPLY]: translations['client.cart_modal.promo_codes.apply'],
  [ButtonTypes.REMOVE]: translations['client.cart_modal.promo_codes.remove'],
};

const errorMessagesTranslationMap: Record<number, string> = {
  9807: translations['client.cart_modal.promo_codes.error_incorrect'],
  9804: translations[
    'client.cart_modal.promo_codes.error_limit_has_been_reached'
  ],
  9810: translations['client.cart_modal.promo_codes.error_expired'],
  9809: translations['client.cart_modal.promo_codes.error_not_valid_for_items'],
};

const setListeners = (isEnabled: boolean) => (options: any) =>
  ({
    ...options,
    modifiers: [
      ...options.modifiers,
      { name: 'eventListeners', enabled: isEnabled },
    ],
  } as OptionsGeneric<any>);

const getTooltipPlacement = () => {
  const { matches } = window.matchMedia('(max-width: 575px)');
  return matches ? 'top-start' : 'bottom-start';
};

const sendAnalyticsEventByRewardsResponse = (
  rewardsResponse: Rewards,
  afterRedeem = true
) => {
  if (afterRedeem) {
    if (rewardsResponse.discount.percent) {
      analyticsManager.sendEvent({
        category: AnalyticsCategories.CART,
        event: AnalyticsActions.DISCOUNT_CART,
        label: rewardsResponse.discount.percent,
        page: 'landing_cart_discount-cart-promocode',
      });
    }
    if (rewardsResponse.discounted_items) {
      analyticsManager.sendEvent({
        category: AnalyticsCategories.CART,
        event: AnalyticsActions.DISCOUNT_ITEM,
        label: rewardsResponse.discounted_items[0].discount.percent,
        page: 'landing_cart_discount-item-promocode',
      });
    }
  } else if (rewardsResponse.bonus?.length) {
    rewardsResponse.bonus.forEach((bonus) => {
      analyticsManager.sendEvent({
        category: AnalyticsCategories.CART,
        event: AnalyticsActions.ADD_BONUS,
        label: bonus.item.sku,
        page: 'landing_cart_add-bonus-promocode',
      });
    });
  }
};

class CartModalPromoController extends Controller {
  buttonTypeValue: ButtonTypes = ButtonTypes.APPLY;

  buttonIsLoadingValue = false;

  inputIsDisabledValue = false;

  #cart?: UniversalCart;

  private tooltip!: Instance;

  get cart() {
    if (!this.#cart) {
      this.#cart = GlobalUniversalCart.instance;
    }
    return this.#cart;
  }

  static targets = [
    'promoInput',
    'button',
    'buttonText',
    'tooltip',
    'tooltipContent',
  ];

  static values = {
    buttonType: { type: String, default: ButtonTypes.APPLY },
    buttonIsLoading: { type: Boolean, default: false },
    inputIsDisabled: { type: Boolean, default: false },
  };

  private getTarget<T extends Element = Element>(targetName: string) {
    const target = this.targets.find(targetName) as T;
    if (!target) {
      throw new Error('Something went wrong');
    }
    return target;
  }

  get promoInputTarget() {
    return this.getTarget<HTMLInputElement>('promoInput');
  }

  get tooltipTarget() {
    return this.getTarget<HTMLDivElement>('tooltip');
  }

  get tooltipContentTarget() {
    return this.getTarget<HTMLDivElement>('tooltipContent');
  }

  get goToCheckoutButton() {
    const goToCheckoutButton = document.querySelector<HTMLButtonElement>(
      '.ui-site-cart-modal__checkout-button'
    );

    if (!goToCheckoutButton) {
      throw new Error('Something went wrong');
    }

    return goToCheckoutButton;
  }

  connect() {
    const { promocode } = this.cart;
    if (!promocode) {
      return;
    }
    this.promoInputTarget.value = promocode;
    this.buttonTypeValue = ButtonTypes.REMOVE;
    this.inputIsDisabledValue = true;

    this.cart.addListener(() => {
      if (this.cart.items.length) {
        return;
      }

      return this.cart.promocode && this.removePromocode();
    });
  }

  tooltipTargetConnected(tooltipElement: HTMLDivElement) {
    this.tooltip = createPopper(this.promoInputTarget, tooltipElement, {
      placement: getTooltipPlacement(),
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 12],
          },
        },
        {
          name: 'computeStyles',
          options: {
            gpuAcceleration: false,
          },
        },
      ],
    });

    return this.tooltip.setOptions(setListeners(false));
  }

  tooltipTargetDisconnected() {
    return this.hideTooltip();
  }

  promoInputTargetConnected(input: HTMLInputElement) {
    input.placeholder =
      translations['client.cart_modal.promo_codes.placeholder'];
  }

  private updateTooltip = throttle(() => {
    return this.tooltip.setOptions({ placement: getTooltipPlacement() });
  }, 200);

  private showTooltip = async (text: string) => {
    await this.hideTooltip();
    this.tooltipContentTarget.textContent = text;
    this.tooltipTarget.setAttribute('data-show', '');

    // See tutorial https://popper.js.org/docs/v2/tutorial/#performance
    await this.tooltip.setOptions(setListeners(true));
    await this.tooltip.update();
    this.addTooltipEventListeners();
  };

  private hideTooltip = async () => {
    this.tooltipContentTarget.textContent = '';
    this.tooltipTarget.removeAttribute('data-show');

    // See tutorial https://popper.js.org/docs/v2/tutorial/#performance
    await this.tooltip.setOptions(setListeners(false));
    this.removeTooltipEventListeners();
  };

  private addTooltipEventListeners() {
    window.addEventListener('resize', this.updateTooltip);
    document.addEventListener('click', this.hideTooltip);
    document.addEventListener('scroll', this.hideTooltip);
  }

  private removeTooltipEventListeners() {
    window.removeEventListener('resize', this.updateTooltip);
    document.removeEventListener('click', this.hideTooltip);
    document.removeEventListener('scroll', this.hideTooltip);
  }

  buttonTypeValueChanged(value: ButtonTypes) {
    const buttonTextElement = this.getTarget('buttonText');
    buttonTextElement.textContent = buttonTranslationMap[value];
  }

  buttonIsLoadingValueChanged(value: boolean) {
    const buttonElement = this.getTarget<HTMLButtonElement>('button');
    buttonElement.disabled = value;

    if (value) {
      buttonElement.setAttribute('data-loading', '');
      return;
    }

    buttonElement.removeAttribute('data-loading');
  }

  focusPromoCodeInput() {
    analyticsManager.sendEvent({
      category: AnalyticsCategories.CART,
      event: AnalyticsActions.INPUT_CODE,
      page: 'landing_cart_input-promocode-start',
    });
  }

  handleButtonClick() {
    if (!this.promoInputTarget.value) {
      return;
    }
    this.buttonIsLoadingValue = true;
    this.inputIsDisabledValue = true;
    switch (this.buttonTypeValue) {
      case ButtonTypes.APPLY: {
        return this.applyPromocode();
      }
      case ButtonTypes.REMOVE: {
        return this.removePromocode();
      }
      default: {
        throw new Error('Something went wrong');
      }
    }
  }

  inputIsDisabledValueChanged(value: boolean) {
    const promoInput = this.getTarget<HTMLInputElement>('promoInput');
    promoInput.disabled = value;
  }

  private async applyPromocode() {
    analyticsManager.sendEvent({
      category: AnalyticsCategories.CART,
      event: AnalyticsActions.CONFIRM_CODE,
      page: 'landing_cart_input-promocode-confirm',
    });

    const promocode = this.promoInputTarget.value;

    if (!promocode) {
      return;
    }

    const rewardsResponse = await this.cart.rewardPromocode(promocode);

    if ('errorCode' in rewardsResponse) {
      return this.handleError(rewardsResponse);
    }

    sendAnalyticsEventByRewardsResponse(rewardsResponse, false);
    if (rewardsResponse.is_selectable) {
      return this.handleError({ errorCode: 9807, errorMessage: '' });
    }
    await this.redeemPromocode(promocode);
    this.buttonTypeValue = ButtonTypes.REMOVE;
    this.buttonIsLoadingValue = false;
    sendAnalyticsEventByRewardsResponse(rewardsResponse);
  }

  private async redeemPromocode(
    promocode: string,
    selectedUnitItems?: Record<string, string>
  ) {
    const redeemResponse = await this.cart.redeemPromocode(
      promocode,
      selectedUnitItems
    );
    if ('errorCode' in redeemResponse) {
      return this.handleError(redeemResponse);
    }

    this.goToCheckoutButton.disabled = false;

    const cartModal =
      document.querySelector<HTMLDivElement>('[data-cart-modal]');

    toggleCartEmail(cartModal, this.cart);
  }

  checkTemporaryItems() {
    const promocode = this.promoInputTarget.value;

    if (!promocode) {
      return;
    }

    if (this.cart.hasTemporaryItemsWithoutDRM()) {
      return;
    }

    const selectedUnitItems = this.cart.items
      .filter((item) => item.isTemporaryItem)
      .reduce(
        (
          acc: Record<string, string>,
          { sku, selectedDRM }: { sku: string; selectedDRM: string }
        ) => ({
          ...acc,
          [sku]: selectedDRM,
        }),
        {}
      );

    return this.redeemPromocode(promocode, selectedUnitItems);
  }

  private async removePromocode() {
    analyticsManager.sendEvent({
      category: AnalyticsCategories.CART,
      event: AnalyticsActions.REMOVE_CODE,
      page: 'landing_cart_remove-promocode',
    });

    const promoInput = this.targets.find('promoInput') as HTMLInputElement;

    const response = await this.cart.cancelRedeemPromocode();
    if ('errorCode' in response) {
      return this.handleError(response);
    }
    this.buttonTypeValue = ButtonTypes.APPLY;
    this.buttonIsLoadingValue = false;
    this.inputIsDisabledValue = false;
    promoInput.value = '';
  }

  private async handleError(error: StoreError) {
    const { errorCode } = error;
    await this.showTooltip(
      errorMessagesTranslationMap[errorCode] ??
        translations['client.cart_modal.promo_codes.error_could_not_apply']
    );
    this.inputIsDisabledValue = false;
    this.buttonIsLoadingValue = false;
    analyticsManager.sendEvent({
      category: AnalyticsCategories.CART,
      event: AnalyticsActions.ERROR_CODE,
      label: String(errorCode),
      page: 'landing_cart_error-promocode',
    });
  }

  private addSelectableBonus(items: BonusItems) {
    type CartItemPrice = {
      amount: number;
      amountWithoutDiscount: number;
      currency: string;
    };

    type GameKeyCartItemProps = {
      unitItems: BonusItem['item']['unit_items'];
      selectedDRM?: string;
      drmName?: string;
      type: string;
      sku: string;
      quantity: number;
      price: CartItemPrice | null; // no price for bonus
      name: string;
      imageUrl: string | null;
      isBonus: boolean;
      isTemporaryItem?: boolean;
    };

    items.forEach(
      ({
        item: { unit_items: unitItems, type, sku, name, image_url: imageUrl },
        quantity,
      }) => {
        const props: GameKeyCartItemProps = {
          unitItems,
          type,
          sku,
          quantity,
          name,
          imageUrl,
          isBonus: true,
          price: null,
          isTemporaryItem: true,
        };

        this.cart.addItem(new GameKeyCartItem(props));
      }
    );

    return this.showTooltip(
      translations['client.cart_modal.promo_codes.error_select_game_platform']
    );
  }
}

export default CartModalPromoController;
