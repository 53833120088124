import { AbstractBlock } from '../blocks';
import { FiveSymbolLocale } from '../locale';
import {
  ImageValue,
  AbstractLocalizedValue,
  AbstractLocalizedString,
  UserInstruction,
} from '../values';

export interface ShareOptionsType {
  facebookLike: boolean;
  facebookShare: boolean;
  tweet: boolean;
}

export interface WidgetsType {
  languagesSelectIsShow: boolean;
  translationsLocation: 'footer' | 'header' | 'both';
  shareOptions: {
    enable: boolean;
    values: ShareOptionsType;
  };
}

export type SimpleValueType = {
  [key: string]: string;
} & { enable: boolean };

export interface PageThemeType {
  pictureBackground: {
    color: string;
    enable: boolean;
    img: string;
    size: string;
  };
  videoBackground: {
    enable: boolean;
    video: string;
  };
}

export interface PageSeoType {
  title: AbstractLocalizedString;
  description: AbstractLocalizedString;
  ogTitle: AbstractLocalizedString;
  ogDescription: AbstractLocalizedString;
  ogImage: AbstractLocalizedString;
  indexable: boolean;
}

export interface LandingThemeType {
  accentColor: string;
  overlayColor: string;
  backgroundBlur: number;
  buttonBorderRadius: number;
  buttonTextColor: string;
  borderColor: string;
  secondaryColor: string;
  headerFont: string;
  mainFont: string;
  textColor: string;
}

export interface LandingSeoType {
  favicon: string;
}

export enum AuthorizationTypes {
  USER_ID = 'user-id',
  WEBHOOK = 'webhook',
  LOGIN = 'login',
}

export interface Authorization {
  logo: ImageValue;
  title: AbstractLocalizedValue;
  background: ImageValue;
  instruction: UserInstruction;
  validation: {
    url: string;
  };
  type: AuthorizationTypes;
  loginId: string;
  isSetSiteStyle: boolean;
}

export interface CartSettings {
  enable: boolean;
}

export interface DefaultPage {
  auth: Authorization;
  blocks: AbstractBlock[];
  seo: PageSeoType;
  theme: PageThemeType;
  cart: CartSettings;
  url: string;
  _id: string;
}

export enum ChangeablePageSettings {
  AUTH = 'auth',
  SEO = 'seo',
  THEME = 'theme',
  CART = 'cart',
}

export interface AppsType {
  gtm: string;
  googleAnalytics?: string;
  facebookPixel?: string;
  forum?: any;
  twitterPixel?: string;
  googleOptimize?: string;
}

export enum LandingTypes {
  CFD = 'cfd',
  GPLAY = 'gplay',
  RFP = 'rfppage',
  SELLING_PAGE = 'sellingpage',
  STEAM = 'steam',
  WEBSTORE = 'store',
  TOPUP = 'topup',
}

export interface Landing {
  analyticsCounterId: number;
  apps: AppsType;
  created: number;
  domain: string;
  externalDomains: any[];
  languages: FiveSymbolLocale[];
  linkPreview?: string;
  linkPublication?: string;
  merchantId: string;
  pages: DefaultPage[];
  projectId: string;
  published: number;
  seo: LandingSeoType;
  theme: LandingThemeType;
  type: LandingTypes;
  updated: number;
  userModified: {
    email: string;
    name: string;
  };
  userPublished: {
    email: string;
    name: string;
  };
  version: number;
  widgets: WidgetsType;
  _id: any;
  id: string;
  site?: {
    parent: Landing;
    path: string;
  };
}

export type LandingWithStructure = { pages: DefaultPage[] } & Landing;
