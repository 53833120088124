import { analyticsManager, DataLayer } from './analytics';

export default data => {
  const { gtm } = data;
  if (!gtm) {
    return true;
  }
  const script = 'script';
  const dataLayer = 'dataLayer';

  window[dataLayer] = window[dataLayer] || [];
  window[dataLayer].push({
    'gtm.start': new Date().getTime(),
    event: 'gtm.js'
  });
  const f = document.getElementsByTagName(script)[0];
  const j = document.createElement(script);
  j.async = true;
  j.src = `https://www.googletagmanager.com/gtm.js?id=${gtm}&l=dataLayer`;
  if (f.parentNode) {
    f.parentNode.insertBefore(j, f);
  }
  analyticsManager.subscribe(new DataLayer(window[dataLayer]));
  return true;
};
