/**
 * @prettier
 * @flow
 */

import React from 'react';

import type { CartItemTemplateProps } from '../blocks/store/types';

import CartItem from './CartItem';

type Props = {
  cartItemsProps: CartItemTemplateProps[],
};

const CartItemList = ({ cartItemsProps }: Props) =>
  cartItemsProps.map<any>((itemProps) => (
    <CartItem
      key={`${itemProps.sku}-${itemProps.selectedDRM}`}
      {...itemProps}
    />
  ));

export default CartItemList;
