/** @prettier */
// @flow
import { getUserLoginAttributes } from '../../../utils/api';
import { ObjectObserver } from '../../../utils/class-helper';
import { parseJwt, getTokenFromCookie } from '../../helpers/common';
import { getStoreTopUpGameSku } from '../../helpers/customCodeSettings';

export type IUserInfo = {
  id?: string | number,
  level?: string | number,
  name?: string,
};

export const UserIdInputStatus: {
  VALID: 'VALID',
  VALIDATION_PASSED: 'VALIDATION_PASSED',
  NOT_FOUND: 'NOT_FOUND',
  INVALID: 'INVALID',
  LOADING: 'LOADING',
  CLEAR: 'CLEAR',
} = {
  VALID: 'VALID',
  VALIDATION_PASSED: 'VALIDATION_PASSED',
  NOT_FOUND: 'NOT_FOUND',
  INVALID: 'INVALID',
  LOADING: 'LOADING',
  CLEAR: 'CLEAR',
};

export class TopupUserService {
  _inputValue: string = '';

  _userInfo: IUserInfo = {};

  _status: $Values<typeof UserIdInputStatus> = UserIdInputStatus.CLEAR;

  async setInputValueFromToken(projectId: string) {
    const userToken = getTokenFromCookie();
    const userData = parseJwt(userToken);
    const gameSKU = getStoreTopUpGameSku();
    if (!gameSKU || !userData) {
      return;
    }
    const attributeName = `${gameSKU}_userID`;
    const { data } = await getUserLoginAttributes({
      keys: [attributeName],
      projectId: Number(projectId),
      userId: userData.sub,
      userToken,
    });

    if (data && data.error) {
      return;
    }

    const attribute = data.find((attribute) => attribute.key === attributeName);
    if (!attribute) {
      return;
    }
    this.inputValue = attribute.value || '';
    this.status = this.inputValue
      ? UserIdInputStatus.VALID
      : UserIdInputStatus.CLEAR;
  }

  get inputValue() {
    return this._inputValue;
  }

  set inputValue(value: string) {
    this._inputValue = value;
  }

  get userInfo() {
    return this._userInfo;
  }

  set userInfo(value: IUserInfo) {
    this._userInfo = value;
  }

  get status() {
    return this._status;
  }

  set status(value: $Values<typeof UserIdInputStatus>) {
    this._status = value;
  }
}

// Singleton -> ObjectObserver -> TopupUserService
const userIdService = new TopupUserService();
export const topupUserServiceObserver = new ObjectObserver(userIdService);
