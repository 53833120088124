/** @prettier */
import { subscriptionsService } from '../actions/subscriptions';

export const activateJoinButton = () => {
  const buttons = document.querySelectorAll('.blur-block__button');
  if (!buttons || !buttons.length) {
    return false;
  }
  const packs = document.querySelectorAll('.packs');
  if (!packs || !packs.length) {
    return false;
  }
  const [element] = packs;
  buttons.forEach((button) => {
    button.addEventListener('click', () => {
      element.scrollIntoView({ behavior: 'smooth' });
    });
  });
  return true;
};

export const setTextToBlurBlock = (translations) => {
  [...document.querySelectorAll('.blur-block')].forEach((node) => {
    const textNode = node.querySelector('.blur-block__text');
    if (textNode) {
      const plans = textNode.getAttribute('data-subscription-names') || '';
      textNode.innerText = translations[
        'client.news.blurred.description'
      ].replace('{subscription_name}', plans);
    }

    const btn = node.querySelector('.blur-block__button .simple-button__text');
    if (btn) {
      const span = document.createElement('span');
      span.innerText = translations['client.news.blurred.button.join'];
      btn.append(span);
    }
  });
};

export const unShowBlurBlock = () => {
  const blurWrappers = [
    ...document.querySelectorAll(
      '.blur-block-wrapper.blur-block-wrapper--show'
    ),
  ];
  const activeSubscription = subscriptionsService.userSubscriptionsInfo.filter(
    ({ status }) => status !== null
  );
  if (activeSubscription) {
    blurWrappers.forEach((wrapper) => {
      const subIdsString =
        wrapper
          .querySelector('.blur-block')
          .getAttribute('data-subscription-ids') || '';
      const subIds = subIdsString ? subIdsString.split(',') : [];
      /* eslint-disable camelcase */
      if (
        activeSubscription.find(({ plan_external_id }) =>
          subIds.includes(plan_external_id)
        )
      ) {
        wrapper.classList.remove('blur-block-wrapper--show');
        wrapper.classList.add('blur-block-wrapper--unshow');
      }
      /* eslint-enable camelcase */
    });
  }
};
