/**
 * @prettier
 */

import { Application } from '@hotwired/stimulus';

import CartModalPromoController from './controllers/CartModalPromoController';

const stimulus = Application.start();

export const initStimulusApplication = () => {
  stimulus.register('cart-modal-promo', CartModalPromoController);
};

export default stimulus;
