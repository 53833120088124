import axios from 'axios';

import {
  AIRTABLE_API_KEY, BASE_URL, LOGIN_API, STORE_API, LAUNCHER_API
} from '../config';
import requestErrorHandler from './request-error-handler';
import { getSitebuilderURL } from './url-helper';


export const sessionStart = async ({
  domain
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/session/${domain}`,
  method: 'post',
  withCredentials: true
}));

export const getStructure = async ({
  merchantId, projectId, domain, data = {}
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/structure`,
  method: 'get',
  withCredentials: true,
  data
}));

export const createStructure = async ({
  merchantId, projectId, domain, data
}) => await axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/structure`,
  method: 'post',
  withCredentials: true,
  data
});

export const publicationLanding = async ({
  merchantId, projectId, domain
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/publication`,
  method: 'post',
  withCredentials: true
}));

export const checkParsingLanding = async ({
  merchantId, projectId, domain, parserName, inputValue
}) => await axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/parsing?type=${parserName}&target=${inputValue}`,
  method: 'get',
  withCredentials: true
});

export const saveBlock = async ({
  merchantId, projectId, landingId, data
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/saveBlock`,
  method: 'put',
  withCredentials: true,
  data
}));

export const deletePageBlock = async ({
  merchantId, projectId, landingId, pageId, blockId
}) => await axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/page/${pageId}/block`,
  method: 'delete',
  withCredentials: true,
  data: { blockId }
});

export const duplicatePageBlock = async ({
  merchantId, projectId, landingId, pageId, data
}) => await axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/page/${pageId}/block/duplicate`,
  method: 'post',
  withCredentials: true,
  data
});

export const movePageBlock = async ({
  merchantId, projectId, landingId, pageId, data
}) => await axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/page/${pageId}/block`,
  method: 'put',
  withCredentials: true,
  data
});

export const addPageBlock = async ({
  merchantId, projectId, landingId, pageId, data
}) => await axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/page/${pageId}/block`,
  method: 'post',
  withCredentials: true,
  data
});

export const getAvailableComponents = async ({
  merchantId, projectId, landingId
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/components`,
  method: 'get',
  withCredentials: true
}));

export const getSKU = async ({
  merchantId, projectId, landingId
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/sku`,
  method: 'get',
  withCredentials: true
}));

export const getFeatures = async ({
  merchantId
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/features`,
  method: 'get',
  withCredentials: true
}));

export const saveLandingValue = async ({
  merchantId, projectId, landingId, data
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/savelandingsettings`,
  method: 'put',
  withCredentials: true,
  data
}));

export const saveApplication = async ({
  merchantId, projectId, domain, data
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/apps`,
  method: 'put',
  withCredentials: true,
  data
}));

export const deleteApplication = async ({
  merchantId, projectId, domain, data
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/apps`,
  method: 'delete',
  withCredentials: true,
  data
}));

export const savePageValue = async ({
  merchantId, projectId, landingId, pageId, data
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/page/${pageId}/savepagesettings`,
  method: 'put',
  withCredentials: true,
  data
}));

export const getImages = async ({
  merchantId, projectId, collectionId, collectionName = 'site' // collectionId == document._id
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/assets/${collectionId}/${collectionName}`,
  method: 'get',
  withCredentials: true
}));

export const uploadImage = async ({
  merchantId, projectId, collectionId, data, collectionName = 'site'
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/assets/${collectionId}/${collectionName}`,
  method: 'post',
  withCredentials: true,
  data
}));

export const deleteImage = async ({
  merchantId, projectId, collectionId, assetId
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/assets/${collectionId}/${assetId}`,
  method: 'delete',
  withCredentials: true
}));

export const getLogins = async ({
  merchantId
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/login/projects?merchant_id=${merchantId}`,
  method: 'get',
  withCredentials: true
}));

export const createLogin = async ({
  merchantId, data
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/login/projects?merchant_id=${merchantId}`,
  method: 'post',
  withCredentials: true,
  data
}));

export const addLanguageToLanding = async ({
  merchantId, projectId, domain, data
}) => await axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/language`,
  method: 'post',
  withCredentials: true,
  data
});

export const deleteLanguageFromLanding = async ({
  merchantId, projectId, domain, data
}) => await axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/landing/${domain}/language`,
  method: 'delete',
  withCredentials: true,
  data
});

export const getLauncherList = async ({
  merchantId, projectId, landingId
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/launcherList`,
  method: 'get',
  withCredentials: true
}));

export const getSubscriptionPlans = async ({
  merchantId, projectId, landingId
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/subscriptionPlans`,
  method: 'get',
  withCredentials: true
}));

export const getProjectInfo = async ({
  merchantId, projectId, landingId
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/projectInfo`,
  method: 'get',
  withCredentials: true
}));

export const updateProjectInfo = async ({
  merchantId, projectId, landingId, requestBody
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/projectInfo`,
  method: 'put',
  data: requestBody,
  withCredentials: true
}));

export const updateProjectTokenlessIntegration = async ({
  merchantId, projectId, landingId, tokenlessIntegration
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/projectTokenlessInfo`,
  method: 'put',
  data: { tokenlessIntegration },
  withCredentials: true
}));

export const toggleStoreComponent = async ({
  merchantId, projectId, landingId, componentName
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/toggleStoreComponent`,
  method: 'put',
  data: { componentName },
  withCredentials: true
}));

export const createNews = async ({
  launcherId, merchantId, landingId, data
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/launcher/${launcherId}/merchant/${merchantId}/landing/${landingId}/constructor/news`,
  method: 'post',
  withCredentials: true,
  data
}));

export const getNews = async ({
  launcherId, offset = 0, limit = 6
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/launcher/${launcherId}/constructor/news?offset=${offset}&limit=${limit}`,
  method: 'get',
  withCredentials: true
}));

export const updateNews = async ({
  launcherId, merchantId, landingId, newsId, data
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/launcher/${launcherId}/merchant/${merchantId}/landing/${landingId}/constructor/news/${newsId}`,
  method: 'put',
  withCredentials: true,
  data
}));

export const deleteNews = async ({
  launcherId, merchantId, landingId, newsId
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/launcher/${launcherId}/merchant/${merchantId}/landing/${landingId}/constructor/news/${newsId}`,
  method: 'delete',
  withCredentials: true
}));

export const getStoreItemsGroups = async ({
  merchantId, projectId, landingId, locale
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/store/virtualItems?locale=${locale}`,
  method: 'get',
  withCredentials: true
}));

export const getStoreItemsByGroup = async ({
  merchantId, projectId, landingId, locale, groupId
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/store/virtualItems/${groupId}?locale=${locale}`,
  method: 'get',
  withCredentials: true
}));

export const getStoreVirtualCurrencies = async ({
  merchantId, projectId, landingId, locale
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/store/virtual_currency?locale=${locale}`,
  method: 'get'
}));

export const getStoreVirtualCurrencyPackages = async ({
  merchantId, projectId, landingId, locale
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/store/virtual_currency/package?locale=${locale}`,
  method: 'get'
}));

export const getStoreGames = async ({
  merchantId, projectId, landingId, locale
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/store/games?locale=${locale}`,
  method: 'get'
}));

export const getAdminStoreGames = async ({
  merchantId, projectId, landingId, locale
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/store/admin/games?locale=${locale}`,
  method: 'get'
}));

export const getStoreBundles = async ({
  projectId, locale
}) => await requestErrorHandler(axios({
  baseURL: STORE_API,
  url: `/v2/project/${projectId}/items/bundle?locale=${locale}`,
  method: 'get'
}));

export const requestGetRetailersData = async (tableName, databaseName, recordOffset = '') => {
  const sorting = 'sort[0][field]=pack&sort[1][field]=retailer';
  const options = {
    method: 'GET',
    url: `https://api.airtable.com/v0/${databaseName}/${tableName}?${sorting}${recordOffset ? `&offset=${recordOffset}` : ''}`,
    headers: {
      Authorization: `Bearer ${AIRTABLE_API_KEY}`
    }
  };

  const result = await requestErrorHandler(axios(options));
  if (result.status !== 200) {
    return [];
  }

  const { offset, records } = result.data;
  let allRecords = records;
  if (offset) {
    const addResult = await requestGetRetailersData(tableName, databaseName, offset);
    allRecords = allRecords.concat(addResult);
  }

  return allRecords;
};

const handleStoreRequestBySKU = async req => {
  const response = await requestErrorHandler(req);
  if (response.status !== 200) {
    return null;
  }
  return response.json();
};

export const getGameKeyBySKU = async (projectId, locale, sku) => {
  const req = fetch(`${STORE_API}/v2/project/${projectId}/items/game/sku/${sku}?locale=${locale}`, {
    method: 'GET'
  });

  return await handleStoreRequestBySKU(req);
};

export const getBundleBySKU = async (projectId, locale, sku) => {
  const req = fetch(`${STORE_API}/v2/project/${projectId}/items/bundle/sku/${sku}?locale=${locale}`, {
    method: 'GET'
  });

  return await handleStoreRequestBySKU(req);
};

export const getVirtualItemBySKU = async (projectId, locale, sku) => {
  const req = fetch(`${STORE_API}/v2/project/${projectId}/items/virtual_items/sku/${sku}?locale=${locale}`, {
    method: 'GET'
  });

  return await handleStoreRequestBySKU(req);
};

export const getVirtualCurrencyPackBySKU = async (projectId, locale, sku) => {
  const req = fetch(`${STORE_API}/v2/project/${projectId}/items/virtual_currency/package/sku/${sku}?locale=${locale}`, {
    method: 'GET'
  });

  return await handleStoreRequestBySKU(req);
};

export const getNewsCategories = async ({
  launcherId
}) => {
  const req = axios(`${LAUNCHER_API}/projects/${launcherId}/news_categories`, {
    method: 'GET'
  });
  const response = await requestErrorHandler(req);
  return response;
};

export const getOrderAccessToken = async ({
  environment,
  merchantId,
  projectId,
  data
}) => await requestErrorHandler(axios({
  baseURL: `${getSitebuilderURL(environment)}/api`,
  url: `/merchant/${merchantId}/project/${projectId}/token/order`,
  method: 'post',
  data
}));

export const getUserLoginAttributes = async ({
  keys,
  projectId,
  userId,
  userToken
}) => await axios({
  baseURL: LOGIN_API,
  url: '/attributes/users/me/get',
  method: 'post',
  headers: {
    Authorization: `Bearer ${userToken}`
  },
  data: {
    keys,
    publisher_project_id: projectId,
    user_id: userId
  }
});

export const topupPartnerMethod = ({
  projectId,
  merchantId,
  country,
  method
}) => async id => await axios.post(
  method,
  {
    user: {
      id,
      country
    },
    settings: {
      project_id: Number(projectId),
      merchant_id: Number(merchantId)
    }
  },
);

export const getLoginWidgetConfiguration = async ({
  loginId
}) => await requestErrorHandler(axios({
  baseURL: BASE_URL,
  url: `/login/configuration/${loginId}`,
  method: 'get',
  withCredentials: true
}))

export const loadProducts = async ({
  merchantId,
  projectId,
  landingId,
  data,
  productsLimit,
  offset
}) => await axios({
  baseURL: BASE_URL,
  url: `/merchant/${merchantId}/project/${projectId}/ui/${landingId}/subscriptionProducts?limit=${productsLimit}&offset=${offset}&project_id[]=${data.project_id}`,
  method: 'GET'
});
