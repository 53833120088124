let imageCompression: any = null;
// Avoid requiring these files in SSR
if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  imageCompression = require('browser-image-compression').default;
}

const imageCompress = async (image: File): Promise<File> => {
  let result = image;

  const options = {
    maxSizeMB: 10,
    maxWidthOrHeight: 1920,
    useWebWorker: false,
  };

  if (imageCompression && ['image/png', 'image/jpeg'].includes(image.type)) {
    try {
      result = await imageCompression(image, options);
      if (result.size > image.size) {
        result = image;
      }
    } catch (error) {
      result = image;
    }
  }

  return result;
};

export default imageCompress;
