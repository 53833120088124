"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateYoutubeVideoUrl = exports.validateVimeoUrl = exports.validateVideo = exports.validateSteamVideoUrl = exports.getYouTubeId = exports.getVideoId = exports.getSteamVideoId = void 0;

const isYoutubeUrl = url => url.match(/^https?:\/\/(?:www\.)?(youtu\.be|youtube\.com)/);

const getYouTubeId = url => {
  const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const match = url.match(p);
  return match ? match[1] : '';
};

exports.getYouTubeId = getYouTubeId;

const getSteamVideoId = url => {
  const p = /\d+(?!%)(.)(?=\/movie)/g;
  const match = url.match(p);
  return match ? match[0] : false;
};

exports.getSteamVideoId = getSteamVideoId;

const getVideoId = url => getYouTubeId(url) || getSteamVideoId(url);

exports.getVideoId = getVideoId;

const validateYoutubeVideoUrl = url => !!getYouTubeId(url);

exports.validateYoutubeVideoUrl = validateYoutubeVideoUrl;

const validateSteamVideoUrl = url => !!getSteamVideoId(url);

exports.validateSteamVideoUrl = validateSteamVideoUrl;

const validateVimeoUrl = url => /^(?:https?:\/\/)?(www\.)?vimeo.com\/(\d+)\/?$/.test(url);

exports.validateVimeoUrl = validateVimeoUrl;

const validateVideo = url => {
  if (isYoutubeUrl(url)) {
    return !!getYouTubeId(url);
  }

  return !!getSteamVideoId(url);
};

exports.validateVideo = validateVideo;