export class ObjectObserver {
  _target: Record<string | symbol, any>;

  _listeners: (() => void)[] = [];

  constructor(target: any) {
    this._target = target;
  }

  get target() {
    const { _listeners } = this;
    return new Proxy(this._target, {
      set(target, property, value) {
        target[property] = value;
        _listeners.forEach((listener) => listener());
        return true;
      },
    });
  }

  addListener(fn: () => void) {
    this._listeners.push(fn);
    return () => {
      this._listeners = this._listeners.filter((listener) => listener !== fn);
    };
  }
}
