// @flow
import type { AbstractLocalizedValue, StepOfUserInstruction, UserInstruction } from '#common/flow-types/values';
import type { FiveSymbolLocale } from '#common/utils/locale';

import { getTranslateMessage } from '../../utils/translation';
import imageValue from './imageValue';
import textValue from './textValue';

export const getDescriptionText = ({
  languages = ['en-US']
}: {|
  languages: FiveSymbolLocale[]
|}): AbstractLocalizedValue => {
  const value: AbstractLocalizedValue = { enable: true };

  languages.forEach((language: FiveSymbolLocale) => {
    value[language] = getTranslateMessage(language, 'document.pages.auth.instruction.steps.description');
  });
  
  return value;
};

export const createStep = ({
  languages
}: {|
  languages: FiveSymbolLocale[]
|}): StepOfUserInstruction => ({
  description: getDescriptionText({ languages }),
  image: imageValue()
});

export default (params: {|
  instructionCutTextTag: string,
  languages: FiveSymbolLocale[],
  isAddedFirstStep: boolean
|}): UserInstruction => {
  const steps = [];
  if (params.isAddedFirstStep) {
    steps.push(createStep({ languages: params.languages }));
  }

  return {
    enable: false,
    instructionCutTextTag: textValue({
      languages: params.languages,
      textTag: params.instructionCutTextTag
    }),
    steps
  };
};
