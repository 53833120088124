/** @prettier */
// @flow
import type { IRenderMode } from '#common/flow-types/build';
import type { StoreXUserData } from '../../utils/types';
import type {
  CartIdParam,
  LocaleParam,
  ProjectIdParam,
} from '../helpers/common';

import { openPayStationWidget } from '../../utils/paystation/open-widget';
import { getPSTokenForCart } from '../../utils/paystation/paystation-token';
import { GlobalUniversalCart } from '../blocks/store/cart/GlobalUniversalCart';
import events from '../events';
import { closeModalWindow } from '../ui-components/editor/modalWindow';

export const clearCartAfterSuccessfulPurchase = async () => {
  const cartModal = document.querySelector('.ui-site-cart-modal');
  sessionStorage.setItem('isOrdered', '1');
  const cart = GlobalUniversalCart.instance;
  if (cartModal) {
    closeModalWindow(cartModal);
  }
  await cart.clearAll();
};

const handlePurchasePostMessageFromCart = async (event) => {
  try {
    const psData = JSON.parse(event.data);
    const { data = {} } = psData;
    const isPurchaseDone = data.state === 'status' && data.value === 'done';

    if (isPurchaseDone) {
      await clearCartAfterSuccessfulPurchase();
    }
  } catch (e) {
    //
  }
};

export const getPayStationPostMessages = () => {
  window.addEventListener('message', handlePurchasePostMessageFromCart);

  window.XPayStationWidget.on(window.XPayStationWidget.eventTypes.CLOSE, () => {
    window.removeEventListener('message', handlePurchasePostMessageFromCart);
    window.XPayStationWidget.off();
    if (sessionStorage.getItem('isOrdered') === '1') {
      sessionStorage.clear();
      // TODO: loader
      window.location.reload();
    }
  });
};

const renderCheckoutCart = async ({
  projectId,
  cartId,
  locale,
  zIndex,
  xUser,
  isAuth,
  renderMode,
  payStationReturnUrl,
}) => {
  const { userToken } = GlobalUniversalCart.instance;
  if (!userToken) {
    throw Error('userToken is undefined');
  }

  const token = await getPSTokenForCart({
    cartId,
    isAuth,
    locale,
    xUser,
    payStationReturnUrl,
    projectId,
    renderMode,
    userToken,
  });
  openPayStationWidget({
    token,
    zIndex,
    renderMode,
  });
  getPayStationPostMessages();
};

export const initCheckoutCartEventHandler = () => {
  window.addEventListener(
    events.cart.init,
    (event) => {
      const {
        projectId,
        locale,
        cartId,
        zIndex,
        xUser,
        isAuth,
        renderMode,
        payStationReturnUrl,
      } = event.detail;
      renderCheckoutCart({
        projectId,
        cartId,
        locale,
        zIndex,
        xUser,
        isAuth,
        renderMode,
        payStationReturnUrl,
      });
    },
    false
  );
};

export const handleCheckoutCart = ({
  projectId,
  cartId,
  locale,
  zIndex,
  xUser,
  isAuth,
  renderMode,
  payStationReturnUrl,
}: ProjectIdParam &
  CartIdParam &
  LocaleParam & {
    zIndex: number,
    xUser: StoreXUserData,
    isAuth: boolean,
    renderMode: IRenderMode,
    payStationReturnUrl: string,
  }) => {
  window.dispatchEvent(
    new CustomEvent(events.cart.init, {
      detail: {
        projectId,
        locale,
        cartId,
        zIndex,
        xUser,
        isAuth,
        renderMode,
        payStationReturnUrl,
      },
    })
  );
};
