import {
  createStore as createStoreManual,
  RootState,
} from '../reducers/site-builder-store';

declare global {
  interface Window {
    __PRELOADED_STATE__: RootState;
  }
}

const _store =
  typeof window !== 'undefined'
    ? createStoreManual(window.__PRELOADED_STATE__)
    : null;

export const getStore = () => _store as ReturnType<typeof createStoreManual>;
