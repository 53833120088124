/** @prettier */
/* eslint-disable */
import analyticsEvents from './analyticsEvents';
import { XSOLLA_TWITTER_PIXEL_ID } from '../../../config';
import { hideGdprConsent } from '../../landingAPI/hideGdprConsent';

export default (data) => {
  const { twitterPixel, projectId, domain } = data;

  !(function (e, t, n, s, u, a) {
    e.twq ||
      ((s = e.twq =
        function () {
          s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments);
        }),
      (s.version = '1.1'),
      (s.queue = []),
      (u = t.createElement(n)),
      (u.async = !0),
      (u.src = '//static.ads-twitter.com/uwt.js'),
      (a = t.getElementsByTagName(n)[0]),
      a.parentNode.insertBefore(u, a));
  })(window, document, 'script');
  if (!hideGdprConsent()) {
    window.twq('init', XSOLLA_TWITTER_PIXEL_ID);
  }
  if (twitterPixel) {
    window.twq('init', twitterPixel);
  }

  return true;
};
