"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RenderMode = void 0;
const RenderMode = {
  PREVIEW: 'preview',
  PUBLICATION: 'publication',
  EDITING: 'editing'
};
exports.RenderMode = RenderMode;