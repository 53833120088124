// @flow

import type { Quality } from './types';

import { getThumbnail as getSteamThumbnail } from './steam';
import { getThumbnail as getYBThumbnail } from './youtube';

import { getVideoId, getSteamVideoId, getYouTubeId } from '#common/utils/video';

export { getIframeSrc as getYoutubeIframeSrc } from './youtube';
export { getSanitizedUrl as getSteamSanitizedUrl } from './steam';

export const getVideoThumbnail = (url: string, quality: Quality = 'medium'): string => {
  if (getVideoId(url)) {
    const steamId = getSteamVideoId(url);
    const youtubeId = getYouTubeId(url);

    if (steamId) {
      return getSteamThumbnail(url);
    }

    if (youtubeId) {
      return getYBThumbnail(quality)(youtubeId);
    }
  }

  return '';
};
