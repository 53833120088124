/** @prettier */
// @flow
import React from 'react';
import { injectIntl } from 'react-intl';

import type { IXsollaMetrikaEventNames } from '../../../scripts/afterBlocks/analytics/analytics';
import type { intlShape } from 'react-intl';

import { MethodsEnum } from '../../../scripts/afterBlocks/analytics/analytics';
import { getStyleForLazyLoad } from '../../../utils/lazy-load-img';

type Props = {
  asset: Object,
  intl: intlShape,
  onMediaButtonClick: Function,
  isServerSideRender: boolean,
  mediaOptions: Object,
  xaAttributes: {
    name: IXsollaMetrikaEventNames,
    extra?: Object,
    value?: string,
  },
};

const MediaWithLink = ({
  asset: { color, img, size },
  intl,
  onMediaButtonClick,
  isServerSideRender,
  mediaOptions,
  xaAttributes,
}: Props) => (
  <div className='ui-site-media main-image'>
    <>
      {isServerSideRender && mediaOptions.href !== '' && (
        <a
          {...mediaOptions}
          className='ui-site-media__link'
          target='_blank'
          rel='noopener noreferrer'
          data-xa-extra={JSON.stringify(xaAttributes.extra) || ''}
          data-xa-value={xaAttributes.value || ''}
          data-xa-name={xaAttributes.name}
          data-xa-method={MethodsEnum.ELEMENT_CLICK}
        />
      )}
      <div
        className='ui-site-media__background-color'
        style={{ backgroundColor: color }}
      />
      <div
        className='ui-site-media__image lazy'
        data-bg={`url(${img})`}
        style={getStyleForLazyLoad(isServerSideRender, img, {
          backgroundSize: size,
        })}
      />
    </>
    {!isServerSideRender && (
      <div
        className='ui-site-media__button-wrapper'
        onClick={onMediaButtonClick}
        onKeyDown={onMediaButtonClick}
        role='button'
        tabIndex={0}
      >
        <button type='button' className='ui-site-media__main-image-assets'>
          {intl.messages['nav_block.settings.image.set_image']}
        </button>
      </div>
    )}
  </div>
);

export default injectIntl(MediaWithLink);
