// script from the package sets the module directly into Window object
import '@xsolla/metrika/watch';

import type { ScriptsData } from './types';

import { initGameKeyEventHandler } from './actions/buyGameKeys';
import { initSubscriptionEventHandler } from './actions/buySubscription';
import { initUniversalItemEventHandler } from './actions/buyUniversalItem';
import afterBlocksScripts from './afterBlocks';
import beforeBlocksScripts from './beforeBlocks';
import commonBlocksScripts from './blocks';
import privateBlocksScripts from './blocks/privateBlocks';
import { setupBlocksAPI } from './landingAPI';
import { initBlocksServices } from './services';
import { initStimulusApplication } from './stimulus/stimulus';
import { getStore } from './store';
import { translations } from './translations';

const blocksScripts = async (data: ScriptsData, translations) => {
  initBlocksServices(data, translations);
  await commonBlocksScripts({ data, translations });
  privateBlocksScripts({ data, translations });
};

const store = getStore();
const { scripts: scriptsState } = store.getState();

/**
 * Block handlers are @deprecated, use initBlock functions instead
 */
setupBlocksAPI(window);
initGameKeyEventHandler();
initSubscriptionEventHandler();
initUniversalItemEventHandler();

window.addEventListener('DOMContentLoaded', async () => {
  const { needRedirect } = await beforeBlocksScripts(scriptsState);
  if (needRedirect) {
    return;
  }
  await blocksScripts(scriptsState, translations);
  afterBlocksScripts(scriptsState, translations);
  initStimulusApplication();
});
