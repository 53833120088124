/** @prettier */
// @flow
import type { IRenderMode } from '#common/flow-types/build';

import { userIdModalDataid } from '../../components/ModalWindows/EditorModals';
import { openPayStationWidget } from '../../utils/paystation/open-widget';
import {
  analyticsManager,
  sendEvent,
} from '../afterBlocks/analytics/analytics';
import { AnalyticsActions } from '../afterBlocks/analytics/analyticsActions';
import {
  topupUserServiceObserver,
  UserIdInputStatus,
} from '../blocks/topup/services';
import { openModalWindow } from '../ui-components/editor/modalWindow';
import { isSandboxMode } from './sandbox';

export const setupSandboxSetting = (
  mode: IRenderMode,
  tokenSettings: Object = {}
) => {
  if (isSandboxMode(mode)) {
    return {
      ...tokenSettings,
      mode: 'sandbox',
    };
  }

  return tokenSettings;
};

export const initTopupButton = async ({
  analyticsCategory,
  button,
  renderMode,
  getToken,
}: {
  analyticsCategory: string,
  button: HTMLElement | null,
  renderMode: IRenderMode,
  getToken: ({ id: string, name?: string }) => Promise<string>,
}) => {
  if (!button) {
    return;
  }

  async function buy() {
    const userId = topupUserServiceObserver.target.inputValue;
    if (
      topupUserServiceObserver.target.status !==
      UserIdInputStatus.VALIDATION_PASSED
    ) {
      return;
    }

    const token = await getToken({
      id: userId,
      name: topupUserServiceObserver.target.userInfo.name,
    });
    openPayStationWidget({
      token,
      renderMode,
    });
    sendEvent({
      name: button.dataset.xaName,
      values: button.dataset.xaValue,
      extra: button.dataset.xaExtra,
    });
  }

  const userId = topupUserServiceObserver.target.inputValue;
  if (
    !userId ||
    topupUserServiceObserver.target.status !==
      UserIdInputStatus.VALIDATION_PASSED
  ) {
    const modal = document.querySelector(`[data-id=${userIdModalDataid}]`);
    openModalWindow(modal, 'UserId', buy);
    analyticsManager.sendEvent({
      category: analyticsCategory,
      event: AnalyticsActions.OPEN_UID_WIDGET_FROM_BUY_NOW,
      page: `landing_${AnalyticsActions.OPEN_UID_WIDGET}`,
    });
    return;
  }
  buy();
};
