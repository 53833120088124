// @flow
export default (params: {
  enable?: boolean,
  image?: string,
  video?: string
} = {}) => ({
  enable: params.enable ?? true,
  type: 'image',
  size: 'contain',
  img: params.image || '',
  video: params.video || '',
  color: 'rgba(0,0,0,0)'
});
