/** @prettier */
// @flow
import { initialize, onConsent } from '@xsolla/gdpr/dist/bundle';

import type { App } from '../helpers/gdpr';
import type { ScriptsData } from '../types';

import { getCookie } from '../helpers/common';
import { setInstance } from '../helpers/gdpr';
import { hideGdprConsent } from '../landingAPI/hideGdprConsent';
import analyticsEvents from './analytics/analyticsEvents';
import facebookPixel from './analytics/facebookPixel';
import googleAnalytics from './analytics/googleAnalytics';
import gtm from './analytics/gtm';
import twitterPixel from './analytics/twitterPixel';

import { fiveSymbolsMap } from '#common/types/locale';

const runOnlyGdprAllowedFunctions = (data: ScriptsData) => {
  gtm(data);
  facebookPixel(data);
  twitterPixel(data);
  googleAnalytics(data);
  analyticsEvents(data);
};

export default (data: ScriptsData) => {
  const { domain, locale, landingTheme } = data;
  const { accentColor, textColor, buttonBorderRadius } = landingTheme;

  if (hideGdprConsent()) {
    runOnlyGdprAllowedFunctions(data);
    return;
  }

  onConsent((consent, prev) => {
    if (
      prev.cookie.analytics_consent_state &&
      !consent.cookie.analytics_consent_state
    ) {
      window.location.reload();
    }

    if (consent.gdpr.consent_state && consent.cookie.analytics_consent_state) {
      runOnlyGdprAllowedFunctions(data);
    }
  });

  initialize(
    {
      state: {
        productName: `landing_${domain}`,
      },
      ui: {
        initialTextColor: textColor,
        accentColor,
        rounding: `${buttonBorderRadius / 30}em`,
      },
      localization: {
        locale:
          fiveSymbolsMap[locale] ||
          getCookie('langCode') ||
          window.navigator.language,
      },
    },
    (err, app?: App) => {
      if (err || !app) {
        return;
      }

      setInstance(app);
    }
  );
};
