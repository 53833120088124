/**
 * @prettier
 */

import { translations } from '../../../translations';

const translationCases: Array<{
  selector: string;
  value: string;
  attribute?: string;
}> = [
  {
    selector: '[data-cart-modal-title]',
    value: translations['client.cart_modal.title'],
  },
  {
    selector: '[data-cart-modal-clear-all-text]',
    value: translations['client.cart_modal.clear_all'],
  },
  {
    selector: '[data-cart-modal-item-purchased-description]',
    value: translations['client.cart_modal.item_purchased_description'],
  },
  {
    selector: '[data-cart-modal-subtotal-title]',
    value: translations['client.cart_modal.subtotal_title'],
  },
  {
    selector: '[data-cart-modal-subtotal-subtitle]',
    value: translations['client.cart_modal.subtotal_subtitle'],
  },
  {
    selector: '[data-cart-modal-continue-button]',
    value: translations['client.cart_modal.continue_button'],
  },
  {
    selector: '[data-cart-modal-checkout-button]',
    value: translations['client.cart_modal.checkout_button'],
  },
  {
    selector: '[data-cart-modal-footer-auth]',
    value: translations['client.cart_modal.footer-auth'],
  },
  {
    selector: '[data-cart-modal-empty-cart-title]',
    value: translations['client.cart_modal.title'],
  },
  {
    selector: '[data-cart-modal-empty-cart-description]',
    value: translations['client.cart_modal.empty_cart_description'],
  },
  {
    selector: '[data-cart-modal-empty-cart-open-store-button]',
    value: translations['client.cart_modal.empty_cart_open_store_button'],
  },
  {
    selector: '[data-cart-modal-loader-cart-title]',
    value: translations['client.cart_modal.title'],
  },
  {
    selector: '[data-cart-modal-email-label]',
    value: translations['client.cart_modal.email_label'],
  },
  {
    selector: '[data-cart-modal-email-description]',
    value: translations['client.cart_modal.email_description'],
  },
  {
    selector: '[data-cart-modal-email-input]',
    value: translations['client.cart_modal.email_placeholder'],
    attribute: 'placeholder',
  },
];

export const setTranslations = (modal: HTMLDivElement) => {
  translationCases.forEach(
    ({
      selector,
      value,
      attribute,
    }: {
      selector: string;
      value: string;
      attribute?: string;
    }) => {
      const node = modal.querySelector<HTMLElement>(selector);
      if (!node) {
        return;
      }
      if (attribute) {
        node.setAttribute(attribute, value);
        return;
      }
      node.innerHTML = value;
    }
  );
};
