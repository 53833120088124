/** @prettier */
import { GlobalUniversalCart } from '../../blocks/store/cart/GlobalUniversalCart';
import { CART_PURCHASE_ID } from '../../blocks/store/cartModal';
import { gaXsollaEvent, fbEvent, twEvent } from '../../helpers/pixels';
import { getCartItemsDataForAnalytics } from './analyticsCartHelper';
import { payStationPostMessage } from './payStationPostMessages';

export const convertPurchaseData = ({
  id,
  title,
  projectId,
  projectName,
  psData,
}) => {
  const {
    purchase_sum: sum,
    purchase_currency: currency,
    purchase_sku: sku,
  } = psData;
  const isCartPurchase = id === CART_PURCHASE_ID;

  let skuItems = [{ id: sku, quantity: 1 }];
  let skuQuantity = 1;
  let skuIds = [sku];

  if (isCartPurchase) {
    const cart = GlobalUniversalCart.instance;
    const cartItemsData = getCartItemsDataForAnalytics(cart);

    skuItems = cartItemsData.items;
    skuQuantity = cartItemsData.quantity;
    skuIds = cartItemsData.ids;
  }

  return {
    value: sum,
    currency,
    content_ids: skuIds,
    content_name: title,
    content_type: 'product',
    contents: skuItems,
    num_items: skuQuantity,
    project_id: projectId,
    project_name: projectName,
  };
};

export const convertTwitterPurchaseData = ({ id, projectName, psData }) => {
  const {
    purchase_sum: sum,
    purchase_currency: currency,
    purchase_invoice_id: invoiceId,
  } = psData;
  const cart = GlobalUniversalCart.instance;
  const isCartPurchase = id === CART_PURCHASE_ID;
  const skuQuantity = isCartPurchase
    ? getCartItemsDataForAnalytics(cart).quantity
    : 1;

  return {
    value: sum,
    currency,
    num_items: skuQuantity,
    transaction_id: invoiceId,
    content_name: projectName,
  };
};

const initPSEvents = ({ buyButton, projectId, projectName }) => {
  const { id, block } = JSON.parse(buyButton.getAttribute('data-xa-extra'));

  payStationPostMessage.block = block;
  payStationPostMessage.handler = (data) => {
    if (data.action === 'open-paystation') {
      gaXsollaEvent({
        eventCategory: 'sitebuilder',
        eventAction: 'open-paystation',
        projectID: projectId,
        projectName,
      });
    }

    if (data.value === 'done') {
      const twPurchaseData = convertTwitterPurchaseData({
        id,
        projectName,
        psData: data,
      });
      twEvent('Purchase', twPurchaseData);
    }
  };
};

export default ({ projectId, domain: projectName }) => {
  fbEvent('ViewContent', {
    project_id: projectId,
    project_name: projectName,
  });
  twEvent('PageView');

  window.addEventListener('click', (event) => {
    const { target } = event;
    const buyButton = target.closest('[data-button-type="buy"]');

    if (!buyButton) {
      return;
    }

    initPSEvents({
      buyButton,
      projectId,
      projectName,
    });
  });
};
