import { getThumbnail as getYBThumbnail } from '../../utils/video/youtube';

import { getYouTubeId } from '#common/utils/video';

// ширина изображения-заглушки, которая приходит, если изображения не существует
const LOW_RES_THUMBNAIL_WIDTH = 120;

export const loadImage = url => new Promise(resolve => {
  const img = new Image();
  img.onload = () => {
    resolve(img);
  };
  img.src = url;
});

export const checkYoutubeThumbnail = async data => {
  const { pageTheme: { videoBackground } } = data;
  const youtubeVideoId = getYouTubeId(videoBackground.video);

  if (!videoBackground.enable || !youtubeVideoId) {
    return;
  }

  const highResImgUrl = getYBThumbnail('hires')(youtubeVideoId);

  const loadedImg = await loadImage(highResImgUrl);
  const pageBackgroundContainer = document.querySelector('.preview-page');

  if (loadedImg.width > LOW_RES_THUMBNAIL_WIDTH && pageBackgroundContainer) {
    pageBackgroundContainer.style.setProperty('--background-image', `url(${highResImgUrl})`);
  }
};

