"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toStoreType = exports.storeItemFactory = exports.filterAuthRequired = exports.customTypesToStoreItem = exports.VirtualGoodItem = exports.VI_TYPE = exports.VC_TYPE = exports.UnitDRMItem = exports.StoreItem = exports.GK_TYPE = exports.ERROR_VC_GROUP = exports.DIGITAL_CONTENT = exports.DEFAULT_VC_IMAGE = exports.BundleItem = exports.BUNDLE_TYPE = void 0;

var _store = require("../../flow-types/store");

var _landing = require("../../model/landing");

/** @prettier  */
const ERROR_VC_GROUP = '__error__';
exports.ERROR_VC_GROUP = ERROR_VC_GROUP;
const GK_TYPE = 'gk';
exports.GK_TYPE = GK_TYPE;
const BUNDLE_TYPE = 'bundle';
exports.BUNDLE_TYPE = BUNDLE_TYPE;
const VC_TYPE = 'vc';
exports.VC_TYPE = VC_TYPE;
const VI_TYPE = 'vi';
exports.VI_TYPE = VI_TYPE;
const DIGITAL_CONTENT = 'digital_content';
exports.DIGITAL_CONTENT = DIGITAL_CONTENT;
const DEFAULT_VC_IMAGE = 'https://cdn3.xsolla.com/img/misc/merchant/default-pg-image.png';
exports.DEFAULT_VC_IMAGE = DEFAULT_VC_IMAGE;

const toStoreType = localType => {
  switch (localType) {
    case VI_TYPE:
      return _store.StoreUniversalItemType.VIRTUAL_ITEM;

    case VC_TYPE:
      return _store.StoreUniversalItemType.BUNDLE;

    case GK_TYPE:
      return _store.StoreUniversalItemType.UNIT;

    case BUNDLE_TYPE:
      return _store.StoreUniversalItemType.BUNDLE;

    default:
      // $FlowFixMe
      return localType;
  }
};

exports.toStoreType = toStoreType;

const customTypesToStoreItem = type => {
  switch (type) {
    case GK_TYPE:
      return {
        type: _store.StoreUniversalItemType.UNIT
      };

    case BUNDLE_TYPE:
      return {
        type: _store.StoreUniversalItemType.BUNDLE,
        bundleType: 'standard'
      };

    default:
      throw Error(`Unknown type: "${type}"`);
  }
};

exports.customTypesToStoreItem = customTypesToStoreItem;

class StoreItem {
  constructor(o) {
    Object.getOwnPropertyNames(o).forEach(k => {
      // $FlowFixMe
      this[k] = o[k];
    });
  }

  static isBundle(item) {
    return item.type === _store.StoreUniversalItemType.BUNDLE && item.bundle_type === 'standard';
  }

  static isDRM(item) {
    return Object.prototype.hasOwnProperty.call(item, 'drm_sku');
  }

  static isVirtualGood(item) {
    return item.type === _store.StoreUniversalItemType.VIRTUAL_GOOD;
  }

  isVI() {
    const item = this;
    return item.type === _store.StoreUniversalItemType.VIRTUAL_GOOD;
  }

  isVC() {
    const item = this;
    return item.type === _store.StoreUniversalItemType.VIRTUAL_CURRENCY;
  }

  isVCPackage() {
    const item = this;
    return item.type === 'bundle' && item.bundle_type === 'virtual_currency_package';
  }

  isUnit() {
    const item = this;
    return item.type === 'unit';
  }

  hasVirtualPrice() {
    const item = this;
    return Object.hasOwnProperty.call(item, 'virtual_prices') && item.virtual_prices.length > 0;
  }

  get parentSKU() {
    const item = this;
    return item.sku;
  }

  isRequireAuth() {
    const item = this;
    return this.isVI() || this.isVC() || this.isVCPackage() || StoreItem.isBundle(item) && item.filterAuthRequired() || // eslint-disable-line no-use-before-define
    this.hasVirtualPrice();
  }

  isSame(b) {
    const item = this;
    return item.sku === b.sku;
  }

  canShow() {
    const item = this;
    return !!(!this.isUnit() || item.unit_items.some(unitItem => unitItem.has_keys || unitItem.is_pre_order));
  }

  canBuyForRealPrice() {
    const item = this;
    return !!item.price;
  }

}

exports.StoreItem = StoreItem;

const hasOnlyVirtualGoods = content => content.every(item => {
  if (item.type === _store.StoreUniversalItemType.BUNDLE) {
    return hasOnlyVirtualGoods(item.content);
  }

  return item.type === _store.StoreUniversalItemType.VIRTUAL_GOOD || item.type === _store.StoreUniversalItemType.VIRTUAL_CURRENCY;
});

const filterAuthRequired = content => content.some(item => {
  if (item.type === _store.StoreUniversalItemType.BUNDLE) {
    return hasOnlyVirtualGoods(item.content);
  }

  return item.type === _store.StoreUniversalItemType.VIRTUAL_GOOD || item.type === _store.StoreUniversalItemType.VIRTUAL_CURRENCY;
});

exports.filterAuthRequired = filterAuthRequired;

class BundleItem extends StoreItem {
  constructor(o, landingType) {
    super(o);
    this._landingType = landingType;
  }

  filterAuthRequired() {
    // $FlowFixMe
    return filterAuthRequired(this.content);
  }

  hasOnlyVirtualGoods() {
    // $FlowFixMe
    return hasOnlyVirtualGoods(this.content);
  }

  get tags() {
    return this.content.map(item => {
      let res = item.name;

      if (item.quantity > 1) {
        res += ` ×${item.quantity}`;
      }

      return res;
    });
  }

  canShow() {
    const item = this;
    return !!(this._landingType === _landing.LandingType.TOPUP && item.price && item.hasOnlyVirtualGoods() || this._landingType !== _landing.LandingType.TOPUP && (item.price || item.virtual_prices.length > 0));
  }

}

exports.BundleItem = BundleItem;

class UnitDRMItem extends StoreItem {
  get parentSKU() {
    const item = this;
    return item.sku.replace(`_${item.drm_sku}`, '');
  }

}

exports.UnitDRMItem = UnitDRMItem;

class VirtualGoodItem extends StoreItem {
  constructor(o, landingType) {
    super(o);
    this._landingType = landingType;
  }

  canShow() {
    const item = this;
    return !!(this._landingType !== _landing.LandingType.TOPUP && item.virtual_prices.length > 0 || item.price);
  }

}

exports.VirtualGoodItem = VirtualGoodItem;

const storeItemFactory = landingType => storeItem => {
  if (StoreItem.isDRM(storeItem)) {
    return new UnitDRMItem(storeItem);
  }

  if (StoreItem.isBundle(storeItem)) {
    return new BundleItem(storeItem, landingType);
  }

  if (StoreItem.isVirtualGood(storeItem)) {
    return new VirtualGoodItem(storeItem, landingType);
  }

  return new StoreItem(storeItem);
};

exports.storeItemFactory = storeItemFactory;