// @flow
import type { ScriptsData, Translations } from '../types';

import initCustomSelectors from '../ui-components/editor/customSelector';
import { analytics } from './analytics/analytics';
import { activateJoinButton, setTextToBlurBlock, unShowBlurBlock } from './blurButtons';
import checkVideo from './checkVideo';
import experiments from './experiments';
import fancyBox from './fancyBox';
import gdpr from './gdpr';
import platformUtmLabel from './platformUtmLabel';
import removeStore from './removeStore';
import { initStoreDropdown } from './storeDrmDropdown';
import { initUserIdModal } from './user-id-modal';
import fixWidowedText from './widowedText';
import youtube from './youtube';

export default (
  data: ScriptsData,
  translations: Translations
) => {
  // TODO сделать вызов функций исходя из зависимостей
  gdpr(data);
  checkVideo(data, translations);
  platformUtmLabel(data, translations);
  fancyBox(data, translations);
  youtube(data, translations);
  removeStore(data, translations);
  unShowBlurBlock();
  setTextToBlurBlock(translations);
  activateJoinButton();
  fixWidowedText();
  initCustomSelectors();
  initStoreDropdown();
  initUserIdModal(data);
  analytics(data);
  experiments(data);
};
