/**
 * This file is @deprecated, use import { fiveSymbolsMap } from '#common/utils/locale'; instead
 */

export const allowedLanguages = [
  'ar-AE', 'bg-BG', 'cs-CZ', 'de-DE',
  'en-US', 'es-ES', 'fr-FR', 'he-IL',
  'hu-HU', 'it-IT', 'ja-JP', 'ko-KR',
  'pl-PL', 'pt-BR', 'ro-RO', 'ru-RU',
  'th-TH', 'tr-TR', 'vi-VN', 'zh-CN',
  'zh-TW'
];

export const twoSymbolsToFiveLocalesMap = {
  ar: 'ar-AE',
  bg: 'bg-BG',
  cs: 'cs-CZ',
  de: 'de-DE',
  en: 'en-US',
  es: 'es-ES',
  fr: 'fr-FR',
  he: 'he-IL',
  hu: 'hu-HU',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  pl: 'pl-PL',
  pt: 'pt-BR',
  ro: 'ro-RO',
  ru: 'ru-RU',
  th: 'th-TH',
  tr: 'tr-TR',
  vi: 'vi-VN',
  cn: 'zh-CN',
  tw: 'zh-TW'
};

export const fiveToTwoSymbolMap = {
  'ar-AE': 'ar',
  'bg-BG': 'bg',
  'cs-CZ': 'cs',
  'de-DE': 'de',
  'en-US': 'en',
  'es-ES': 'es',
  'fr-FR': 'fr',
  'he-IL': 'he',
  'hu-HU': 'hu',
  'it-IT': 'it',
  'ja-JP': 'ja',
  'ko-KR': 'ko',
  'pl-PL': 'pl',
  'pt-BR': 'pt',
  'ro-RO': 'ro',
  'ru-RU': 'ru',
  'th-TH': 'th',
  'tr-TR': 'tr',
  'vi-VN': 'vi',
  'zh-CN': 'cn',
  'zh-TW': 'tw'
};
