import { applyMiddleware, compose, createStore, Middleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import { clientEnvironments } from '../config';
import { RootState } from './site-builder-store';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
  }
}

const getMiddlewares = (customMiddlewares, isServerSideRender, useDevtools) => {
  const middlewares: Middleware[] = [thunk, ...customMiddlewares];
  if (clientEnvironments.isDev && !isServerSideRender) {
    const composeEnhancers =
      useDevtools && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : compose;
    middlewares.push(createLogger({ collapsed: true }));
    return composeEnhancers(applyMiddleware(...middlewares));
  }
  return compose(applyMiddleware(...middlewares));
};

const createStoreManual = ({
  rootReducer,
  customMiddlewares = [],
  preloadedState,
  isServerSideRender = false,
  useDevtools = false,
}: {
  rootReducer: any;
  customMiddlewares?: any[];
  preloadedState?: RootState;
  isServerSideRender?: boolean;
  useDevtools?: boolean;
}) => {
  const middlewares = getMiddlewares(
    customMiddlewares,
    isServerSideRender,
    useDevtools
  );
  return createStore(rootReducer, preloadedState || {}, middlewares);
};

export default createStoreManual;
