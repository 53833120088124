// @flow

import IntlMessageFormat from 'intl-messageformat';

import { allowedLanguages } from './converterTranslation';

const defaultLocale: 'en-US' = 'en-US';

const isDefaultLocale = (locale: string) => locale === defaultLocale;

const wrap = (wrapperTag: string, value: string) => `<${wrapperTag}>${value}</${wrapperTag}>`;

const wrapByLabel = (label, message) => {
  switch (label) {
    case 'blocks.packs.values.title':
      return wrap('h2', message);
    case 'blocks.topup.values.title':
      return wrap('h1', wrap('b', message));
    case 'blocks.topup.values.description':
      return wrap('h3', message);
    case 'blocks.topup.values.userInstruction.stepTitleTag':
      return wrap('h3', message);
    case 'blocks.subscriptions.values.heading':
      return wrap('h2', message);
    default:
      return message;
  }
};

const requireFileWithTranslation = language => {
  try {
    return require(`../translations/${language}`); // eslint-disable-line import/no-dynamic-require
  } catch (error) {
    return {};
  }
};

const loadTranslations = () => {
  const translations = {};
  allowedLanguages.forEach(language => {
    translations[language] = requireFileWithTranslation(language);
  });
  return translations;
};

const translations = loadTranslations();

const getTranslateMessage = (language: string = defaultLocale, id: string, format?: Object): string => {
  try {
    const message = new IntlMessageFormat(translations[language][id]).format(format);
    return wrapByLabel(id, message);
  } catch (error) {
    if (language !== defaultLocale) {
      return getTranslateMessage(defaultLocale, id, format);
    }
    return '';
  }
};

const getTranslationsForClient = (language: string) => {
  const defaultTranslations = translations[defaultLocale];
  const translationsByLanguage = translations[language];
  if (!translationsByLanguage) {
    return {};
  }

  return Object.keys(defaultTranslations)
    .filter(key => key.startsWith('client.'))
    .reduce((selectedTranslations, key) => {
      selectedTranslations[key] = translationsByLanguage[key] || defaultTranslations[key];
      return selectedTranslations;
    }, {});
};

export {
  getTranslateMessage as default,
  getTranslateMessage,
  loadTranslations,
  getTranslationsForClient,
  isDefaultLocale,
  defaultLocale,
  wrap
};
