/** @prettier */
const WB_MERCHANT_ID = '169548';
const WB_PROJECT_ID = '184819';
const WB_DEV_PROJECT_ID = '186632';

let customAuthorization: boolean;
let devCustomAuthorization: boolean;

export const isCustomAuth = () => customAuthorization;
export const isDevCustomAuth = () => devCustomAuthorization;

export const initCustomAuthorization = ({
  merchantId,
  projectId,
}: {
  merchantId: string;
  projectId: string;
}) => {
  customAuthorization =
    merchantId === WB_MERCHANT_ID &&
    (projectId === WB_PROJECT_ID || projectId === WB_DEV_PROJECT_ID);

  devCustomAuthorization =
    merchantId === WB_MERCHANT_ID && projectId === WB_DEV_PROJECT_ID;
};
