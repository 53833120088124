// @flow

import cn from 'classnames';
import React from 'react';
import { Tooltip } from 'xsolla-uikit';

import deleteIconSvg from '../../../assets/images/icons/delete-icon.svg';

type Props = {
  label: string,
  cssClasses?: string[]
}

const DeleteTooltip = ({ label = '', cssClasses = [] }: Props) => (
  <Tooltip
    label={label}
  >
    <div
      className={cn(...cssClasses)}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: deleteIconSvg }}
    />
  </Tooltip>
);

export default DeleteTooltip;
