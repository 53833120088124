import { Reducer } from 'redux';

import { getLoginService } from '../../../scripts/blocks/login/get-login-service';

const INIT_SERVICES = 'INIT_SERVICES';
const UPDATE_CART = 'UPDATE_CART';

// TODO improve types
export interface State {
  cart: unknown;
  storeAPIDataService: unknown;
  inventory: unknown;
  customAmountService: unknown;
  userService: ReturnType<typeof getLoginService>;
  userIdServiceObserver: unknown;
}
interface Action {
  type: typeof INIT_SERVICES | typeof UPDATE_CART;
  payload: Partial<State>;
}

const initialState: State = {
  cart: null,
  storeAPIDataService: null,
  inventory: null,
  customAmountService: null,
  userService: null,
  userIdServiceObserver: null,
};

export const initServices = (services: State) => ({
  type: INIT_SERVICES,
  payload: services,
});

export const updateCart = (cart: unknown) => ({
  type: UPDATE_CART,
  payload: {
    cart,
  },
});

export const landingServices: Reducer<State, Action> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case INIT_SERVICES:
    case UPDATE_CART:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
