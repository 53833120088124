/* @prettier @flow */
import React from 'react';
import ReactDOM from 'react-dom';

import Loader from '../../ui-components/settings/Loader';

export interface ILoaderService {
  checkAttach(): boolean;
  checkDetach(): boolean;
  detach(): void;
}

export function attach(service: ILoaderService, timeout: number = 10000) {
  if (!document.getElementById('loader') && service.checkAttach()) {
    const loader = document.createElement('div');
    loader.id = 'loader';
    document.getElementsByTagName('body')[0].appendChild(loader);
    const loaderContainer = document.getElementById('loader');
    if (loaderContainer) {
      ReactDOM.render(<Loader isLandingPage />, loaderContainer);
      // remove loader by timeout if error with items fetching or item removed from store
      setTimeout(() => {
        if (document.getElementById('loader')) {
          ReactDOM.unmountComponentAtNode(document.getElementById('loader'));
          loaderContainer.remove();
        }
      }, timeout);
    }
  }
}

export function detach(service: ILoaderService) {
  const loaderContainer = document.getElementById('loader');
  if (loaderContainer && service.checkDetach()) {
    ReactDOM.unmountComponentAtNode(loaderContainer);
    loaderContainer.remove();
    service.detach();
  }
}
